import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { drivers, driversCounter } from "../../redux/actions/table.action";
import Table from "./../../utilities/components/Table/Table";
import UserEditView from './UserEdit';
import { LoadingView } from "./../../utilities/components/Loading";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Dashboard() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [Data, setData] = React.useState<Array<string>>([]);
  const [Props, setProps] = React.useState({});
  const [searchParams] = useSearchParams();
  const [SelectedUser, setSelectedUser] = React.useState<any>([]);
  const [Tasks, setTasks] = React.useState<Array<string>>([]);
  const [Filters, setFilters] = React.useState({
    title: "FILTERS",
    icon: "/assets/icons/Todo.svg",
    items: [
      {
        title: "All",
        icon: "/assets/icons/Todo.svg",
        name: "All-Count",
        prop: "",
      },
      {
        title: "Verified",
        icon: "/assets/icons/Task@2x.png",
        name: "VERIFIED",
        prop: "VERIFIED",
      },
      {
        title: "Disabled",
        icon: "/assets/icons/Steering Wheel.svg",
        name: "DISABLED",
        prop: "DISABLED",
      },
      {
        title: "Unverified",
        icon: "/assets/icons/check.png",
        name: "NOT_VERIFIED",
        prop: "NOT_VERIFIED",
      },
      {
        title: "Not Submitted",
        icon: "/assets/icons/cancel.png",
        name: "NOT_SUBMITTED",
        prop: "NOT_SUBMITTED",
      },
    ],
  });


  const [Loading, setLoading] = React.useState(false);
  const [ALL, setALL] = React.useState(0);
  const [VERIFIED, setVERIFIED] = React.useState(0);
  const [DISABLED, setDISABLED] = React.useState(0);
  const [NOTVERIFIED, setNOTVERIFIED] = React.useState(0);
  const [NOTSUBMITTED, setNOTSUBMITTED] = React.useState(0);
  const navigate = useNavigate();

  function apiCanceld(status: any, response: any) {
    setLoading(false);
    let error = "";
    if (typeof status === 'string') {
      error = status ? status : "";
    }
    if (response.payload && response.payload.error) {
      error += " " + response.payload.errorMassage;
    }

    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  }
  function menuCounter() {
    let status = searchParams.get('filter');
    if (status) {
      status = status
    } else {
     
    }
    dispatch(driversCounter()).then((d: any) => {


      d?.payload?.map((obj: any) => {
        if (obj.license_verification === "VERIFIED") {
          setVERIFIED(obj.count);
        }
        if (obj.license_verification === "DISABLED") {
          setDISABLED(obj.count);
        }
        if (obj.license_verification === "NOT_VERIFIED") {
          setNOTVERIFIED(obj.count);
        }
        if (obj.license_verification === "NOT_SUBMITTED") {
          setNOTSUBMITTED(obj.count);
        }
      })
    })


  }

  React.useEffect(() => {
    menuCounter();

  }, []);

  React.useEffect(() => {
    setLoading(true)
    let status = searchParams.get('filter');
    let license_verification: any = status;
    let ordering: any = "-user__date_joined";

    request({ license_verification, ordering });

  }, [searchParams]);

  function request(p?: any) {

    let requestProps: any = {}
    if (p) {
      requestProps = p
    } else {
      requestProps = Props
    }

    if (!requestProps.ordering) {
      requestProps.ordering = "-user__date_joined";
    }

    let status = searchParams.get('filter');

    if (status) {
      requestProps.license_verification = status
    }
    setProps(requestProps)

    dispatch(drivers(requestProps)).then((response: any) => {
      if (response?.payload?.error) {
        apiCanceld("", response);
      }
      if (response?.payload?.results) {
        setTasks(response.payload);
        setLoading(false);
      }
    });
  }



  function childGenrator(item: any) {
    let status = searchParams.get('filter');
    const links = (
      <li
        onClick={() => {
          navigate("?filter=" + item.prop);
        }}
        className={status === item.prop ? "filterItem filterItemActive d-flex align-items-center" + " filterItem-" + item.prop : "filterItem d-flex align-items-center" + " filterItem-" + item.prop}
      >
        <img className={"menuChildIcon"} src={item.icon} />
        <small
          style={{
            // display: !MenuIsOpen ? "none" : "unset",
            color: !item.value ? "#8697A8" : "#44566c",
          }}
        >
          {t(item.title)}
        </small>
        <span className={item.name}>
          {item.name === "All-Count" && VERIFIED + DISABLED + NOTVERIFIED + NOTSUBMITTED}
          {item.prop === "VERIFIED" && VERIFIED}
          {item.prop === "DISABLED" && DISABLED}
          {item.prop === "NOT_VERIFIED" && NOTVERIFIED}
          {item.prop === "NOT_SUBMITTED" && NOTSUBMITTED}
        </span></li>
    );

    return (
      <>
        <div className="links">{links}</div>
      </>
    );
  }

  return (
    <div className="main-container d-flex">

    
      {Loading && (
        <LoadingView />
      )}
      <div className="ulChildView menuFilterView UsermenuFilterView">
        <div className="filtersView filtersViewUsers">
          {Filters.items.map((item: any) => childGenrator(item))}
        </div>
      </div>
      <Table
        columns={[
          { name: 'user', key: 'user.first_name', sort_key: 'user__first_name' },
          { name: 'number of booking', key: 'user.num_bookings', sort_key: 'user__num_bookings' },
          { name: 'status', key: 'license_verification', sort_key: 'license_verification' },
          { name: 'account_created', key: 'user.date_joined', sort_key: 'user__date_joined' },
          { name: 'total_charges', key: 'user.total_charges', sort_key: 'user__total_charges' },
          { name: 'address', key: 'user.address', sort_key: 'user__address' },
          { name: 'notes', key: 'user.notes', sort_key: 'user__notes' },
          { name: 'last_booking', key: 'user.last_booking', sort_key: 'user__last_booking' },
          { name: 'coupon', key: 'user.coupon', sort_key: 'user__coupon' },
          { name: 'balance', key: 'user.balance', sort_key: 'user__balance' },
        ]}
        rowSelect={(row: any) => {
          setSelectedUser(row);
        }}
        getData={(props: any) => {
          
          request(props);
        }}
        tableData={Tasks}
        tableType={"Tasks_filter"}
        title={"Users " + " - " + (searchParams.get('filter') ? searchParams.get('filter') : "All")}
      />
      {SelectedUser.user && (<UserEditView user={SelectedUser} update={() => { request() }} close={() => { setSelectedUser({}) }} />)}
    </div>
  );
}

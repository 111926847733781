import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";

interface Props {
  colorsSet: (type: string) => void | undefined;
  InputColor: any;
  children?: React.ReactNode;
}
const colors = [
  "#EBE9E9",
  "#F4A4C0",
  "#ED8694",
  "#F9AD82",
  "#FECD81",
  "#A1E7C9",
  "#A0E2EB",
  "#9ECDFC",
  "#A69EEA",
  "#D9D9D9",
  "#E63B7A",
  "#E4364C",
  "#FE8A49",
  "#FAB853",
  "#33D18E",
  "#24CBE2",
  "#0180FF",
  "#4E3ED7",
  "#6C6C6C",
  "#982650",
  "#942432",
  "#B14F17",
  "#BC7D1A",
  "#1C9662",
  "#1690A0",
  "#16538E",
  "#33288D",
  "#323232",
  "#54102A",
  "#510D15",
  "#55250A",
  "#63420B",
  "#085032",
  "#0A4951",
  "#0B2F52",
  "#150D57",
  "#44566C",
];
export default function ColorSelect({ colorsSet, InputColor }: Props) {
  return (
    // <OutsideClickHandler
    //   onOutsideClick={() => {
    //     close();
    //   }}
    // >
    <div className={"ColorSelect"}>
      <div className={"colorInput"}>
        <span style={{ background: InputColor }}></span>
      </div>
      <div className={"colorsList"}>
        {colors.map((color: string, key: number) => (
          <div
            key={key}
            className={"filterRow"}
            onClick={() => {
              colorsSet(color);
            }}
            style={{ background: color }}
          />
        ))}
      </div>
    </div>
    // </OutsideClickHandler>
  );
}

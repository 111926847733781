import { rest, restWithOutHook } from "../apiGateway";
import ky from "ky";

export const profile = (token?: string) => {
  return rest.get("user-profile/", {
      headers: { "Authorization": `Bearer ` + token },
    })
    .json();
};

export const userEdit = (json: any) => {
  return rest.patch("users/" + json.id + "/" , { json }).json();
};

export const getBillingHistory = (id: any) => {
  return rest.get("company/vehicles/booking/?status=COMPLETE&status=CANCEL&user=" + id).json();
};

export const getCards = () => {
  return rest.get("stripe/cards/").json();
};



export const profileEdit = async ({
  data,
  token,
}: {
  data: any;
  token?: any;
}) => {
  const formData = new FormData();

  for (const key in data) {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  }
  return ky
    .patch(`${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` + "user-profile/", {
      body: formData,
      headers: {
        Authorization: token
          ? "Bearer " + token
          : `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .json();
};
export const passwordChange = (json: any) => {
  return rest.post("auth/password/change/", { json }).json();
};
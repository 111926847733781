import * as React from "react";
import { connect, useSelector } from "react-redux";
import {
  PageContainer,
  FormHeader,
  ImagePicker,
  DropDown,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import {
  profile,
  profileEdit,
} from "../../redux/actions/user.action";
import { useDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
import InputWrapper from "../../utilities/components/InputWrapper/InputWrapper";
import {
  InputWithIcon,
  CheckboxWithTitle,
} from "../../utilities/components/FromUtils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { users, sendPushNotification, sendSms ,sendEmail} from "../../redux/actions/table.action";


export default function Account() {
  const dispatch = useDispatch();
  const [Data, setData] = useState<Record<string, any>>({});
  const [Error, setError] = useState("");

  const user = useSelector((state: RootState) => state.user.profile);
  const token = useSelector((state: RootState) => state.auth.token);
  const [usersList, setUsersList] = React.useState([]);
  const [SelectedUsers, setSelectedUsers] = React.useState([]);

  const [BtnLoading, setBtnLoading] = React.useState(false);
  const [Search, setSearch] = React.useState("");
  const [sms, setSms] = React.useState(false);
  const [email, setEmail] = React.useState(false);
  const [notification, setNotification] = React.useState(false);



  function change(event: any) {
    const name = event.target.name;
    let value = event.target.value;
    setData({
      ...Data,
      [name]: value,
    });
  }

  function request() {
    dispatch(users({ search: Search })).then((response: any) => {

      if (response?.payload?.results) {
        setUsersList(response?.payload?.results)
      }
    });

  }


  function search(keyword: any) {
    setSearch(keyword?.target?.value);


  }

  React.useEffect(() => {

   
    if(!Search.length){
      setUsersList([])
    }else{
      request();
    }
  }, [Search]);


  const [t] = useTranslation();

  function SaveRequest(yo: any) {
   // setBtnLoading(true);

    let recipientsId:any = [];
    let recipientsEmail:any = [];

    SelectedUsers.map((user:any) =>{
      recipientsId.push(user.id)
      recipientsEmail.push(user.email)
    })
    console.log(Data)
    //sendSmsPOST
    // {
    //   
    // }

    //sendNotifiable
    // {
    //   "title": "string",
    //   "text": "string"
    // }

    //sendEmail
    // {
    //   "subject": "string",
    //   "body": "string"
    // }

    if(email){
      dispatch(sendEmail({ 
        recipients : recipientsEmail,
        body:Data?.text,
        subject : Data?.subject

      })).then((response: any) => {
        if(response?.payload?.detail){
          toast.success("Email send", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error(t("Email error"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
    }
    if(notification){
      dispatch(sendPushNotification({ 
        recipients : recipientsId,
        title:Data?.subject,
        text:Data?.text,
      })).then((response: any) => {
        if(response?.payload?.detail){
          toast.success("Notification send", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error(t("Notification error"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
    }

    if(sms){
      dispatch(sendSms({ 
        recipients : recipientsId,
        text:Data?.text,
      })).then((response: any) => {
        if(response?.payload?.detail){
          toast.success("Sms send", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error(t("sms error"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
        
      })
    }


  }


  function CheckExist(val:any) {
    return SelectedUsers.some((item:any) => val === item.id);
}


  return (
    <div
      className={"page-container d-flex flex-column h100"}
    >
      <ToastContainer />
      <FormHeader text={"Messaging"} />
      <div className={"ErrorMassage"}>{Error}</div>



      <div className="profile-fa ">
        <div className="modal-content-section d-flex flex-column">
          <div className="modal-account-account-detail d-flex">

            <div className={"col-12 "}>
              <div className={"d-flex"}>
                <div className="col-12 ">
                  <div className="profileSideColTitle">
                    New Messaging
                  </div>
                  <div className="checkbox-notification">
                    <CheckboxWithTitle
                      value={email}
                      text={"Email"}
                      onChange={(bool: boolean) => {
                        setEmail(bool)
                      }}
                    />
                    <CheckboxWithTitle
                      value={notification}
                      text={"Push Notification"}
                      onChange={(bool: boolean) => {
                        setNotification(bool)
                      }}
                    />
                    <CheckboxWithTitle
                      value={sms}
                      text={"SMS"}
                      onChange={(bool: boolean) => {
                        setSms(bool)
                      }}
                    />
                  </div>
                  <div className="input-with-label-container"><small>To <span></span></small>
                    <div className="input-with-label input-with-label-flex" >
                      <div className="userSelectedItemParent">
                        {SelectedUsers?.map((user: any) => <div className="userSelectedItem" onClick={()=>{setSelectedUsers(SelectedUsers.filter((item:any) => item.id !== user.id))}}>{user.first_name} {user.last_name}</div>)}
                        {usersList?.length ?
                          <div className="usersLists customScrollBar">
                            {usersList?.map((user: any) => <div className={CheckExist(user.id) ? "user-usersLists-selected user-usersLists" : "user-usersLists"} onClick={() => {
                              if(!CheckExist(user.id)){
                                let d: any = [...SelectedUsers];
                                d.push(user);
                                setSelectedUsers(d);
                              }else{
                                setSelectedUsers(SelectedUsers.filter((item:any) => item.id !== user.id))
                              }
                            }}>
                              {user.first_name}  {user.last_name}
                              <div>{user.email}</div>
                            </div>)}
                          </div>
                          : ''
                        }
                      </div>
                      <input
                       className="input-dynamic"
                       style={{minWidth: 'auto !important'}}
                        onChange={(key) => { search(key) }}
                        value={Data && Data.last_name}
                        placeholder="Users"
                      />

                    </div>
                  </div>
                  <InputWrapper label={"Subject"} >
                    <input
                      name="subject"
                      onChange={(value) => change(value)}
                      value={Data && Data.email}
                      placeholder="Subject"
                    />
                  </InputWrapper>
                  <h4 className="messaging-title">Messages</h4>
                  <textarea name="text"  placeholder="Message" id="editor"   onChange={(value) => change(value)}
>
                  </textarea>

                </div>

              </div>

              <LoadingButton
                className={"updateBtn"}
                loading={BtnLoading}
                onClick={() => {
                  SaveRequest(Data);
                }}
                text={"Send"}
              /> </div>
          </div>


        </div>
      </div>


    </div>
  );
}

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { bookings } from "../../redux/actions/table.action";
import EditView from "../Booking/BookingEdit";
import { LoadingView } from "../../utilities/components/Loading";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ToastContainer, toast } from "react-toastify";

const localizer = momentLocalizer(moment);

export default function CalendarDriver({ history }: any) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [event, setEvent] = React.useState<any>();
  const [BookingData, setBookingData] = React.useState<any>();
  const [EditViewShow, setEditViewShow] = React.useState<boolean>(false);
  const [SelectedData, setSelectedData] = React.useState<any>({});
  const [Loading, setLoading] = React.useState(true);
  const [StartDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));




  const holidaysList = [];
  const user = useSelector((state: RootState) => state.user.profile);

  function NameWeek(date: any, culture: any) {
    return t("calendar." + [localizer.format(date, "dddd", culture)]);
  }
  const formats = {
    weekdayFormat: (date: any, culture: any) => NameWeek(date, culture),
  };

  function RequestStatus(request_status: any, request: any) {

  }

  function eventStyleGetter(
    event: any,
    start_date: any,
    end_date: any,
    isSelected: any,

  ) {
    const backgroundColor = event.color;
    const alpha = event.alpha ? event.alpha : 1;
    const style = {
      backgroundColor: backgroundColor,
      color: "#fff",
      border: "0px",
      display: "block",
      opacity: alpha
    };
    return {
      style: style,
      title: " - ",
    };
  }
  async function isOverlapping(e: any) {
    var array: any = BookingData;
    for (let i in array) {
      //console.log("array[i].id != e.id",array[i].id + "!="+ e.id)
      if (array[i].id != e.id) {
        if (!((new Date(array[i].book_for)) >= new Date(e.end) || new Date(array[i].drop_off_datetime) <= new Date(e.start))) {
          return true;
        }
      }
    }
    return false;
  }

 async function eventCreator(bookingsList: any) {
    const events: any = [];
    if (bookingsList && bookingsList !== null) {
     await bookingsList.map(async (booking: any, key: any) => {
        const book_for = moment(booking.book_for)
        const isBefore = moment(book_for).isBefore(new Date());

        if (booking.status !== "CANCEL" && booking.drop_off_datetime) {
          const e: any = {};
          let className = ' eventCustomStyle '
          e.id = booking.id
          e.title = booking?.status +" - " +  booking?.pricing_plan.name +" - " + booking?.vehicle?.name + "\n" + booking?.vehicle?.vehicle_type.name +
            " - " +
            booking.user.first_name +
            " " +
            booking.user.last_name;
          e.type = booking.type;
          e.status = booking.status;


          const pricingPlan = booking.pricing_plan.name.replaceAll(" ","_")
          

          if (Math.round(moment(book_for).diff(moment(), "days", true)) === 0) { className += " todayEvent" }

          if(e.status === 'OPEN'){
            if (!isBefore) { className += ' nextEvent'}
            if (isBefore)  { className += ' opacityEvent ' }
          }




          className += " event-" + e.status + " ";

          e.start = booking.book_for;
          e.end = booking.drop_off_datetime;
          e.booking = booking
          e.borderColor = "#fff";
          let overlab = await isOverlapping(e);
          if(overlab){
            className += " overlabEvent "
          }else{
            className += "  "
          }

          if(pricingPlan === "By_Hour"){
            className += ' By_Hour '
            if(e.status === "ACTIVE"){
              className += " By_Hour-" + e.status + " ";
            }
          }
          if(pricingPlan === "By_Minute"){
            className += ' By_Minute '
            if(e.status === "ACTIVE"){
              className += " By_Minute-" + e.status + " ";
            }
          }
          if(pricingPlan === "By_Day"){
            className += ' By_Day '
            if(e.status === "ACTIVE"){
              className += " By_Day-" + e.status + " ";
            }

          }

          e.classNames = className
          // e.end = holiday.end_date;
          // e.start = holiday.start_date;
          events.push(e);
        }
      });
      setLoading(false)
      setEvent(events);
    }
  };

  function request(data?:any) {

    //&book_for_after=2022-01-01&book_for_before=2022-02-01
    let DefaultParam:any = {
      offset: 0,
      status: ['?status=OPEN&status=ACTIVE&status=COMPLETE&book_for_after='+data?.start+"&book_for_before="+data?.end+"&limit=300" ],
      search: false,
      ordering: false,
      according: false,
    };

    
    
    dispatch(bookings(DefaultParam)).then((res: any) => {
      setLoading(false)
      setBookingData(res.payload.results)
      console.log("res.payload",res.payload)
      
      if (res.payload.error) {
        toast.error(res.payload.errorMassage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }else if(res.payload.count === 0){
        toast.info("No Booking at this Time", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }
  React.useEffect(() => {
    if(BookingData && BookingData[0]){
      eventCreator(BookingData)
    }
  }, [BookingData]);
  React.useEffect(() => {
    // setLoading(true)
    // request()
   

  }, []);


  function onSelectEvent(selected: any) {
    setSelectedData(selected.booking)
  }




  return (
    <div className={"calendarFa"}>
      {Loading && <LoadingView />}
      <ToastContainer/>
      {SelectedData?.id && <EditView Data={SelectedData} Close={() => {
        setSelectedData({})
      }}
        UpdateTable={(action: any) => {
          action && request()
        }}
      />}
      <div
        className={"CalendarView UserCalendarToolbar"}
      >
        <FullCalendar
        firstDay={1}
        eventTimeFormat={{hour: '2-digit',
        minute: '2-digit',
        meridiem: true}}
        allDaySlot={false}
        // slotEventOverlap={false}
        eventChange={()=>{console.log("a")}}
          dayHeaderFormat={{ month: 'long', weekday: 'long', day: 'numeric', omitCommas: true }}
          customButtons={{
            new: {
              text: 'new',
              click: () => console.log('new event'),
            },
          }}
          
          datesSet={(a)=>{
            setLoading(true)
            console.log("a.start",a.start)
            setStartDate(moment(a.start).format('YYYY-MM-DD'))
            request({start : moment(a.start).format('YYYY-MM-DD') , end : moment(a.end).format('YYYY-MM-DD')})
          }}
          dateClick={(e) => console.log(e.dateStr)}
          themeSystem='bootstrap'
          nowIndicator={true}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          weekends={true}
          startParam={'book_for'}
          endParam={'end'}
          eventClick={(e) => {
            onSelectEvent(e.event.extendedProps);
          }}
          events={event}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          views={{
            dayGrid: {
              selectable: true,
            },
            timeGrid: {
              selectable: true,
            },
            dayGridMonth: {
              selectable: true,
            },
            agendaDay: {
              selectable: true,
            },
          }}
        />


      </div>

    </div>
  );
}

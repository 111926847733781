const PARAM = "PARAM_";
const PAGE = "PAGE_";
const SERVER = "";

export default {
  PARAM: PARAM,
  PAGE: PAGE,
  SERVER: SERVER,

  //DYNAMIC VARIEABLES
  ///////////////////////////////////////////////////////////PAGES
  PAGE_ONE: PAGE + "PAGE_ONE",
  MENU_OPEN: true,

  ///////////////////////////////////////////////////////////PARAMETRS
  LOADING: PARAM + "LOADING",
  TOKEN: PARAM + "TOKEN",
  BREADCRUMBS: PARAM + "BREADCRUMBS",

  SERVER_URL: {
    FRONT: {
      CONTACT: "webcontact/",
      COUNTRIES: "countries/",
    },
    AUTH: {
      SIGN_OUT: "rest-auth/logout/",
      SIGN_IN: "api/token/",
      SIGN_UP: "rest-auth/registration/",
      RESET_PASSWORD: "rest-auth/password/reset/",
    },
    ACCOUNT: {
      USER_INFO: "companies/",
    },
  },
};

import * as userService from "../services/user.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandler } from "../apiGateway";
export const profile = createAsyncThunk("profile", async (token?: string) => {
  try {
    return await userService.profile(token);
  } catch (err) {
    return errorHandler(err);
  }
});
export const setUser = createAsyncThunk("profile", async (user: any) => {
  return user;
});
export const userEdit = createAsyncThunk(
  "company/userEdit/",
  async (data: any) => {
    try {
      return await userService.userEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const getCards = createAsyncThunk(
  "/stripe/cards/",
  async () => {
    try {
      return await userService.getCards();
    } catch (err) {
      return errorHandler(err);
    }
  },
);



export const getBillingHistory = createAsyncThunk(
  "getBillingHistory/",
  async (id: any) => {
    try {
      return await userService.getBillingHistory(id);
    } catch (err) {
      return errorHandler(err);
    }
  },
);


export const profileEdit = createAsyncThunk(
  "profile/edit",
  async ({ data, token }: { data: any; token?: any }) => {
    try {
      return await userService.profileEdit({ data, token });
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const passwordChange = createAsyncThunk(
  "password-change/",
  async (json: any) => {
    try {
      return await userService.passwordChange(json);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
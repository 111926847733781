import React from "react";
import { rest, auth} from "../apiGateway";
import ky from "ky";

export const logout = async () => {
  const refresh = await localStorage.getItem("refresh_token");
  return rest
    .post("auth/logout/", { body: JSON.stringify({ refresh: refresh }) })
    .json();
};


export const deviceAdd = (json:any) => {
  return rest.post("devices/", { json })
    .json();
};export const signIn = (json:any) => {
  return auth.post("auth/login/", { json, headers: { Authorization: "" } })
    .json();
};
export const signUp = (json: {}) => {
  return auth
    .post("auth/registration/", { json, headers: { Authorization: "" } })
    .json();
};
export const inviteVerify = (json: {}) => {
  return auth
    .post("invite/verify/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const inviteAccept = async ({
  json,
  key,
}: {
  json: any;
  key: string;
}) => {
  return auth
    .post("invite/accept/" + key + "/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const resetPassword = (json: {}) => {
  return auth
    .post("auth/password/reset/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const VerifyEmailResend = (json: {}) => {
  return auth
    .post("auth/registration/resend-verification-email/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const VerifyEmail = (json: {}) => {
  return auth
    .post("auth/registration/account-confirm-email/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const ResetConfirm = (json: {}) => {
  return auth
    .post("auth/password/reset/confirm/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const profile = () => {
  return rest.get("profile/").json();
};

export const mobileSms = (data: any) => {
  // return aws
  //   .post("two-factor-auth/", {
  //     json: data,
  //   })
  //   .json();
  // return ky
  //   .post("https://stats.fleetspark.ai/two-factor-auth/", {
  //     json: data,
  //        })
  //   .json();
};

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import Table from "../../utilities/components/Table/Table";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSound from "use-sound";
import { tasks, task, tasksCounter } from "../../redux/actions/table.action";
import TasksDrawer from "./TasksDrawer"
import moment from "moment";
import { LoadingView } from "./../../utilities/components/Loading";
import { deviceAdd } from "../../redux/actions/auth.action";


export default function Tasks() {
  const navigate = useNavigate();
  const alarm = `${process.env.PUBLIC_URL}/ringtone.mp3`;
  const [play, { stop }] = useSound(alarm);
  //let audio = new Audio(alarm)
  const [searchParams] = useSearchParams();
  const [Status, setStatus] = React.useState(null);

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [Tasks, setTasks] = React.useState<Record<string, any>>([]);

  const Filters: any = {
    title: "FILTERS",
    icon: "/assets/icons/Todo.svg",
    items: [
      {
        title: "All",
        icon: "/assets/icons/Todo.svg",
        name: "All",
        prop: "",
      },
      {
        title: "menu.open",
        icon: "/assets/icons/Clock Remaining.svg",
        name: "Open",
        prop: "OPEN",
      },
      {
        title: "menu.OPEN_WITH_SAFETY",
        icon: "/assets/icons/Steering WheelYellow.svg",
        name: "Open with Safety",
        prop: "OPEN_WITH_SAFETY",
      },
      {
        title: "menu.dispatching",
        icon: "/assets/icons/Steering WheelGreen.svg",
        name: "Dispatching",
        prop: "DISPATCHING",
      },
      {
        title: "menu.completed",
        icon: "/assets/icons/check.png",
        name: "Completed",
        prop: "COMPLETE",
      },
      {
        title: "menu.canceled",
        icon: "/assets/icons/Delete.svg",
        name: "Canceled",
        prop: "CANCEL",
      },
    ],
  };

  const [Task, setTask] = React.useState<Record<string, any>>({});
  const [ShowMap, setShowMap] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [OpenCount, setOpenCount] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [DistpatchingCount, setDistpatchingCount] = React.useState(0);
  const [OPEN_WITH_SAFETYCount, setOPEN_WITH_SAFETYCount] = React.useState(0);
  const [CANCEL, setCANCEL] = React.useState(0);
  const [COMPLETE, setCOMPLETE] = React.useState(0);
  function request(p?: any) {
    let props: any = p ? p : {};
    let status = searchParams.get('filter');
    if (status) {
      props.status = status
    }
    dispatch(tasks(props)).then((response: any) => {
      if (response.payload && response.payload.results) {
        setTasks(response.payload);
        setLoading(false);
      } else {
        toast.error(response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }
  const hey = () => play();
  function notificationChecker(obj: any) {
    obj?.tasks?.map((task: any) => {
      const bookFor = task?.booking?.book_for;
      var now = moment(new Date());
      var end = moment(bookFor);
      var duration = moment.duration(end.diff(now));
      var asMinutes = duration.asMinutes();

      if (asMinutes < 45 && asMinutes > 0) {
        //console.log("play")
        //audio.play()

        hey()


        // const interval = setInterval(() => {
        //   stop()
        // }, 10000);
        // return () => clearInterval(interval);
      }
    })
  }


  function menuCounter() {
    dispatch(tasksCounter()).then((response: any) => {
      if (response && response.payload && !response.payload.error) {
        response.payload.map((obj: any, key: any) => {
          if (obj.status === "OPEN_WITH_SAFETY") {
            setOPEN_WITH_SAFETYCount(obj.count)
          }
          if (obj.status === "DISPATCHING") {
            setDistpatchingCount(obj.count)
          }
          if (obj.status === "OPEN") {
            setOpenCount(obj.count)
            notificationChecker(obj)
          }

          if (obj.status === "CANCEL") {
            setCANCEL(obj.count)
          }

          if (obj.status === "COMPLETE") {
            setCOMPLETE(obj.count)
          }

        })
      } else {
        setLoading(false)
        toast.error(response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  const counterRef: any = React.useRef()

  React.useEffect(() => {
    counterRef.current = count;
  })

  React.useEffect(() => {
    setInterval(() => {
      setCount(counterRef.current + 1);
    }, 60000);
    let fcm_token = localStorage.getItem('fcm_token');
    console.log("fcm_token", fcm_token)
    if (fcm_token) {
      dispatch(deviceAdd({
        "os": "web",
        "brand": "browser",
        "model": "panel",
        "udid": fcm_token + "udid",
        "fcm_token": fcm_token,
      }))
    }

  }, []);


  React.useEffect(() => {

    menuCounter()
  }, [count])

  React.useEffect(() => {
    const s: any = searchParams.get('filter') ? searchParams.get('filter') : null
    setStatus(s)

  }, [searchParams.get('filter')])

  React.useEffect(() => {
    setLoading(true)
    menuCounter();
    request()
  }, [Status])

  function childGenrator(item: any) {
    const links = (
      <li
        onClick={() => {
          navigate({
            pathname: '/panel/tasks/',
            search: `?${createSearchParams({ filter: item.prop })}`,
          });

        }}
        className={Status === item.prop ? "filterItem filterItemActive d-flex align-items-center" + " filterItem-" + item.prop : "filterItem d-flex align-items-center" + " filterItem-" + item.prop}
      >
        <img className={"menuChildIcon"} src={item.icon} />
        <small
          style={{
            // display: !MenuIsOpen ? "none" : "unset",
            color: !item.value ? "#8697A8" : "#44566c",
          }}
        >
          {t(item.title)}
        </small>
        {item.prop !== "" ?
          <span className={item.prop}>
            {item.prop === "OPEN" && OpenCount}
            {item.prop === "DISPATCHING" && DistpatchingCount}
            {item.prop === "OPEN_WITH_SAFETY" && OPEN_WITH_SAFETYCount}
            {item.prop === "CANCEL" && CANCEL}
            {item.prop === "COMPLETE" && COMPLETE}
          </span>
          : <span className={"allCount"}>{OpenCount + DistpatchingCount + OPEN_WITH_SAFETYCount + CANCEL + COMPLETE}</span>}
      </li>
    );

    return (
      <>
        <div className="links">{links}</div>
      </>
    );
  }
  function selectBook(row: any) {
    dispatch(task(row.id)).then((response: any) => {
      if (response.payload && !response.payload.error) {
        setTask(response.payload);
        if (response.payload.booking && response.payload.booking?.vehicle) {
          setTask(response.payload);
          setShowMap(true)
        }
      } else {
        setLoading(false);
        toast.error(response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  return (
    <div className="main-container d-flex">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {Loading && (
        <LoadingView />
      )}
      <div className="ulChildView menuFilterView">
        <div className="filtersView">

          {Filters.items.map((item: any) => childGenrator(item))}
        </div>
      </div>
      <Table
        columns={[
          { name: 'vehicle', key: 'booking.vehicle.vehicle_image+booking.vehicle.vehicle_type.name', sort_key: 'booking__vehicle__name' },
          { name: 'license', key: 'booking.vehicle.license' },
          { name: 'name', key: 'booking.vehicle.name', sort_key: 'booking__vehicle__name' },
          { name: 'start_at', key: 'start_at', sort_key: 'start_at' },
          { name: 'book_for', key: 'booking.book_for', sort_key: 'booking__book_for' },
          { name: 'delivery_address', key: 'booking.start_address', sort_key: 'start_address' },
          { name: 'booking_status', key: 'booking.status', sort_key: 'status' },
          { name: 'customer', key: 'booking.user.picture+booking.user.first_name+booking.user.last_name+booking.user.mobile_number', sort_key: 'user' },
          { name: 'KM', key: 'booking.vehicle.distance_by_car_in_meters' },
          { name: 'HH:MM', key: 'booking.vehicle.delivery_time_in_seconds' },
          { name: 'safty', key: 'safety_driver.picture+safety_driver.first_name+safety_driver.last_name', sort_key: 'safety_driver' },
          { name: 'teleop', key: 'dispatcher_driver.picture+dispatcher_driver.first_name+dispatcher_driver.last_name', sort_key: 'dispatcher_driver' },
        ]}
        rowSelect={(row: any) => {
          selectBook(row);
        }}
        getData={(props: any) => {
          request(props);
        }}
        tableData={Tasks}
        tableType={"Tasks_filter"}
        title={"Delivery  " + " - " + (Status ? Status : "All")}
      />
      {ShowMap && (<TasksDrawer
        TaskData={Task}
        close={(value: any) => { setShowMap(value) }}
        updateTable={() => { menuCounter(); }}
      />

      )}
    </div>
  );
}

import React, { useEffect } from "react";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  companyVehicles,
  companyVehicleEdit,
  companyVehicleDelete,
  companyVehicleCreate,
} from "../../redux/actions/table.action";
import Table from "../../utilities/components/Table/Table";
import { useTranslation } from "react-i18next";
import VehicleEdit from "./VehicleEdit";
// interface ChartProps {
//     // pin: () => void | undefined;
//     // remove: () => void | undefined;
//     // changeDate: (Range: object) => void | undefined;
//     // el: Record<string, any>;
// }

const DefaultParam = {
  offset: 0,
  limit: 10,
  search: false,
  ordering: false,
  according: false,
};

export default function Vehicles() {
  const dispatch = useDispatch();

  const [ActiveVehicle, setActiveVehicle] = React.useState<any>({});
  const [Result, setResult] = React.useState<any>();
  const [DefaultParams, setDefaultParams] = React.useState<any>(DefaultParam);
  const tableData = useSelector((state: RootState) => state.table.companyVehicles);
  useEffect(() => {
    dispatch(companyVehicles(DefaultParams));
  }, []);
  useEffect(() => {
    if (Result) {
      dispatch(companyVehicles(DefaultParams));
    }
  }, [Result]);

  async function request(Params: any) {

    await dispatch(companyVehicles(Params));

  }


  return (
    <div>
      {ActiveVehicle?.id && <VehicleEdit Data={ActiveVehicle} Close={() => {
        setActiveVehicle({})
      }}
        UpdateList={(action) => {
          request(DefaultParams)
        }}
      />}
      <Table
        rowSelect={(row: any) => {
          setActiveVehicle(row);
        }}
        getData={(props: any) => {
          request(props);
        }}
        tableData={tableData}
        tableType={"Vehicles"}
        title={"Vehicles"}
      />

    </div>
  );
}

import { useEffect, useState } from "react";
import Dashboard from "./Package/index";
import { getSetting, saveSetting, stat } from "../../redux/actions/dashboard.action";
import { useDispatch } from "../../redux/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface Props { }

export default function AppL({ }: Props) {
  const dispatch = useDispatch();
  const [widgetData, setwidgetData] = useState([]);
  const [Token, setToken] = useState(null);
  const [Settings, setSettings] = useState([]);

  useEffect(() => {
    WidgetsList();
    get();
  }, []);

  async function get() {
    // const items = localStorage.getItem("dashboard");
    // console.log("dashboard Reed Items", items);

    await dispatch(getSetting()).then((res: any) => {
      // var settingsJson = res.payload ? JSON.parse(res.payload) : [];
      if (res && res.payload) {
        console.log("dashboard Reed Items", res.payload);
        const response: any = res.payload.setting
        // const response:any = [{"id":"section1","show":true,"items":[
        //   {"h":4,"i":"lZJe7-0fabd947-5439-460f-9f8f-21ffe3ba3026-section_id=section1","w":3,"x":0,"y":0,"id":3,"icon":"","maxH":6,"maxW":3,"minH":3,"minW":1,
        //   "name":"link_prediction_error_histogram","title":"Link Prediction Error Histogram","active":true,"legend":false,"static":false,"category":2,"end_date":null,
        //   "url_path":"https://staging-stat.fetch-mobility.com/fetch/trips/teleoperated-delivery/","section_id":"section1","start_date":null,"description":"This widget shows the histogram of predicted errors","isDraggable":true,"isResizable":true,"section_key":0,
        //   "parameter" : [
        //     {
        //       type:"select",
        //       name:"latitude",
        //       values:[14.32121,1.661,214.4],
        //       related_parameter:"longitude",
        //       url:"/network/link-prediction/latitude-list",
        //       icon : ""
        //     },
        //     {
        //       type:"select",
        //       name:"user",
        //       traget_name : "id",
        //       values:[{name:"ali",id:"1"},{name:"Pouya",id:"2"}],
        //       related_parameter:"longitude",
        //       url:"/network/link-prediction/latitude-list"
        //     }
        //   ]
        // }
        // ],"title":"Section 1"}]

        if (response) {
          setSettings(response);
        }

      }
      //   return setwidgetData(res.payload);
    });

  }

  async function save(setting: any) {
   // console.log("save", setting.length)
    if (setting && setting.length) {
      setSettings(setting)
      //localStorage.setItem("dashboard", JSON.stringify(setting));
      await dispatch(saveSetting(setting))
    }
  }
  async function WidgetsList() {
    await dispatch(stat(`${process.env.REACT_APP_STATS_ADDR_APIS}/` + "widgets/")).then((res) => {
      const response: any = res.payload;
      if (response) {
 
        return setwidgetData(response);
      } else {
        toast.error("server didn't support widgets for prodaction please use staging", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }
  const serverAddress = `${process.env.REACT_APP_STATS_ADDR_APIS}/`;
  
  async function widgetDataFunc(url: any, p: any) {
    //const prop = p.start_date ?  propChr + "startdate=" + p.start_date + "&enddate=" + p.end_date : '';
    console.log("p",p)
    let props = '';
    let FinalP:any = [];
    Object.keys(p).map((k)=>{
      if(p[k]){
        FinalP.push(k)
      }
    })


    FinalP.map((keyName:any, i:any) => {
    
      
      let propChr = "&";
      if (i === 0) {
        propChr = url.includes('?') ? "&" : "?"
      }
      if (p[keyName]) {
        props += propChr + keyName + "=" + p[keyName]
      }
    }
    )
   
    const request = await dispatch(
      stat(serverAddress + url + props)
    ).then((res) => {
      
      return res.payload
    }).catch((e) => {

    });


    return request
  }


  return (
    <>
      {Settings && (
        <Dashboard
          widgetData={widgetDataFunc}
          string={null} //i18n
          reload={false}
          menuOpen={false} // can deleted
          saveSetting={(setting: any) => {
            //console.log('a')
            save(setting);
          }}

          getSetting={Settings}
          WidgetsList={{
            categories: [
              {
                id: 1,
                name: "fetch",
                icon_path: "/some-icon/",
              },
            ],
            widgets: widgetData,
          }}
          assets={{
            slideshow_logo: "",
          }} //assets
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import OutsideClickHandler from "react-outside-click-handler";
import Widget from "./Widgets/WidgetCreator";
import DatePicker from "./Widgets/Header/DatePicker";
import "react-animated-slider/build/vertical.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface Props {
  widgetData: any;
  Ready: boolean;
  Breakpoint: string;
  OpenDateZindex: Record<string, any>;
  assets: Record<string, any>;
  SliderIndex: number;
  Scale: number;
  Sections: any;
  RenameSection: any;
  Slider: boolean | true;
  Overview: boolean | true;
  SingleOverview: any;
  SectionTitle: string;
  setSectionTitle: (name: any) => void | undefined;
  SlideShowMode: boolean;
  setSlideShowMode: (status: any) => void | undefined;
  HiddenSections: any;
  setSections: (sections: any) => void | undefined;
  loadedItem: (el: any) => void | undefined;
  setRenameSection: (el: any) => void | undefined;
  setHiddenSections: (el: any) => void | undefined;
  openSectionCalendar: (el: any) => void | undefined;
  SectionCalendar: string;
  setSectionCalendar: (el: any) => void | undefined;
  handleDateChange: (range: any, key: any) => void | undefined;
  SingleOverviewFunction: (el: any) => void | undefined;
  openSectionSetting: (el: any) => void | undefined;
  OpenSetting: string;
  setOpenSetting: (el: any) => void | undefined;
  deleteSection: (el: any) => void | undefined;
  showAddView: (el: any, key: any) => void | undefined;
  SectionMove: (el: any, key: any) => void | undefined;
  onDragStop: (layouts: any, oldItem: any, newItem: any) => void;
  onResizeStop: (layouts: any, oldItem: any, newItem: any) => void;
  onLayoutChange: (layouts: any) => void;
  onBreakpointChange: (breakpoint: any) => void;
  widgetDateChange: (range: any, section_key: any, i: any) => void | undefined;
  setOpenDateZindex: (state: boolean, el: any) => void | undefined;
  pinItem: (el: any) => void | undefined;
  infoItemAction: (el: any) => void | undefined;
  removeItem: (el: any) => void | undefined;
  SectionAction: () => void | undefined;
  t: Record<string, any>;
}

export default function SectionCreator({setSlideShowMode,
  widgetData,
  Ready,
  SectionAction,
  Breakpoint,
  t,
  deleteSection,
  Sections,
  SingleOverviewFunction,
  Slider,
  openSectionSetting,
  SectionMove,
  Overview,
  showAddView,
  setOpenSetting,
  SlideShowMode,
  OpenSetting,
  HiddenSections,
  assets,
  OpenDateZindex,
  handleDateChange,
  setSections,
  SectionTitle,
  setHiddenSections,
  openSectionCalendar,
  RenameSection,
  setSectionTitle,
  SectionCalendar,
  setSectionCalendar,
  setRenameSection,
  onDragStop,
  onResizeStop,
  onLayoutChange,
  onBreakpointChange,
  widgetDateChange,
  setOpenDateZindex,
  loadedItem,
  pinItem,
  infoItemAction,
  removeItem,
}: Props) {
  function HideShowSection(index: any) {
    const section = [...Sections];
    section[index].show = !section[index].show;
    setSections(section);
  }
  const [CalendarDate, setCalendarDate] = useState<any>("");

  const handle = useFullScreenHandle();
  const [SlideTimer, setSlideTimer] = useState(30000);
  const [Scale, setScale] = useState(1);
  const [CountDown, setCountDown] = useState<any>(null);
  const [SingleOverview, setSingleOverview] = useState(null);
  const [SliderStop, setSliderStop] = useState(false);
  const [SliderIndex, setSliderIndex] = useState(0);

  function itemWidegt(i: any, el: any) {
    try {
      return (
        <Widget
          widgetData={widgetData}
          SectionAction={() => SectionAction()}
          Breakpoint={Breakpoint}
          t={t}
          load={el.load}
          changeDate={(range) => {
            widgetDateChange(range, el.section_key, el.i);
          }}
          DateOpen={(state, el) => {
            setOpenDateZindex(state, el);
          }}
          loaded={() => {
            loadedItem(el);
          }}
          el={el}
          options={el.options}
          pin={() => {
            pinItem(el);
          }}
          info={() => {
            infoItemAction(el);
          }}
          remove={() => {
            removeItem(el);
          }}
        />
      );
    } catch {
      return <div>there is no data available</div>;
    }
  }

  function itemsHtml(i: any, el: any) {
    const widget = itemWidegt(i, el);

    return (
      <div
        key={i}
        data-grid={el}
        className={
          OpenDateZindex && OpenDateZindex.i === i
            ? "itemDrag zIndexHigh"
            : "itemDrag "
        }
      >
        {widget}
      </div>
    );
  }
  function createElement(el: any) {
    const i = el.add ? "+" : el.i;

    return itemsHtml(i, el);
  }

  function StateSection(id: any) {
    const array = [...HiddenSections];
    const index = array.indexOf(id);
    if (index !== -1) {
      array.splice(index, 1);
      setHiddenSections(array);
    } else {
      setHiddenSections((oldArray: any) => [...oldArray, id]);
    }
  }

  function sectionClass(id: any, key: any) {
    let className = "dashboardSection ";
    const section_id =
      OpenDateZindex && OpenDateZindex.i && OpenDateZindex.i.split("=")[1];
    if (SlideShowMode) {
      const si = SliderIndex !== key ? "HiddenSection" : "shownSlide";
      className += " SectionSlide  " + si;
    }
    if (Overview) {
      className += " overviewSection ";
    }
    if (!SlideShowMode && !Overview) {
      if (HiddenSections.indexOf(id) > -1) {
        className += " dashboardSectionHidden";
      }
    }
    if (SingleOverview === id) {
      className =
        "dashboardSection slider slideShowDashboard slideShowDashboard SingleOverview";
    }
    if (section_id === id) {
      className += " zIndexHighSection";
    }
    return className;
  }
  function handleChangeTitle(e: any) {
    setSectionTitle(e.target.value);
  }

  function elements(section: any, key: any) {
    const els = (<div
      id={"section-" + key}
      key={key}
      className={sectionClass(section.id, key)}
    >
      {Overview && (
        <div className={"overviewColTitle"}>
          <span
            className={
              section.show
                ? "overviewColCheck overviewColCheckActive"
                : "overviewColCheck "
            }
            onClick={() => {
              HideShowSection(key);
            }}
          >
            {section.show ? <img src={"/assets/icons/check.svg"} /> : ""}
          </span>
          {section.title}
        </div>
      )}

      <div className={Overview ? "sectionHeaderHidden" : "sectionHeader"}>
        <h2 className={"sectionTitle"}>
          {RenameSection === key ? (
            <div className={"sectionEditTitle"}>
              <input
                type={"text"}
                onChange={(e) => {
                  handleChangeTitle(e);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    ChangeTitle();
                  }
                }}
                defaultValue={section.title}
              />
              <div
                onClick={() => {
                  setRenameSection("");
                }}
              >
                <img alt={""} src={"/assets/icons/closeCircle.svg"} />
              </div>
              <div
                onClick={() => {
                  ChangeTitle();
                }}
              >
                <img alt={""} src={"/assets/icons/icon-basic-drafts.svg"} />
              </div>
            </div>
          ) : (
            <>
              {section.title}
              <div
                onClick={() => {
                  StateSection(section.id);
                }}
                className={"sectionState"}
              >
                {HiddenSections.indexOf(section.id) > -1 ? (
                  <img
                    alt={""}
                    src={"/assets/icons/icon-basic-arrow-right.svg"}
                  />
                ) : (
                  <img
                    alt={""}
                    src={"/assets/icons/icon-basic-arrow-down.svg"}
                  />
                )}
              </div>
            </>
          )}
        </h2>
        <div className={"sectionHeaderSetting"}>
          {!SlideShowMode ? (
            <>
              <div
                className={"sectionHeaderSettingItem"}
                onClick={() => {
                  openSectionCalendar(section.id);
                }}
              >
                <img alt={""} src={"/assets/icons/icon-basic-calendar.svg"} />
              </div>
              {SectionCalendar === section.id && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setSectionCalendar("");
                  }}
                >
                  <div className="datePickerChart datePickerSection">
                    <DatePicker
                      t={t}
                      defaultData={""}
                      onDateChange={(range: {
                        start_date: string;
                        end_date: string;
                      }) => {
                        setCalendarDate(range);
                      }}
                    />
                    <div className="DatePickerBtn">
                      <button
                        className="cancel"
                        onClick={() => {
                          setSectionCalendar(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="ok"
                        onClick={() => {
                          console.log("CalendarDate",CalendarDate)
                          handleDateChange(CalendarDate, key);
                          setSectionCalendar("");
                        }}
                      >
                        {t.ok}
                      </button>
                    </div>
                  </div>
                </OutsideClickHandler>
              )}

              <div
                className={"sectionHeaderSettingItem"}
                onClick={() => {
                  SingleOverviewFunction(section.id);
                }}
              >
                <img alt={""} src={"/assets/icons/icon-basic-preview.svg"} />
              </div>
              <div
                className={"sectionHeaderSettingItem"}
                onClick={() => {
                  openSectionSetting(key);
                }}
              >
                <img alt={""} src={"/assets/icons/dots.svg"} />
                {OpenSetting === key && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setOpenSetting("");
                    }}
                  >
                    <div className={"SettingItemSubMenu"}>
                      <div
                        onClick={() => {
                          showAddView(section.id, key);
                        }}
                        className={"SettingItemSubMenuItem"}
                      >
                        <img alt={""} src={"/assets/icons/plus.svg"} />
                        <span>{t.add_element}</span>
                      </div>
                      <div
                        onClick={() => {
                          SectionMove(true, key);
                        }}
                        className={
                          key === 0
                            ? "SettingItemSubMenuItem SettingItemSubMenuItemDisable"
                            : "SettingItemSubMenuItem"
                        }
                      >
                        {key === 0 ? (
                          <img
                            alt={""}
                            src={"/assets/icons/ArrowUpDisable.svg"}
                          />
                        ) : (
                          <img alt={""} src={"/assets/icons/ArrowUp.svg"} />
                        )}
                        <span>{t.move_section_up}</span>
                      </div>
                      <div
                        onClick={() => {
                          SectionMove(false, key);
                        }}
                        className={
                          key + 1 === Sections.length
                            ? "SettingItemSubMenuItem SettingItemSubMenuItemDisable"
                            : "SettingItemSubMenuItem"
                        }
                      >
                        {key + 1 === Sections.length ? (
                          <img
                            alt={""}
                            src={"/assets/icons/ArrowDownDisable.svg"}
                          />
                        ) : (
                          <img alt={""} src={"/assets/icons/ArrowDown.svg"} />
                        )}
                        <span>{t.move_section_down}</span>
                      </div>
                      <div
                        onClick={() => {
                          setRenameSection(key);
                        }}
                        className={"SettingItemSubMenuItem"}
                      >
                        <img
                          alt={""}
                          src={"/assets/icons/icon-basic-drafts.svg"}
                        />
                        <span>{t.rename_section}</span>
                      </div>
                      <div
                        onClick={() => {
                          deleteSection(section.id);
                        }}
                        className={"SettingItemSubMenuItem"}
                      >
                        <img
                          alt={""}
                          src={"/assets/icons/icon-basic-delete.svg"}
                        />
                        <span>{t.delete_section}</span>
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            </>
          ) : (
            <div>
              {/* {assets && assets.slodeshow_logo &&   <img src={assets.slodeshow_logo} className={"companyLogoSlideShow"} />} */}
            </div>
          )}
        </div>
      </div>
      <div
        id={"sectionIdScale"}
        className={Overview ? "ViewsItems" : "origin"}
        style={{
          transform: "scale(" + Scale + ")",
        }}
      >

        {Ready && (
          <ResponsiveReactGridLayout
            onDragStop={onDragStop}
            onResizeStop={onResizeStop}
            onLayoutChange={onLayoutChange}
            onBreakpointChange={onBreakpointChange}
            //onResize={(a,b,c)=>{console.log(a,b,c)}}
            rowHeight={100}
            margin={[15, 15]}
            //onDrop={onDrop}
            isDroppable={true}
            measureBeforeMount={false}
            cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
          >

            {section &&
              section.items !== undefined &&
              section.items.length ? (
              section.items.map((el: any) => createElement(el))
            ) : (
              <div
                onClick={() => showAddView(section.id, key)}
                key={"add"}
                data-grid={{
                  type: "add",
                  section_id: "section1",
                  title: "",
                  i: "add",
                  isResizable: false,
                  isDraggable: false,
                  static: false,
                  load: true,
                  x: 0,
                  y: 0,
                  w: 1,
                  h: 2,
                  text: "",
                  class: "addItem",
                }}
                className={"static itemDrag addItem"}
              >
                <img
                  alt={""}
                  src={"/assets/icons/plus-sign.svg"}
                  className={"add-icon"}
                />
              </div>
            )}
          </ResponsiveReactGridLayout>
        )}
      </div>
    </div>);
    return (<>{SlideShowMode ? <>{els}</> : <>{els}</>}</>)
  }


  // function SliderTimer() {
  //   const times = [30000, 60000, 120000, 300000, 600000];
  //   return (
  //     <div className={"timesSlide"}>
  //       {times.map((time, key) => (
  //         <div
  //           key={key}
  //           onClick={() => {
  //             setSlideTimer(time);
  //           }}
  //           className={SlideTimer === time ? "activeTime" : ""}
  //         >
  //           {time / 60000 < 1
  //             ? (time / 60000) * 60 + " Seconds"
  //             : time / 60000 + " Minutes"}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }

  useEffect(()=>{
  
    if(SlideShowMode) {
      handle.enter()
    } 
  },[SlideShowMode])

  function slideAction(next = true) {
    const Sec = [];
    Sections.map((section: any) => section.show && Sec.push(section));
    if (next) {
      if (SliderIndex + 1 < Sec.length) {
        setSliderIndex(SliderIndex + 1);
      } else {
        setSliderIndex(0);
      }
    } else {
      if (SliderIndex === 0) {
        setSliderIndex(Sec.length - 1);
      } else {
        setSliderIndex(SliderIndex - 1);
      }
    }
    CountDown && CountDown.start();
  }
  function sliderActionView() {
    return (<div className={"dashboardSliderActionView"} >
      <div className={"dashboardSliderAction"} >

        <div className={"fsLogoSlideShow"}>
          <img src={"/assets/logo/Driverless Sticker w QR.png"} />
        </div>

        <div className={"SliderActionGroup"}>


        </div>
        <div className={"SliderActionGroup"}>
          <div
            className={"sliderAction"}
            onClick={() => {
              slideAction(true);
            }}
          >
            <img src={"/assets/icons/Low.svg"} />
          </div>
          <div
            className={"sliderAction"}
            onClick={() => {
              slideAction(false);
            }}
          >
            <img src={"/assets/icons/High.svg"} />
          </div>
        </div>
        <div className={"SliderActionGroup"}>
          <div
            className={"sliderAction"}
            onClick={() => {
              if (CountDown) {
                setSliderStop(!SliderStop);
                if (SliderStop) {
                  CountDown.start();
                } else {
                  CountDown.pause();
                }
              }
            }}
          >

            {!SliderStop ? (
              <img src={"/assets/icons/Pause.svg"} />
            ) : (
              <img src={"/assets/icons/Play.svg"} />
            )}
          </div>
          <div
            className={"sliderAction"}
            onClick={() => {
              // exit();
                  setSlideShowMode(false);
            //  setScale(1);
            handle.exit()   
            }}
          >
            <img src={"/assets/icons/closeCircle.svg"} />
          </div>
        </div>
      </div></div>)
  }

  async function ChangeTitle() {
    const section = [...Sections];
    section[RenameSection].title = SectionTitle;
    setSections(section);
    setRenameSection("");
  }
  function SectionMap() {
    const Secs: any = [];
    if (Slider) {
      Sections.map((section: any) => section.show && Secs.push(section));
    } else {
      Sections.map(
        (section: any) =>
          (Overview || (section.show && !Overview)) && Secs.push(section),
      );
    }

    return Secs.map(
      (section: any, key: any) =>
        section && (SlideShowMode ? <div className="slide-container">{sliderActionView()}  {elements(section, key)}</div> : elements(section, key))
    );
  }

  return (SlideShowMode ? <FullScreen handle={handle}>{SectionMap()}</FullScreen> : SectionMap());
}

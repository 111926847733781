import React, { useEffect, useLayoutEffect } from "react";
import { sortByDistance } from 'sort-by-distance'
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker,
  Circle, Tooltip,
  Polyline,
  FeatureGroup, LayerGroup, useMap, CircleMarker
} from "react-leaflet";
import { Icon, LatLngBounds, LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
interface CCMapProps {
  Data: any;
}


export default function CCMap({ Data }: CCMapProps) {
  function iconGenerator(vehicle: any) {
    const carIcon = new Icon({
      iconUrl: vehicle.vehicle_type ? vehicle.vehicle_type.vehicle_image : 'https://fetch-rest-static-staging.s3.eu-west-1.amazonaws.com/static/vehicle_pics/e-niro.png',
      iconSize: [60, 50],
    });
    return carIcon;
  }


  const arrIcon = new Icon({
    iconUrl: "/assets/icons/IconDestination.png",
    iconSize: [50, 90],
  });
  const length = Data.length;



  function routeLine(leg: any) {
    const points: any = [];
    leg.points.map((point: any) => {
      let p = [point.latitude, point.longitude];
      points.push(p);
    });

    return (
      <Polyline positions={points} pathOptions={{ color: "rgb(10 187 112)" }} />
    );
  }

  function getSort(Data: any) {
    let d: any = [];
    Data.map((p: any) => {
      let singlePoint: any = {}
      singlePoint.longitude = p.coordinates ? p.coordinates[0] : p.location_point.coordinates[0];
      singlePoint.latitude = p.coordinates[1] ? p.coordinates[1] : p.location_point.coordinates[1];
      d.push(singlePoint)
    })


    let point1: any = { latitude: Math.max.apply(Math, d.map(function (o: any) { return o.latitude; })), longitude: Math.max.apply(Math, d.map(function (o: any) { return o.longitude; })) };
    let point2: any = { latitude: Math.min.apply(Math, d.map(function (o: any) { return o.latitude; })), longitude: Math.min.apply(Math, d.map(function (o: any) { return o.longitude; })) };


    return [point1, point2]

  }

  function cornerFinder(Data: any) {
    const points = getSort(Data)
    const ponstLength = points.length
    if (points && Data.length) {
      // var corner1 = new LatLng(
      //   Data[length - 1] && Data[length - 1].location_point ? Data[length - 1].location_point.coordinates[1] : Data[length - 1].coordinates ? Data[length - 1].coordinates[1] : 0,
      //   Data[length - 1] && Data[length - 1].location_point ? Data[length - 1].location_point.coordinates[0] : Data[length - 1].coordinates ? Data[length - 1].coordinates[0] : 0,
      // );
      // var corner2 = new LatLng(
      //   Data && Data[0] && Data[0].location_point ? Data[0].location_point.coordinates[1] : Data[0].coordinates ? Data[0].coordinates[1] : 0,
      //   Data && Data[0] && Data[0].location_point ? Data[0].location_point.coordinates[0] : Data[0].coordinates ? Data[0].coordinates[0] : 0,
      // );
      var corner1 = new LatLng(points[0].latitude, points[0].longitude);
      var corner2 = new LatLng(points[ponstLength - 1].latitude, points[ponstLength - 1].longitude);
      var bounds = new LatLngBounds(corner1, corner2);
      return bounds
    } else {


      var corner1 = new LatLng(52.00938082349798, -0.731685814950479);
      var corner2 = new LatLng(52.03526177, -0.77185288);

      var bounds = new LatLngBounds(corner1, corner2);
      return bounds
    }
  }



  function mapInfoRender() {
    return (<><TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
      {Data[0] && length && Data[0].type === 'circle' &&
        Data.map((circle: any, key: any) => (
          <Circle
            key={key}
            center={[circle.coordinates[1], circle.coordinates[0]]}
            pathOptions={{ color: circle.color, fillColor: circle.color }}
            radius={circle.radius}
          >
            {circle.tooltip && <Tooltip sticky direction="top" >{circle.tooltip}</Tooltip>}

          </Circle>))
      }
      {Data[0] && length && Data[0].type === 'circle_marker' &&
        Data.map((circle: any, key: any) => (
          <CircleMarker
            key={key}
            center={[circle.coordinates[1], circle.coordinates[0]]}
            pathOptions={{ color: circle.color, fillColor: circle.color }}
            radius={circle.radius}
          >
            {circle.tooltip && <Tooltip sticky direction="top" >{circle.tooltip}</Tooltip>}

          </CircleMarker>))
      }












      {/* {Data.car_routes && Data.car_routes.routes &&
      Data.car_routes.routes.map((route: any, key: any) => (
        <FeatureGroup key={key}>
          <Popup>Popup in FeatureGroup</Popup>
          {route &&
            route.legs &&
            route.legs.map((leg: any) => routeLine(leg))}
        </FeatureGroup>
      ))} */}
      {/* {Data.car_routes && Data.car_routes.routes &&
      Data.car_routes.routes.map((route: any, key: any) => (
        <FeatureGroup key={key}>
          <Popup>Popup in FeatureGroup</Popup>
          {route &&
            route.legs &&
            route.legs.map((leg: any) => routeLine(leg))}
        </FeatureGroup>
      ))} */}

      {Data[0] && length && Data[0].vehicle_type &&
        Data.map((vehicle: any, key: any) => (
          <Marker
            key={key}
            position={[
              vehicle && vehicle.location_point
                ? vehicle.location_point.coordinates[1]
                : 0,
              vehicle && vehicle.location_point
                ? vehicle.location_point.coordinates[0]
                : 0,
            ]}
            icon={iconGenerator(vehicle)}
          >
            <Popup>Location</Popup>

          </Marker>
        ))}

      {/* <Marker
      position={[
        Data.end_location.coordinates[1],
        Data.end_location.coordinates[0],
      ]}
      icon={arrIcon}
    >
      <Popup>Arrival Location</Popup>
    </Marker> */}</>)
  }


  const centerBackend = Data.center
  const zoomBackend = Data.zoom

  return (Data.zoom ? <MapContainer center={centerBackend} zoom={zoomBackend} scrollWheelZoom={false} trackResize={true}>{mapInfoRender()}</MapContainer> : <MapContainer bounds={cornerFinder(Data)} scrollWheelZoom={true} trackResize={true} >{mapInfoRender()}</MapContainer>)
}

import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";

interface Props {
  cols: Record<string, any>;
  active: Array<string>;
  close: () => void | undefined;
  filter: (type: string) => void | undefined;
}

export default function ColFilter({ cols, close, active, filter }: Props) {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        close();
      }}
    >
      <div className={"colFilter"}>
        {cols.map((col: string, key: number) => (
          <div
            key={key}
            className={"filterRow"}
            onClick={() => {
              filter(col);
            }}
          >
            <input
              type="checkbox"
              checked={active.includes(col) ? false : true}
            />
            <span>{col.replaceAll("_", " ")}</span>
          </div>
        ))}
      </div>
    </OutsideClickHandler>
  );
}

import React, { useState } from 'react'


export default function Footer() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [City, setCity] = useState("");
  const [Erorr, setError] = useState("");
  const [Sent, setSent] = useState("");

  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  function errorHandler(res) {
    try {
      let errorMassage = "";
      const keys = Object.keys(res);
      keys.map((key, i) => {
        let message = "";
        const objData = res[key];
        const inputName = key.replaceAll("_", " ");
        if (objData.isArray) {
          objData.map(
            (massageText) =>
              (message += inputName + " : " + massageText + "  "),
          );
        } else {
          message = inputName + " : " + objData + "  ";
        }
        errorMassage += message;
      });


      return errorMassage.replaceAll(",", "  ");
    } catch (error) {
      return "Server Error 500";
    }
  }
  async function send() {
    if (!Name.length) {
      setError('* Name : This field may not be blank.')
      return ''
    }
    if (!re.test(Email)) {
      setError('* Email must be a valid email address')
      return ''
    }
    if (!City.length) {
      setError('* City & Country : This field may not be blank.')
      return ''
    }


    const formData = new FormData();
    formData.append('name', Name);
    formData.append('email', Email);
    formData.append('location', City);
    await fetch('https://api.fetch-mobility.com/webcontact/', {
      method: 'POST',
      body: formData
    }).then(async function (response) {
      if (response.status === 201) {
        setError(false)
        setSent(true)
      } else {
        setSent(false)
        setError(true)
        const r = await response.json()
        setError(errorHandler(r))

      }

    }).catch((er) => {
      setSent(false)
      setError(true)

    });


  }
  return (<footer>

    <div className="stay">

      <div className="formStay">


        {!Sent ?
          <>
                  <div className="title">Stay Tuned</div>
        <div className="desc">Sign up so we can let you know
          once we are in your city</div>
            <input type="text" placeholder="Name *" value={Name} onChange={(e) => { setName(e.target.value) }} />
            <input type="email" placeholder="Email *" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
            <input type="text" placeholder="City & Country *" value={City} onChange={(e) => { setCity(e.target.value) }} />
            {Erorr && <div className="resMassage"><div className='Error'>{Erorr}</div></div>}

            <div className="formButton" onClick={() => {
              send()
            }}>Join the waiting list</div>
          </>
          :
          <div className='Success'>
            <strong>Thanks for signing up!</strong>
            We have sent you an email to verify your contact details.

          </div>
        }

      </div>

    </div>
    <div className="footerInfo">
      <div className="footerLogo">
        <a href="https://imperiumdrive.com/" target="_blank" ><img src="/assets/icons/PoweredByID.png" /></a>
      </div>
      <div className="footerLinks">
        {/* <div className="socials">
          <a href="#">
            <img src="" />
          </a>
        </div> */}
        <span>hello@fetch-mobility.com</span>
        <span>Copyright 2022 Fetch Mobility © All rights reserved</span>

      </div>

    </div>
  </footer>);
}

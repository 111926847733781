import React, { useEffect } from "react";
import { useDispatch } from "../../redux/store";
import { TelematicsDevices } from "../../redux/actions/vehicle.action";
import { companyVehicleEdit, damages, companyVehicleGet } from "../../redux/actions/table.action";
import { DropDown } from "../../utilities/components/FromUtils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutsideClickHandler from "react-outside-click-handler";
import Moment from "moment";
import { LoadingView } from "./../../utilities/components/Loading";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import DamageDetails from "../ReportDamages/DamageDetails";
import { actionPermission } from "../../redux/apiGateway";
interface Props {
  Close: () => void | undefined;
  UpdateList: (action: boolean) => void | undefined;
  Data: Record<string, any>;
}

export default function VehicleEdit({ Data, Close, UpdateList }: Props) {

  const dispatch = useDispatch();
  const [SelectedData, setSelectedData] = React.useState<any>(Data);
  const [Telematics, setTelematics] = React.useState<any>([]);
  const [Damages, setDamages] = React.useState<any>([]);
  const [Loading, setLoading] = React.useState(false);
  const [DamageDetailsView, setDamageDetailsView] = React.useState(false);
  const [SelectedDamage, setSelectedDamage] = React.useState({});


  const user = useSelector((state: RootState) => state.user.profile);

  function getDamages(){
    dispatch(companyVehicleGet(Data.id)).then((vResponse: any) => {
      if (!vResponse.payload.error) {
        dispatch(damages()).then((dResponse: any) => {
          if (!dResponse.payload.error) {
            let ds: any = [];
            dResponse.payload.results.map((damage: any) => {
              if (vResponse.payload.damage_set.includes(damage.id)) {
                ds.push(damage)
              }
            })
            ds.sort(function (a: any, b: any) {
              if (a.area > b.area) {
                return -1;
              }
              if (a.area < b.area) {
                return 1;
              }
              return 0;
            });
            setDamages(ds)
          }
        });


      }
    });
  }

  useEffect(() => {

    dispatch(TelematicsDevices()).then((res) => {
      if (res.payload && res.payload.results) {
        setTelematics(res.payload.results)
      }
    });

    getDamages()



  }, []);


  function lockAction(type: any, action: any) {
    let name: any = null;
    if (type === "lock") {
      name = 'is_locked'
    }
    if (type === "immobilizer") {
      name = 'is_immobilizer_locked'
    }
    setSelectedData({
      ...SelectedData,
      [name]: action,
    });

  }
  function changeKey(e: any) {
    setSelectedData({
      ...SelectedData,
      [e.target.name]: e.target.value,
    });
  }

  function changeArray(name: any, data: any) {
    setSelectedData({
      ...SelectedData,
      [name]: data,
    });
  }

  function Update() {
    setLoading(true)
    const data: any = {};
    data.id = Data?.id;
    data.name = SelectedData?.name;
    data.license = SelectedData?.license;
    if (SelectedData.telematics_device) {
      data.telematics_device = SelectedData.telematics_device?.id
    }
    if (SelectedData.is_locked !== Data.is_locked) {
      data.is_locked = SelectedData.is_locked
    }
    if (SelectedData.is_immobilizer_locked !== Data.is_immobilizer_locked) {
      data.is_immobilizer_locked = SelectedData.is_immobilizer_locked
    }






    dispatch(companyVehicleEdit(data)).then((response) => {
      if (response.payload) {
        if (!response.payload.error) {
          toast.success("Updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setSelectedData(response.payload)
          UpdateList(true)
          setLoading(false)
        } else {
          toast.error(response.payload.errorMassage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

    });
  }
  return (
    <div className="d-flex">
      <ToastContainer />

      <div className="EditContainer">
        <OutsideClickHandler onOutsideClick={() => {
          Close()
        }}>

          {DamageDetailsView && <DamageDetails Damage={SelectedDamage} Vehicle={Data} close={() => { setDamageDetailsView(false) }} DamageUpdate={(d: any) => { getDamages();setDamageDetailsView(false) }} />}

          <div className="EditCustomView customScrollBar">
            <div className="carImgBox">
              <img
                src={
                  SelectedData?.vehicle_image ? SelectedData?.vehicle_image : SelectedData?.vehicle_type?.vehicle_image
                }
                className="carImage"
              />
            </div>
            <div className="inputBox">
              <div className="title" >Status</div>
              <div className="rowhalf">
                <div className="editRow"><div className="editRowTitle">Autonomy  </div><input type="text" name="none" onChange={changeKey} value={SelectedData?.current_charge_level_percent + "% - " + SelectedData?.current_range_meters + "km"} disabled /></div>
                <div className="editRow"><div className="editRowTitle">Odometer  </div><input type="text" name="none" onChange={changeKey} value={SelectedData?.odometer} disabled /></div>
              </div>
              <div className="rowhalf">
                <div className="editRow"><div className="editRowTitle">Status  </div><input type="text" name="none" onChange={changeKey} value={SelectedData?.satus} disabled /></div>
                <div className="editRow"><div className="editRowTitle">Online  </div><input type="text" name="none" onChange={changeKey} value={Moment(SelectedData?.last_reported).format("ddd , D MMM YYYY hh:mm a")} disabled /></div>
              </div>
            </div>

            <div className="inputBox">
              <div className="title" >Vehicle Info</div>
              <div className="editRow"><div className="editRowTitle">Vehicle Type </div><input type="text" name="-" onChange={changeKey} value={SelectedData?.vehicle_type?.name} disabled /></div>
              <div className="editRow"><div className="editRowTitle">License Plate </div><input type="text" name="license" onChange={changeKey} value={SelectedData?.license} /></div>
              <div className="editRow"><div className="editRowTitle">Name </div><input type="text" name="name" onChange={changeKey} value={SelectedData?.name} /></div>
              <div className="editRow"><div className="editRowTitle">Telematics Device </div><DropDown
                selectedItem={SelectedData.telematics_device ? SelectedData.telematics_device : null}
                textEntity={"name"}
                items={Telematics}
                onChange={(selected: any) => {
                  changeArray('telematics_device', selected);
                }}
              /></div>


              <div className="editRow"><div className="editRowTitle">Damages List </div>
                <div className="dropwithActoin">
                  <div className="damageList">
                    {Damages.length ? Damages.map((d: any, key: any) => (
                      <div className="damegeItem" onClick={() => { setSelectedDamage(d); setDamageDetailsView(true) }}>
                        <div className="description">{d.description}</div>
                        {d.area.length ? <span className="area">{d.area}</span> : ''}
                        {d.side.length ? <span className="area">{d.side}</span> : ''}
                        {d.size.length ? <span className="area">{d.size}</span> : ''}
                        <div className="bool"><span className={"is_repaired-" + d.is_repaired}>is repaired</span><span className={"is_valid-" + d.is_valid} >is valid</span></div>
                      </div>

                    )) : <span>No Damage Reported</span>}

                  </div>
                  <div className="addActoin" onClick={() => { setSelectedDamage({}); setDamageDetailsView(true) }}><span>Create New Damage</span></div>
                </div>
              </div>





              <div className="editRow"><div className="editRowTitle">Available for Booking </div><input type="text" name="none" onChange={changeKey} value={SelectedData?.AvailableForBooking} disabled /></div>


            </div>


            <div className="inputBox">
              <div className="title" >Vehicle Control</div>
              <div className="lockview">
                <div className="lockrow">
                  <div className="lockrowTitle">
                    <img src="/assets/icons/Lock.svg" />Door lock
                  </div>
                  <div className="lockrowBtns">
                    <div onClick={() => { lockAction('lock', false) }} className={SelectedData?.is_locked ? "lockBtn unlock" : "lockBtn unlock disable"}> <img src="/assets/icons/Unlock_white.svg" />Unlock</div>
                    <div onClick={() => { lockAction('lock', true) }} className={SelectedData?.is_locked ? "lockBtn lock disable" : "lockBtn lock"}> <img src="/assets/icons/Lock_white.svg" />Lock</div>
                  </div>

                </div>
                <div className="lockrow">
                  <div className="lockrowTitle">
                    <img src="/assets/icons/Gear.svg" />Immobilizer active
                  </div>
                  <div className="lockrowBtns">
                    <div onClick={() => { lockAction('immobilizer', false) }} className={SelectedData?.is_immobilizer_locked ? "lockBtn unlock" : "lockBtn unlock disable"}> <img src="/assets/icons/Unlock_white.svg" />Unlock</div>
                    <div onClick={() => { lockAction('immobilizer', true) }} className={SelectedData?.is_immobilizer_locked ? "lockBtn lock disable" : "lockBtn lock "}> <img src="/assets/icons/Lock_white.svg" />Lock</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="inputBox">

             
              {actionPermission(user,'vehicleEdit') ?  <div className="editUpdate" onClick={() => Update()} ><img src="/assets/icons/icon-basic-refresh.svg" /> Update</div> : <div className="editUpdate" ><img src="/assets/icons/icon-basic-refresh.svg" /> You Can't Edit </div> }


            </div>

          </div>
          {Loading && (<LoadingView />)}
        </OutsideClickHandler>
      </div>
    </div>
  );
}

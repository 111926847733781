import * as React from "react";
import { Link } from "react-router-dom";
import {
  InputWithIcon,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import { resetPasswordValidator } from "../../utilities/functions/authValidator";
import validator from "validator";
import { useDispatch } from "../../redux/store";
import { resetPassword } from "../../redux/actions/auth.action";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      resetPassword();
    }
  };

  const resetPasswordHandler = () => {
    if (resetPasswordValidator({ email })) {
      dispatch(resetPassword({ email })).then(() => {
        const title = t("auth.Verification_email");
        toast.success(title, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setTimeout(() => {
          window.location.replace("/");

        }, 3000);
        //
      });
    }
  };

  return (
    <div className="main-container main-auth-container h100vh d-flex justify-content-center align-items-center forget-password-container">
      <ToastContainer />

      <div className="forgot-password-main d-flex flex-column fadeIn">
        <div className="forgot-password-main-top d-flex flex-column">
          <div className="login-form-inputs-header">
            <img
              className="login-form-inputs-header-img"
              src={"/assets/logo/logo.png"}
              alt="fetch-logo"
            />
          </div>
          {/* <div className="forgot-password-lockpad-container remove-under-1440">
            <img
              className="forgot-password-lockpad-img"
              src={"/assets/icons/Lockx2.png"}
              alt="padlock-logo"
            />
          </div> */}
          <small className="body-1-text forgot-password-top-text">
            {t("home.forgot-password")}
          </small>
        </div>
        <div className="forgot-password-main-bottom  d-flex flex-column">
          <InputWithIcon
            value={email}
            placeholder="Email"
            onKeyDown={(event) => handleKeyDown(event)}
            pngSource={"/assets/icons/@2x.png"}
            onChange={(email) => setEmail(email)}
          />
          <div className="login-button forgot-button">
            <LoadingButton
              disabled={!validator.isEmail(email)}
              onClick={() => resetPasswordHandler()}
              text={t("home.rest-password")}
            />
          </div>
          <Link
            to="/"
            className="forget-password-link-to-login deep-sky-blue-text"
          >
            Home Page
          </Link>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { LoadingView } from "./../../utilities/components/Loading";
import DateTimePicker from 'react-datetime-picker';
import { driverEdit, drivers } from "../../redux/actions/table.action";
import { userEdit, getBillingHistory, getCards } from "../../redux/actions/user.action";
import OutsideClickHandler from "react-outside-click-handler";
import {
  DropDown,
  InputWithIcon,
  CheckboxWithTitle,
  LoadingButton, ImagePicker,
} from "../../utilities/components/FromUtils";
import InputWrapper from "../../utilities/components/InputWrapper/InputWrapper";
import { Button } from "react-bootstrap";
import Moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { MasterCard, VisaCard } from "../../utilities/components/svg";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
interface Props {
  user: Record<string, any>;
  close: () => void | undefined;
  update: () => void | undefined;
}

export default function UserEdit({
  user,
  close,
  update
}: Props) {

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [TabActive, setTabActive] = React.useState("user");
  const [SelectedUser, setSelectedUser] = React.useState<any>(user.user);
  const [Licence, setLicence] = React.useState<any>(user);
  const [Stripe, setstripe] = React.useState<any>(user);
  const [BillingHistorys, setBillingHistorys] = React.useState<any>([]);
  const [Cards, setCards] = React.useState<any>([]);


  const [image, setImage] = React.useState(null);

  const [Loading, setLoading] = React.useState(false);

  function saveUser(data: any) {
    setLoading(true);
    let licenseData: any = {}
    licenseData.id = data.id;
    let user = { ...data }
    if (typeof data.picture !== "object") {
      user.picture = undefined; //Remove the image because it is a string
    }

    if (Licence.license_verification) { licenseData.license_verification = Licence.license_verification }
    if (Licence.license_number) { licenseData.license_number = Licence.license_number }
    if (Licence.license_check_code) { licenseData.license_check_code = Licence.license_check_code }
    if (Licence.license_expiry_date) { licenseData.license_expiry_date = Licence.license_expiry_date }

    dispatch(userEdit(user)).then((response: any) => {
      if (response.payload && !response.payload.errorMassage) {
        setSelectedUser(response.payload);
        toast.success("User Inputs :" + t("account.saved"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      } else {
        toast.error("error : " + response.payload.errorMassage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      }
    })
    dispatch(driverEdit(licenseData)).then((response: any) => {
      setLoading(false);

      if (response.payload && !response.payload.errorMassage) {
        toast.success("Licence Inputs :" + t("account.saved"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
        setLicence(response.payload);
        update()
      } else {
        toast.error("error : " + response.payload.errorMassage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      }
    });
  }
  React.useEffect(() => {
    if (image !== null) {
      setSelectedUser({
        ...SelectedUser,
        picture: image,
      });
    }
  }, [image]);


  React.useEffect(() => {
    dispatch(getBillingHistory(SelectedUser.id)).then((response: any) => {
      setBillingHistorys(response?.payload?.results)
    })

    dispatch(getCards()).then((response: any) => {
      setCards(response?.payload?.data)
    })



  }, [])



  function changeLicence(event: any) {
    const name = event.target.name;
    let value = event.target.value;

    setLicence({
      ...Licence,
      [name]: value,
    });
  }

  function change(event: any) {
    const name = event.target.name;
    let value = event.target.value;

    setSelectedUser({
      ...SelectedUser,
      [name]: value,
    });
  }


  function changeCheckBox(event: any) {
    const name = event.target.name;
    let value = event.target.checked;

    setSelectedUser({
      ...SelectedUser,
      [name]: value,
    });
  }
  function changeStripe(event: any) {
    const name = event.target.name;
    let value = event.target.value;

    setstripe({
      ...Stripe,
      [name]: value,
    });
  }



  return (

    <div className="editViewFa ">
      <OutsideClickHandler onOutsideClick={() => {
        close()
      }}>
        <div className="editViewPopup">
          <ToastContainer />
          <div
            className="editViewClose"
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/icons/closeCircle.svg" />
          </div>
          <div className="profile-fa profile-edit-fa customScrollBar">

            <div className="modal-account-account-detail d-flex">
              <div className="right-section col-4 bar-right-section">
                <ImagePicker
                  label="Click to change photo"
                  image={SelectedUser.picture ? SelectedUser.picture : undefined}
                  imageFile={(image: any) => setImage(image)}
                />

                <div className={"profileSide"}>
                  <div className={"profileSideItem"}>
                    <div className="account-balance">
                      <img src="/assets/icons/Wallet.svg" />

                      <small>Balance : £{SelectedUser.balance}</small>
                    </div>
                  </div>


                  <div className="profileTabs">
                    <div className={TabActive === "user" ? " profileTab profileTabActive" : "profileTab"} onClick={() => { setTabActive("user") }}>
                      <img src="/assets/icons/Details.svg" /> User Details
                    </div>
                    <div className={TabActive === "licence" ? "profileTab profileTabActive" : "profileTab"} onClick={() => { setTabActive("licence") }}>
                      <img src="/assets/icons/License.svg" /> License Details
                    </div>
                    <div className={TabActive === "wallet" ? "profileTab profileTabActive" : "profileTab"} onClick={() => { setTabActive("wallet") }}>
                      <img src="/assets/icons/Credit Card.svg" /> Wallet
                    </div>

                    <div className={TabActive === "permission" ? "profileTab profileTabActive" : "profileTab"} onClick={() => { setTabActive("permission") }}>
                      <img src="/assets/icons/Lock.svg" />Groups & Permissions

                    </div>
                  </div>


                </div>
              </div>


              {TabActive === 'user' && (<div className={"col-8 "}>
                <div className="profileSideColTitle">
                  User Details
                </div>
                <div className={"d-flex"}>
                  <div className="col-6 ">

                    <InputWrapper label={t("account.first_name") + ":"} important>
                      <input
                        name="first_name"
                        onChange={(value) => change(value)}
                        value={SelectedUser && SelectedUser.first_name}
                        placeholder="First Name"
                      />
                    </InputWrapper>
                    <InputWrapper label={t("account.last_name") + ":"} important>
                      <input
                        name="last_name"
                        onChange={(value) => change(value)}
                        value={SelectedUser && SelectedUser.last_name}
                        placeholder="Last Name"
                      />
                    </InputWrapper>
                    <InputWrapper label={t("account.email") + ":"} important>
                      <input
                        name="email"
                        onChange={(value) => change(value)}
                        value={SelectedUser && SelectedUser.email}
                        placeholder="Email"
                      />
                    </InputWrapper>
                    <InputWrapper label={t("account.phone") + ":"} important>
                      <input
                        name="mobile_number"
                        onChange={(value) => change(value)}
                        type="text"
                        pattern="[0-9]*"
                        className={""}
                        value={
                          SelectedUser && SelectedUser.mobile_number
                        }
                        placeholder="Mobile"
                      />
                    </InputWrapper>
                    <InputWrapper label={"Account Created" + ":"}>
                      <input
                        disabled={true}
                        name="date_joined"
                        onChange={(value) => change(value)}
                        type={"text"}
                        value={SelectedUser && Moment(SelectedUser.date_joined).format("ddd , D MMM YYYY hh:mm a")}
                        placeholder="Account Created"
                      />
                    </InputWrapper>

                    <InputWrapper label={"Last Booking" + ":"}>
                      <input
                        disabled={true}
                        name="last_booking"
                        onChange={(value) => change(value)}
                        type={"text"}
                        value={SelectedUser && Moment(SelectedUser.last_booking).format("ddd , D MMM YYYY hh:mm a")}
                        placeholder="Last Booking"
                      />
                    </InputWrapper>

                  </div>
                  <div className="col-6 userTextArea">

                    <InputWrapper label={"Address" + ":"} important>
                      <textarea
                        name="address"
                        onChange={(value) => change(value)}
                        value={SelectedUser && SelectedUser.address}
                        placeholder="Address"
                      />
                    </InputWrapper>
                    <InputWrapper label={"Notes" + ":"} >
                      <textarea
                        name="notes"
                        onChange={(value) => change(value)}
                        value={SelectedUser && SelectedUser.notes}
                        placeholder="notes"
                      />
                    </InputWrapper>


                  </div>
                </div>

                <LoadingButton
                  className={"updateBtn updateUserBtn"}
                  onClick={() => {
                    saveUser(SelectedUser);
                  }}
                  text={t("account.Update")}
                  icon={"/assets/icons/icon-basic-refresh.svg"} /> </div>)}

              {TabActive === 'licence' && (<div className={"col-8 "}>
                <div className="profileSideColTitle">
                  Licence Details
                </div>
                <div className={"d-flex"}>
                  <div className="col-6 ">

                    <InputWrapper label={"Licence Number" + ":"} important>
                      <input
                        name="license_number"
                        onChange={(value) => changeLicence(value)}
                        value={Licence && Licence.license_number}
                        placeholder="Licence Number"
                      />
                    </InputWrapper>
                    <div className="InputWrapperRelative">

                      <InputWrapper label={"License Expiry" + ":"} important>
                        <input
                          name="license_expiry_date"
                          onChange={(value) => changeLicence(value)}
                          value={Licence && Licence.license_expiry_date}
                          placeholder="Licence Expiry"
                        />


                      </InputWrapper>


                      <div className="datePickerProfile ">
                        <DateTimePicker
                          disableClock={true}
                          format={"y-MM-dd"}
                          onChange={(date: any) => {
                            setLicence({
                              ...Licence,
                              'license_expiry_date': moment(date).format("YYYY-MM-DD"),
                            });
                          }} value={Licence?.license_expiry_date ? new Date(Licence.license_expiry_date) : new Date()} />

                      </div>

                    </div>
                    <InputWrapper label={"License Check Code" + ":"} important>
                      <input
                        name="license_check_code"
                        onChange={(value) => changeLicence(value)}
                        value={Licence && Licence.license_check_code}
                        placeholder="Licence Check Code"
                      />
                    </InputWrapper>

                    <InputWrapper label={"Verification Status" + ":"} important>
                      <DropDown
                        selectedItem={Licence.license_verification}
                        textEntity={null}
                        items={[
                          "NOT_SUBMITTED",
                          "NOT_VERIFIED",
                          "VERIFIED",
                          "DISABLED",
                          "SUBMIT_AGAIN"
                        ]}
                        placeholder={Licence.license_verification}
                        onChange={(selected: any) => {
                          setLicence({
                            ...Licence,
                            license_verification: selected,
                          });
                        }}
                      />
                    </InputWrapper>



                  </div>
                  <div className="col-6 ">
                    <div className="userEditInfoSideCol">
                      <div className="cardItem">
                        <span>Back License Card</span>
                        {Licence.back_license_card ? (
                          <a href={Licence.back_license_card} target={"_blank"}>
                            <img
                              src={Licence.back_license_card}
                              className="uploadedImg"
                            />
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="cardItem">
                        <span>Front License Card</span>
                        {Licence.front_license_card ? (
                          <a href={Licence.front_license_card} target={"_blank"}>
                            <img
                              src={Licence.front_license_card}
                              className="uploadedImg"
                            />
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="cardItem">
                        <span>Selfie With License</span>
                        {Licence.selfie_with_license ? (
                          <a
                            href={Licence.selfie_with_license}
                            target={"_blank"}
                          >
                            <img
                              src={Licence.selfie_with_license}
                              className="uploadedImg"
                            />
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>


                  </div>
                </div>

                <LoadingButton
                  className={"updateBtn updateUserBtn"}
                  onClick={() => {
                    saveUser(SelectedUser);
                  }}
                  text={t("account.Update")}
                  icon={"/assets/icons/icon-basic-refresh.svg"} />  </div>)}

              {TabActive === 'wallet' && (<div className={"col-8 "}>
                <div className="profileSideColTitle">
                  Wallet
                </div>

                <div className={"d-flex"}>
                  <div className="col-6 ">
                    <div className="userEditInfoSideCol">
                      <div className="cardItem ">
                        <span></span>
                        <InputWrapper label={"Coupon:"} >
                        <input
                        name="coupon"
                        onChange={(value) => change(value)}
                        value={SelectedUser && SelectedUser.coupon}
                        placeholder="Coupon"
                      />
                      </InputWrapper>
                      </div>
                    </div>
                    <div className="userEditInfoSideCol">
                      <div className="cardItem PaymentMethod">
                        <span>Payment Method</span>
                        {Cards[0]?.card && Cards[0]?.card.brand === 'visa' && <VisaCard data={Cards[0]?.card} />}
                        {/* {Cards[0]?.card && Cards[0]?.card.brand === 'master' &&  <MasterCard data={Cards[0]?.card} />} */}
                      </div>
                      <div className="cardItem PaymentMethod">
                        <span>Billing History</span>
                        <div className="billingHistoryFa customScrollBar">
                          {BillingHistorys.map((item: any, key: any) => (
                            <a key={key} className="billingItem " target={'_blank'} href={item.stripe_invoice_pdf} >
                              <img src="/assets/icons/icon-basic-bill.svg" />
                              <div className="title">{Moment(item.book_for).format("ddd , D MMM YYYY hh:mm a")}</div>
                              <div className="billingDetails">
                                <span className="price">{item.stripe_invoice_cost_unit} {item.stripe_invoice_total_cost}</span>
                                <span className="invoice">invoice</span>
                              </div>
                            </a>)
                          )}

                        </div>
                      </div>

                    </div>


                  </div>
                  <div className="col-6 ">
                    <span>Billing Detailes</span>
                    <InputWrapper label={"Licence Number" + ":"} important>
                      <input
                        name="address"
                        onChange={(value) => changeStripe(value)}
                        value={Stripe && Stripe.address}
                        placeholder="Address"
                      />
                    </InputWrapper>

                    <InputWrapper label={"Additinoal" + ":"} important>
                      <input
                        name="additinoal"
                        onChange={(value) => changeStripe(value)}
                        value={Stripe && Stripe.additinoal}
                        placeholder="Additinoal"
                      />
                    </InputWrapper>
                    <InputWrapper label={"City" + ":"} important>
                      <input
                        name="city"
                        onChange={(value) => changeStripe(value)}
                        value={Stripe && Stripe.city}
                        placeholder="City"
                      />
                    </InputWrapper>

                    <InputWrapper label={"State" + ":"} important>
                      <input
                        name="state"
                        onChange={(value) => changeStripe(value)}
                        value={Stripe && Stripe.state}
                        placeholder="State"
                      />
                    </InputWrapper>

                    <InputWrapper label={"Postal Code" + ":"} important>
                      <input
                        name="zip"
                        onChange={(value) => changeStripe(value)}
                        value={Stripe && Stripe.zip}
                        placeholder="Postal Code"
                      />
                    </InputWrapper>


                    <InputWrapper label={"Country" + ":"} important>
                      <input
                        name="country"
                        onChange={(value) => changeStripe(value)}
                        value={Stripe && Stripe.country}
                        placeholder="Country"
                      />
                    </InputWrapper>



                  </div>

                </div>



                <LoadingButton
                  className={"updateBtn updateUserBtn"}
                  onClick={() => {
                    saveUser(SelectedUser);
                  }}
                  text={t("account.Update")}
                  icon={"/assets/icons/icon-basic-refresh.svg"} />  </div>)}

              {TabActive === 'permission' && (<div className={"col-8 "}>
                <div className="profileSideColTitle">
                  Groups & Permissions
                </div>
                <div className={"d-flex"}>
                  <div className="col-6 ">
                    <div className="userEditTitle">Permissions</div>
                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_active'} onChange={(value) => changeCheckBox(value)} checked={SelectedUser && SelectedUser.is_active} />
                        <div className="checkboxTitle">Active</div>
                      </div>
                      <div className="chechboxDesc">Unselect this to deactivate a user and instead of deleting accounts.</div>
                    </div>

                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_superuser'} onChange={(value) => changeCheckBox(value)} checked={SelectedUser && SelectedUser.is_superuser} />
                        <div className="checkboxTitle">is Super User</div>
                      </div>
                      <div className="chechboxDesc">Gives user all permissions without explicitly assingning them. </div>
                    </div>

                  </div>
                  <div className="col-6 ">
                    <div className="userEditTitle">Groups</div>
                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_driver'} onChange={(value) => changeCheckBox(value)} checked={SelectedUser && SelectedUser.is_driver} />
                        <div className="checkboxTitle">is Driver</div>
                      </div>
                      <div className="chechboxDesc">Designates whether this user can book cars.</div>
                    </div>


                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_dispatcher_driver'} onChange={(value) => changeCheckBox(value)} checked={SelectedUser && SelectedUser.is_dispatcher_driver} />
                        <div className="checkboxTitle">is Teleop driver</div>
                      </div>
                      <div className="chechboxDesc">Gives user permission to dispatch vehicles remotely.</div>
                    </div>


                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_safety_driver'} onChange={(value) => changeCheckBox(value)} checked={SelectedUser && SelectedUser.is_safety_driver} />
                        <div className="checkboxTitle">is Safety Driver</div>
                      </div>
                      <div className="chechboxDesc">Gives user permission to safety driver features in the app.</div>
                    </div>
                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_staff'} onChange={(value) => changeCheckBox(value)} checked={SelectedUser && SelectedUser.is_staff} />
                        <div className="checkboxTitle">is Staff</div>
                      </div>
                      <div className="chechboxDesc">gives user permission to log into the web panel.</div>
                    </div>

                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_tester'} onChange={(value) => changeCheckBox(value)} checked={SelectedUser && SelectedUser.is_tester} />
                        <div className="checkboxTitle">is Test User</div>
                      </div>
                      <div className="chechboxDesc">User stats are not included statislics.</div>
                    </div>

                  </div>

                </div>

                <LoadingButton
                  className={"updateBtn updateUserBtn"}
                  onClick={() => {
                    saveUser(SelectedUser);
                  }}
                  text={t("account.Update")}
                  icon={"/assets/icons/icon-basic-refresh.svg"} />  </div>)}



            </div>

          </div>
        </div>
      </OutsideClickHandler>
      {/* <div className="editViewFa">
        <div className="editViewPopup">
          <div className={"ErrorMassage"}>{Error}</div>

          <div
            className="editViewClose"
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/icons/closeCircle.svg" />
          </div>
          <div className="userInfoSide">
            <div className="cardItem">
              <span>Name</span>
              {SelectedUser.user &&
                SelectedUser.user.first_name +
                " " +
                SelectedUser.user.last_name}
            </div>
            <div className="cardItem">
              <span>Email</span>
              {SelectedUser.user && SelectedUser.user.email}
            </div>

            <div className="cardItem">
              <span>Phone</span>
              {SelectedUser.user && SelectedUser.user.mobile_number}
            </div>
          </div>
          <div className="userEditInfoSide">
            <div className="userEditInfoSideCol">
              <div className="cardItem">
                <span>Status</span>

                <DropDown
                  withInput={true}
                  selectedItem={SelectedUser.license_verification}
                  textEntity={null}
                  preText={""}
                  items={[
                    "NOT_SUBMITTED",
                    "NOT_VERIFIED",
                    "VERIFIED",
                    "DISABLED",
                    "SUBMIT_AGAIN"
                  ]}
                  placeholder={SelectedUser.license_verification}
                  pngSource={"/assets/icons/Profile2x.png"}
                  onChange={(selected: any) => {
                    setSelectedUser({
                      ...SelectedUser,
                      license_verification: selected,
                    });
                  }}
                />
              </div>
              <div className="cardItem">
                <span>License number</span>
                <InputWithIcon
                  value={SelectedUser.license_number}
                  name={"license_number"}
                  placeholder="License number"
                  pngSource={"/assets/icons/icon-basic-credit-card.svg"}
                  onChange={(license_number: string) => {
                    setSelectedUser({
                      ...SelectedUser,
                      license_number: license_number,
                    });
                  }}
                />
              </div>
              <div className="cardItem">
                <span>License check code</span>
                <InputWithIcon
                  value={SelectedUser.license_check_code}
                  name={"license_check_code"}
                  placeholder="License check code"
                  pngSource={"/assets/icons/icon-basic-credit-card.svg"}
                  onChange={(license_check_code: string) => {
                    setSelectedUser({
                      ...SelectedUser,
                      license_check_code: license_check_code,
                    });
                  }}
                />
              </div>
              <div className="cardItem">
                <span>Expiry date</span>

                <InputWithIcon
                  type={"date"}
                  value={SelectedUser.license_expiry_date}
                  name={"license_expiry_date"}
                  placeholder="License number"
                  pngSource={"/assets/icons/Calendar.svg"}
                  onChange={(license_expiry_date: string) => {
                    setSelectedUser({
                      ...SelectedUser,
                      license_expiry_date: license_expiry_date,
                    });
                  }}
                />
              </div>

              <div className="cardItem">
                <span>Status</span>


                <CheckboxWithTitle
                  value={SelectedUser.user && SelectedUser.user.is_active}
                  text={"Active"}
                  onChange={(terms: boolean) => {
                    let user = { ...SelectedUser.user }
                    user.is_active = terms
                    setSelectedUser({
                      ...SelectedUser,
                      user: user,
                    });
                  }}
                />


                <CheckboxWithTitle
                  value={SelectedUser.user && SelectedUser.user.is_staff}
                  text={"Staff status"}
                  onChange={(terms: boolean) => {
                    let user = { ...SelectedUser.user }
                    user.is_staff = terms
                    setSelectedUser({
                      ...SelectedUser,
                      user: user,
                    });
                  }}
                />
                <CheckboxWithTitle
                  value={SelectedUser.user && SelectedUser.user.is_dispatcher_driver}
                  text={"Dispatch Driver"}
                  onChange={(terms: boolean) => {
                    let user = { ...SelectedUser.user }
                    user.is_dispatcher_driver = terms
                    setSelectedUser({
                      ...SelectedUser,
                      user: user,
                    });
                  }}
                />
                <CheckboxWithTitle
                  value={SelectedUser.user && SelectedUser.user.is_safety_driver}
                  text={"Safety Driver"}
                  onChange={(terms: boolean) => {
                    let user = { ...SelectedUser.user }
                    user.is_safety_driver = terms
                    setSelectedUser({
                      ...SelectedUser,
                      user: user,
                    });
                  }}
                />
              </div>
            </div>


          </div>
          <LoadingButton
            className={"updateBtn updateBtnAb"}
            loading={Loading}
            onClick={() => {
              setLoading(true);
              saveUser(SelectedUser);
            }}
            text={t("account.Update")}
            icon={"/assets/icons/icon-basic-refresh.svg"}
          />
        </div>
      </div> */}

      {Loading && (
        <LoadingView />
      )}
    </div>
  );
}

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import Table from "../../utilities/components/Table/Table";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSound from "use-sound";
import { bookings, task, bookingsCounter } from "../../redux/actions/table.action";
import EditView from "./BookingEdit";
import { LoadingView } from "./../../utilities/components/Loading";


const DefaultParam = {
  offset: 0,
  limit: 10,
  search: false,
  ordering: false,
  according: false,
};
export default function Tasks() {
  const navigate = useNavigate();
  const alarm = `${process.env.PUBLIC_URL}/ringtone.mp3`;
  const [play, { stop }] = useSound(alarm);
  //let audio = new Audio(alarm)
  const [searchParams] = useSearchParams();
  const [Status, setStatus] = React.useState(null);


  const [EditViewShow, setEditViewShow] = React.useState<boolean>(false);
  const [SelectedData, setSelectedData] = React.useState<any>({});

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [Bookings, setBookings] = React.useState<Record<string, any>>([]);

  const [ALL, setALL] = React.useState(0);
  const [OPEN, setOPEN] = React.useState(0);
  const [ACTIVE, setACTIVE] = React.useState(0);
  const [COMPLETE, setCOMPLETE] = React.useState(0);
  const [CANCEL, setCANCEL] = React.useState(0);


  const Filters: any = {
    title: "FILTERS",
    icon: "/assets/icons/Todo.svg",
    items: [
      {
        title: "All",
        icon: "/assets/icons/Todo.svg",
        name: "All",
        prop: "",
      },
      {
        title: "menu.open",
        icon: "/assets/icons/Task@2x.png",
        name: "Open",
        prop: "OPEN",
      },
      {
        title: "menu.ACTIVE",
        icon: "/assets/icons/rad.png",
        name: "Active",
        prop: "ACTIVE",
      },
      {
        title: "menu.completed",
        icon: "/assets/icons/check.png",
        name: "Completed",
        prop: "COMPLETE",
      },
      {
        title: "menu.canceled",
        icon: "/assets/icons/cancel.png",
        name: "Canceled",
        prop: "CANCEL",
      },
    ],
  };

  const [Task, setTask] = React.useState<Record<string, any>>({});
  const [ShowMap, setShowMap] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  function request(p?: any) {
    let props: any = p ? p : {};
    let status = searchParams.get('filter');
    if (status) {
      props.status = status
    }
    dispatch(bookings(props)).then((response: any) => {
      if (response.payload && response.payload.results) {
        setBookings(response.payload);
        setLoading(false);
      } else {
        toast.error(response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }




  const counterRef: any = React.useRef()

  React.useEffect(() => {
    counterRef.current = count;
  })

  React.useEffect(() => {
    setInterval(() => {
      setCount(counterRef.current + 1);
    }, 60000);
  }, []);


  React.useEffect(() => {
    const s: any = searchParams.get('filter') ? searchParams.get('filter') : null
    setStatus(s)

  }, [searchParams.get('filter')])

  function menuCounter() {
    dispatch(bookingsCounter()).then((response: any) => {
      if (response && response.payload && !response.payload.error) {
        response.payload.map((obj: any, key: any) => {
          if (obj.status === "OPEN") {
            setOPEN(obj.count)
          }
          if (obj.status === "ACTIVE") {
            setACTIVE(obj.count)
          }
          if (obj.status === "COMPLETE") {
            setCOMPLETE(obj.count)
          }
          if (obj.status === "CANCEL") {
            setCANCEL(obj.count)
          }
        })
      } else {
        setLoading(false)
        toast.error(response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }


  React.useEffect(() => {
    setLoading(true);
    menuCounter();
    request();
  }, [Status])

  function childGenrator(item: any) {
    const links = (
      <li
        onClick={() => {
          navigate({
            pathname: '/panel/Bookings/',
            search: `?${createSearchParams({ filter: item.prop })}`,
          });

        }}
        className={Status === item.prop ? "filterItem filterItemActive d-flex align-items-center" + " filterItem-" + item.prop : "filterItem d-flex align-items-center" + " filterItem-" + item.prop}
      >
        <img className={"menuChildIcon"} src={item.icon} />
        <small
          style={{
            // display: !MenuIsOpen ? "none" : "unset",
            color: !item.value ? "#8697A8" : "#44566c",
          }}
        >
          {t(item.title)}
        </small>
        {item.prop !== "" ?
          <span className={item.prop}>
            {item.prop === "OPEN" && OPEN}
            {item.prop === "ACTIVE" && ACTIVE}
            {item.prop === "COMPLETE" && COMPLETE}
            {item.prop === "CANCEL" && CANCEL}
          </span>
          :  <span className={"allCount"}>{OPEN + ACTIVE + COMPLETE + OPEN + CANCEL}</span>}
          </li>
    );

    return (
      <>
        <div className="links">{links}</div>
      </>
    );
  }
  function selectBook(row: any) {
    dispatch(task(row.id)).then((response: any) => {
      if (response.payload && !response.payload.error) {
        setTask(response.payload);
        if (response.payload?.booking && response.payload?.booking?.vehicle) {
          setTask(response.payload);
          setShowMap(true)
        }
      } else {
        setLoading(false);
        toast.error(response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  return (
    <div className="main-container d-flex">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {Loading && (
        <LoadingView />
      )}
      <div className="ulChildView menuFilterView">
        <div className="filtersView">

          {Filters.items.map((item: any) => childGenrator(item))}
        </div>
      </div>

      {EditViewShow && <EditView Data={SelectedData} Close={() => {
        setEditViewShow(false)
      }}
        UpdateTable={(action: any) => {
          action && request(DefaultParam)
        }}
      />}
      <Table
        colsOrder={
          [
            "user",
            "vehicle",
            "book_for",
            "status",
            "pricing_plan",
            "stripe_invoice_pdf",
            "stripe_invoice_total_cost",
            "trip",
            "type",
            "user_location",
            "user_address",
            "start_location",
            "start_address",
            "end_location",
            "end_address",
            "expire_at",
            "drop_off_datetime",
            "created_on",
            "updated_on",
            "stripe_invoice_cost_unit",
            "delivery_instruction",
            "delivery",
        ]
        }
        rowSelect={(row: any) => {
          setSelectedData(row)
          setEditViewShow(true)
        }}
        getData={(props: any) => {
          request(props);
        }}
        tableData={Bookings}
        tableType={"Tasks_filter"}
        title={"Bookings" + " - " + (Status ? Status : "All")}

      />
    </div>
  );
}

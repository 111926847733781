import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import ReduxDevTools from "./pages/ReduxDevTools/ReduxDevTools";
import App from "./App";
import "./styles/css/CoreCss.css";
import "./utilities/i18n";

//  <React.StrictMode> Commented
// ReactDOM.render(
//   <Provider store={store}>
    
//     {/*<ReduxDevTools />*/}
//   </Provider>,
//   document.getElementById("root"),
// );
ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
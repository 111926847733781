import * as React from "react";
import { useImmer } from "use-immer";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  InputWithIcon,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import {
  VerifyEmail,
  VerifyEmailResend,
} from "../../redux/actions/auth.action";
import { EmailValidator } from "../../utilities/functions/authValidator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useEffect, useState } from "react";
import queryString from 'query-string';

export default function VerifyEmailCo() {
  const prop: any = useParams();
  const dispatch = useDispatch();
  const loc: any = useLocation();
  const [t] = useTranslation();
  const [Error, setError] = useState(true);
  const [Done, setDone] = useState(false);
  const [Email, setEmail] = useState("");
  const [state, setState] = useImmer({
    permission: true,
    valid: "",
  });
  const validate = (data: any) => {
    setState((state) => {
      state.valid = "";
    });
    if (EmailValidator(data)) {
      return true;
    } else {
      setState((state) => {
        state.valid = t("auth.Verification_email");
      });
      return false;
    }
  };
  useEffect(() => {
    const query: any = queryString.parse(loc && loc.search);

    const data = { key: query.token };

    dispatch(VerifyEmail(data)).then((response: any) => {
      if (!response?.payload?.error) {
        setDone(true)
      } else {
        setState((state) => {
          state.valid = response?.payload?.errorMassage;
        });
      }
    });
  }, []);

  function resend() {
    const data = { email: Email };
    if (validate(data)) {
      dispatch(VerifyEmailResend(data)).then((response: any) => {
        if (!response.payload.error) {
          setDone(true)
        } else {
          setState((state) => {
            state.valid = response?.payload?.errorMassage;
          });
        }
      });
    }
  }

  return (
    <div className="main-container main-auth-container d-flex">
      <div className="login-form-section full-section d-flex justify-content-center align-items-center fadeIn">
        <div className="login-form-inputs-container d-flex justify-content-center flex-column">
          <div className="login-form-inputs-header">
            <img
              className="login-form-inputs-header-img"
              src={"/assets/logo/logo.png"}
              alt="fetch-logo"
            />
          </div>
          <small className="signInText  text-center">
            {/* {t("auth.token_expired")} */}
          </small>
          {Done ? <div>
            <div className="alert alert-success" role="alert">
              Thanks for confirming your email address. Now go back to the app to continue the journey
            </div>
            <div className="login-button">
              <a href="fetch://" target="_blanck" className="LoadingButton undefined">Back To App</a>
            </div>
          </div> :
            <>
              <InputWithIcon
                value={Email}
                placeholder={t("home.email")}
                type="email"
                pngSource={"/assets/icons/Profile2x.png"}
                onChange={(email: string) => setEmail(email)}
              />
              {state.valid && (
                <div style={{ width: "200px", display: "block" }}>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {state.valid}
                  </span>
                </div>
              )}
              <div className="login-button">
                <LoadingButton
                  onClick={() => resend()}
                  text={t("auth.Resend_Verification_Email")}
                />
              </div>
            </>}
        </div>
      </div>
    </div>
  );
}

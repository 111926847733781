import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
export const  Shape = () => {
  const particlesInit = useCallback(async engine => {
      await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
      await console.log(container);
  }, []);

  return (
      <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
              background: {
                  color: {
                      value: "#fff",
                  },
              },
              fpsLimit: 120,
              interactivity: {
                  events: {
                      // onClick: {
                      //     enable: true,
                      //     mode: "push",
                      // },
                      onHover: {
                          enable: true,
                          mode: "grab",
                      },
                      resize: true,
                  },
                  modes: {
                      push: {
                          quantity: 40,
                      },
                      repulse: {
                          distance: 200,
                          duration: 1,
                      },
                  },
              },
              particles: {
                  color: {
                      value: "#383596",                      
                        },
                  links: {
                      color: "#191664",
                      distance: 150,
                      enable: true,
                      opacity: 0.25,
                      width: 1,
                  },
                  collisions: {
                      enable: true,
                  },
                  move: {
                      enable: true,
                      outModes: {
                          default: "bounce",
                      },
                      random: true,
                      speed: 3,
                      straight: false,
                  },
                  number: {
                      density: {
                          enable: true,
                          area: 600,
                      },
                      value: 70,
                  },
                  opacity: {
                      value: 0.75,
                  },
                  shape: {
                      type: "circle",
                  },
                  size: {
                      value: { min: 1, max: 2 },
                  },
              },
              detectRetina: true,
          }}
      />
  );
};

import { rest, errorHandler, propGenrator } from "../apiGateway";
import ky from "ky";






export const UpdateSettings = async (data: any) => {
  try {
    return await rest.patch("settings/", { json: data }).json();
  } catch (err) {
    return errorHandler(err);
  }
};
export const getSettings = async () => {
  try {
    return await rest.get("settings/").json();
  } catch (err) {
    return errorHandler(err);
  }
};
export const getCoupons = async () => {
  try {
    return await rest.get("coupons/").json();
  } catch (err) {
    return errorHandler(err);
  }
};

export const couponEdit = async (data: any) => {
  try {
    return await rest.patch("coupons/", { json: data }).json();
  } catch (err) {
    return errorHandler(err);
  }
};
export const couponsStripePredefined = async () => {
  try {
    return await rest.get("coupons/stripe-predefined").json();
  } catch (err) {
    return errorHandler(err);
  }
};

export const getVehicles = async () => {
  try {
    return await rest.get("vehicles/").json();
  } catch (err) {
    return errorHandler(err);
  }
};

export const getTrips = async () => {
  try {
    return await rest.get("trips/").json();
  } catch (err) {
    return errorHandler(err);
  }
};






export const AllTasks = async (Param?: any) => {
  try {
    const p: any = await propGenrator(Param)

    return await rest.get("dispatching/tasks/", { searchParams: p }).json();
  } catch (err) {
    return errorHandler(err);
  }
};

export const returns = async (Param: any) => {
  try {
    const p: any = await propGenrator(Param)
    return await rest.get("dispatching/return-to-base/tasks/", { searchParams: p }).json();
  } catch (err) {
    return errorHandler(err);
  }
};

export const getReturn = async (id: any) => {
  try {
    return await rest.get("dispatching/return-to-base/tasks/" + id + "/").json();
  } catch (err) {
    return errorHandler(err);
  }

};

export const returnCount = async () => {
  try {
    return await rest.get("dispatching/return-to-base/tasks/count/").json();
  } catch (err) {
    return errorHandler(err);
  }

};



export const getTasks = async (Param: any) => {
  try {
    const p: any = await propGenrator(Param)
    return await rest.get("dispatching/delivery/tasks/", { searchParams: p }).json();
  } catch (err) {
    return errorHandler(err);
  }

};


export const tasksAllCounter = async () => {
  try {
    return await rest.get("dispatching/tasks/count/").json();
  } catch (err) {
    return errorHandler(err);
  }

};
export const tasksCounter = async () => {
  try {
    return await rest.get("dispatching/delivery/tasks/count/").json();
  } catch (err) {
    return errorHandler(err);
  }

};


export const bookingsCounter = async () => {
  try {
    return await rest.get("company/vehicles/booking/count/").json();
  } catch (err) {
    return errorHandler(err);
  }

};



export const getTask = async (id: any) => {
  try {
    return await rest.get("dispatching/delivery/tasks/"+id+"/").json();
  } catch (err) {
    return errorHandler(err);
  }

};

export const taskReturn = async (id: any) => {
  try {
    return await rest.get("dispatching/return-to-base/tasks/"+id+"/").json();
  } catch (err) {
    return errorHandler(err);
  }

};





export const Damages = async () => {
  try {
    return await rest.get("damages-company-vehicles/").json();
  } catch (err) {
    return errorHandler(err);
  }

};


// export const damageEdit = async (data: any) => {
//   try {
//     return await rest.patch("damages-company-vehicles/" + data.id + "/", { json: data }).json();
//   } catch (err) {
//     return errorHandler(err);
//   }
// };

export const damageEdit = async ({
  data,
  token,
}: {
  data: any;
  token?: any;
}) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key]) {
      if (key === "uploaded_images") {
        data[key].map((img: any) => (
          formData.append("uploaded_images", img)
        ))
      } else {
        formData.append(key, data[key]);
      }
    }
  }
  return ky.patch(`${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` + "damages-company-vehicles/" + data.id + "/", {
    body: formData,
    headers: {
      Authorization: token
        ? "Bearer " + token
        : `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .json();
};

export const damageCreate = async ({
  data,
  token,
}: {
  data: any;
  token?: any;
}) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key]) {
      if (key === "uploaded_images") {
        data[key].map((img: any) => (
          formData.append("uploaded_images", img)
        ))
      } else {
        formData.append(key, data[key]);
      }
    }
  }
  return ky.post(`${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` + "damages-company-vehicles/", {
    body: formData,
    headers: {
      Authorization: token
        ? "Bearer " + token
        : `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .json();
};





export const valetCounter = async () => {
  try {

    return await rest.get("dispatching/valet-parking/tasks/count/").json();
  } catch (err) {
    return errorHandler(err);
  }

};
export const getValets = async (Param: any) => {
  try {
    const p: any = await propGenrator(Param)

    return await rest.get("dispatching/valet-parking/tasks/", { searchParams: p }).json();
  } catch (err) {
    return errorHandler(err);
  }

};
export const getValet = async (id: any) => {
  try {
    return await rest.get("dispatching/valet-parking/tasks/" + id + "/").json();
  } catch (err) {
    return errorHandler(err);
  }

};





export const getVehicle = async (id: any) => {
  try {
    return await rest.get("vehicles/" + id + "/").json();
  } catch (err) {
    return errorHandler(err);
  }

};



export const startReturnDispatching = async (data: any) => {
  try {
    return await rest.patch("dispatching/return-to-base/tasks/" + data.id + "/", { json: data.json }).json();
  } catch (err) {
    return errorHandler(err);
  }
};
export const startValetDispatching = async (data: any) => {
  try {
    return await rest.patch("dispatching/valet-parking/tasks/" + data.id + "/", { json: data.json }).json();
  } catch (err) {
    return errorHandler(err);
  }
};


export const startDispatching = async (data: any) => {
  try {
    return await rest.patch("dispatching/delivery/tasks/" + data.id + "/", { json: data.json }).json();
  } catch (err) {
    return errorHandler(err);
  }
};



export const companyVehicles = (data: any) => {
  const search = data.search ? "&search=" + data.search : "";
  const according = data.according === "-" ? data.according : "";
  const order = data.ordering ? "&ordering=" + according + data.ordering : "";

  return rest
    .get(
      "company/vehicles/" +
      "?limit=" +
      data.limit +
      "&offset=" +
      data.offset +
      search +
      order,
    )
    .json<Record<string, any>>();
};

export const companyVehicleEdit = (json: any) => {
  return rest
    .patch("company/vehicles/" + json.id + "/", { json })
    .json();
};
export const companyVehicleDelete = (json: any) => {
  return rest
    .delete("company/vehicles/" + json.id + "/", { json })
    .json();
};

export const companyVehicleCreate = (json: any) => {
  return rest.post("company/vehicles/", { json }).json();
};

export const companyVehicleGet = (id: any) => {
  return rest.get("company/vehicles/" + id).json();
};

export const pricing_plan = () => {
  return rest.get("pricing-plan/").json();
};



export const TelematicsDevices = async (data: any) => {
  const props: any = await propGenrator(data)
  return rest
    .get(
      "telematics/devices/", { searchParams: props })
    .json<Record<string, any>>();
};

export const TelematicsDevicesEdit = (json: any) => {
  return rest
    .patch("telematics/devices/" + json.id + "/", { json })
    .json();
};
export const TelematicsDevicesDelete = (json: any) => {
  return rest
    .delete("telematics/devices/" + json.id + "/", { json })
    .json();
};
export const TelematicsDevicesCreate = (json: any) => {
  return rest.post("telematics/devices/", { json }).json();
};
export const bookings = async (data: any) => {
  let props: any = await propGenrator(data);
  let statusInUrl = '';
  if (Array.isArray(props.status)) {
    statusInUrl = props.status
    props = null; 
  }
  return rest
    .get("company/vehicles/booking/" + statusInUrl, { searchParams: props })
    .json<Record<string, any>>();
};
export const valetTasks = async (data: any) => {
  const props: any = await propGenrator(data)
  return rest
    .get("dispatching/valet-parking/tasks/", { searchParams: props })
    .json<Record<string, any>>();
};




export const driversCounter = async () => {
  return rest.get("drivers/count/")
    .json<Record<string, any>>();
};
export const drivers = async (data: any) => {
  const props: any = await propGenrator(data)
  return rest.get("drivers/", { searchParams: props })
    .json<Record<string, any>>();
};
export const users = async (data: any) => {
  const props: any = await propGenrator(data)
  return rest.get("users/", { searchParams: props })
    .json<Record<string, any>>();
};
export const driversEdit = (json: any) => {
  return rest.patch("drivers/" + json.id + "/", { json }).json();
};
export const bookingEdit = (json: any) => {
  return rest.patch("company/vehicles/booking/" + json.id + "/", { json }).json();
};
export const driversCreate = (json: any) => {
  return rest.post("driver-profile/", { json }).json();
};


export const sendEmail = (json: any) => {
  return rest.post("send-email/text", { json }).json();
};
export const sendSms = (json: any) => {
  return rest.post("send-sms", { json }).json();
};
export const sendPushNotification = (json: any) => {
  return rest.post("send-push-notification", { json }).json();
};


export const driversDelete = (json: any) => {
  return rest.delete("company/profiles/" + json.id + "/", { json }).json();
};

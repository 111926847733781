import { createAsyncThunk } from "@reduxjs/toolkit";
import * as tableService from "../services/table.service";
import { errorHandler } from "../apiGateway";

export const UpdateSettings = createAsyncThunk(
  "table/UpdateSettings",
  async (data:any) => {
    return await tableService.UpdateSettings(data);
  },
);


export const settings = createAsyncThunk(
  "table/settings",
  async () => {
    return await tableService.getSettings();
  },
);


export const couponsStripePredefined = createAsyncThunk(
  "table/stripe-predefined",
  async () => {
    return await tableService.couponsStripePredefined();
  },
);
export const coupons = createAsyncThunk(
  "table/getCoupons",
  async () => {
    return await tableService.getCoupons();
  },
);

export const couponEdit = createAsyncThunk(
  "table/tasks",
  async (json: any) => {
    return await tableService.couponEdit(json);
  },
);
export const vehicles = createAsyncThunk(
  "table/vehicles",
  async () => {
    return await tableService.getVehicles();
  },
);

export const vehicle = createAsyncThunk(
  "table/vehicle",
  async (id: any) => {
    return await tableService.getVehicle(id);
  },
);
export const trips = createAsyncThunk(
  "table/trips",
  async () => {
    return await tableService.getTrips();
  },
);

export const tasks = createAsyncThunk(
  "table/tasks",
  async (status: any) => {
    return await tableService.getTasks(status);
  },
);

export const tasksAllCounter = createAsyncThunk(
  "table/tasksAllCounter",
  async () => {
    return await tableService.tasksAllCounter();
  },
);
export const tasksCounter = createAsyncThunk(
  "table/tasksCounter",
  async () => {
    return await tableService.tasksCounter();
  },
);
export const bookingsCounter = createAsyncThunk(
  "table/bookingsCounter",
  async () => {
    return await tableService.bookingsCounter();
  },
);

export const taskReturn = createAsyncThunk(
  "table/task",
  async (id: any) => {
    return await tableService.taskReturn(id);
  },
);
export const task = createAsyncThunk(
  "table/task",
  async (id: any) => {
    return await tableService.getTask(id);
  },
);



export const AllTasks = createAsyncThunk(
  "table/AllTasks",
  async (status: any) => {
    return await tableService.AllTasks(status);
  },
);

export const returns = createAsyncThunk(
  "table/tasks",
  async (status: any) => {
    return await tableService.returns(status);
  },
);

export const getReturn = createAsyncThunk(
  "table/task",
  async (id: any) => {
    return await tableService.getReturn(id);
  },
);
export const returnCount = createAsyncThunk(
  "table/returnCount",
  async () => {
    return await tableService.returnCount();
  },
);






export const valetCounter = createAsyncThunk(
  "table/valetCounter",
  async () => {
    return await tableService.valetCounter();
  },
);
export const valets = createAsyncThunk(
  "table/valets",
  async (status: any) => {
    return await tableService.getValets(status);
  },
);
export const valet = createAsyncThunk(
  "table/valet",
  async (id: any) => {
    return await tableService.getValet(id);
  },
);


export const startReturnDispatching = createAsyncThunk(
  "table/startDispatching",
  async ({
    id,
    json,
  }: {
    id: string;
    json: any;
  }) => {
    return await tableService.startReturnDispatching({id,json});
  },
 
);

export const startValetDispatching = createAsyncThunk(
  "table/startDispatching",
  async ({
    id,
    json,
  }: {
    id: string;
    json: any;
  }) => {
    return await tableService.startValetDispatching({id,json});
  },
 
);
export const startDispatching = createAsyncThunk(
  "table/startDispatching",
  async ({
    id,
    json,
  }: {
    id: string;
    json: any;
  }) => {
    return await tableService.startDispatching({id,json});
  },
 
);

export const driversCounter = createAsyncThunk(
  "driversCounter",
  async () => {
    try {
      return await tableService.driversCounter();
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const drivers = createAsyncThunk(
  "drivers",
  async (data: any) => {
    try {
      return await tableService.drivers(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const users = createAsyncThunk(
  "drivers",
  async (data: any) => {
    try {
      return await tableService.users(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);


export const sendSms = createAsyncThunk(
  "drivers",
  async (data: any) => {
    try {
      return await tableService.sendSms(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);


export const sendPushNotification = createAsyncThunk(
  "sendPushNotification",
  async (data: any) => {
    try {
      return await tableService.sendPushNotification(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const sendEmail = createAsyncThunk(
  "sendEmail",
  async (data: any) => {
    try {
      return await tableService.sendEmail(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);





export const pricing_plan = createAsyncThunk(
  "pricing_plan",
  async () => {
    try {
      return await tableService.pricing_plan();
    } catch (err) {
      return errorHandler(err);
    }
  },
);




export const driverDelete = createAsyncThunk(
  "company/profiles/",
  async (data: any) => {
    try {
      return await tableService.driversDelete(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const driverEdit = createAsyncThunk(
  "company/profiles/",
  async (data: any) => {
    try {
      return await tableService.driversEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const bookings = createAsyncThunk(
  "table/bookings",
  async (data: any) => {
    try {
      return await tableService.bookings(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const bookingEdit = createAsyncThunk(
  "table/booking/edit",
  async (data: any) => {
    try {
      return await tableService.bookingEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const valetTasks = createAsyncThunk(
  "table/valetTasks",
  async (data: any) => {
    try {
      return await tableService.valetTasks(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const damages = createAsyncThunk(
  "table/Damages",
  async () => {
    try {
      return await tableService.Damages();
    } catch (err) {
      return errorHandler(err);
    }
  },
);


export const damageEdit = createAsyncThunk(
  "table/damageEdit/",
  async ({ data, token }: { data: any; token?: any }) => {
    try {
      return await tableService.damageEdit({ data, token });
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const damageCreate = createAsyncThunk(
  "table/damageCreate/",
  async ({ data, token }: { data: any; token?: any }) => {
    try {
      return await tableService.damageCreate({ data, token });
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const companyVehicles = createAsyncThunk(
  "table/vehicles",
  async (data: any) => {
    try {
      return await tableService.companyVehicles(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const companyVehicleCreate = createAsyncThunk(
  "table/company-vehicles/",
  async (data: any) => {
    try {
      return await tableService.companyVehicleCreate(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const companyVehicleGet = createAsyncThunk(
  "table/company-vehicles/get",
  async (id: any) => {
    try {
      return await tableService.companyVehicleGet(id);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const companyVehicleEdit = createAsyncThunk(
  "table/company-vehicles/",
  async (data: any) => {
    try {
      return await tableService.companyVehicleEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const companyVehicleDelete = createAsyncThunk(
  "table/company-vehicles/",
  async (data: any) => {
    try {
      return await tableService.companyVehicleDelete(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);




export const TelematicsDevice = createAsyncThunk(
  "table/TelematicsDevices",
  async (data: any) => {
    try {
      return await tableService.TelematicsDevices(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const TelematicsDevicesCreate = createAsyncThunk(
  "table/TelematicsDevicesCreate/",
  async (data: any) => {
    try {
      return await tableService.TelematicsDevicesCreate(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const TelematicsDevicesEdit = createAsyncThunk(
  "table/TelematicsDevicesEdit/",
  async (data: any) => {
    try {
      return await tableService.TelematicsDevicesEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const TelematicsDevicesDelete = createAsyncThunk(
  "table/TelematicsDevicesDelete/",
  async (data: any) => {
    try {
      return await tableService.TelematicsDevicesDelete(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

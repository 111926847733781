import ky from "ky";
import jwt from "jwt-decode"
import useAuth from "../context/hooks/useAuth";
import { changeToken } from "./actions/auth.action";

let token = localStorage.getItem("token");
let newToken: any = null;
let refresh_token = localStorage.getItem("refresh_token");
// import { useSelector } from "react-redux";
// import { useDispatch } from "../redux/store";
// const dispatch = useDispatch();
// const token_redux = useSelector((state: RootState) => state.auth.token);
// const refresh_token_recus = useSelector((state: RootState) => state.auth.token);

function extendHeader() {
  const t = newToken ? newToken : token;
  // console.log("-----------------------------------------------------------------------------------")
  // console.log("token",token)
  // console.log("newToken",newToken)
  // console.log("t",t)
  // console.log("-----------------------------------------------------------------------------------")

  const header: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
    //Language : localStorage.getItem("lang"),
  };
  if (token) {
    header["Authorization"] = "Bearer " + token;
  }
  return header;
}


const LogOut = async () => {
  await localStorage.removeItem("refresh_token");
  await localStorage.removeItem("token");
  await localStorage.setItem("prev_url", window.location.href);
  const route = window.location.pathname;

  if (window.location.href !== "http://localhost:3000/" && route !== "/verify/email/" && route !== "/forgot-password" && route !== "/sign-up" && route !== "/login" && !route.includes("password-reset/confirm/")) {
    window.location.replace("/login");
  }
}

export function actionPermission(user: any, page: any) {
  // ((user?.is_safety_driver) || (!user?.is_dispatcher_driver && !user?.is_staff)))
  if (user?.is_staff) {
    return true
  }
  if (user?.is_dispatcher_driver && page === 'task') {
    return true
  }
  if (user?.is_safety_driver && page === 'task') {
    return false
  }
  if ((user?.is_dispatcher_driver || user?.is_safety_driver) && page === 'vehicleEdit') {
    return false
  }
  return false

}
export async function errorHandler(err: any) {
  try {
    const res: any = await err.response.json();
    let errorMassage = "";
    const keys = Object.keys(res);
    keys.map((key, i) => {
      let message = "";
      const objData = res[key];
      const inputName = key.replaceAll("_", " ");
      if (Array.isArray(objData)) {
        objData.map(
          (massageText: any) => {
            let t = massageText;
            if (massageText.message) {
              t = massageText.message
            }
            (message += inputName + " : " + t + "  ")
          }
        );
      } else {

        message = inputName + " : " + objData + "  ";
      }
      errorMassage += message;
    });
    res.errorMassage = errorMassage.replaceAll(",", "  ");

    //res.errorMassage = errorMassage.slice(0, -2);
    res.error = true;
    if (err.response && err.response.status) {
      res.errorMassage += " CODE = " + err.response.status;
    } else {
      res.errorMassage += " CODE = " + " Null (Request Time out)"
    }

    return res;
  } catch (error: any) {
    const res: any = {};
    let statusCode = "CODE = ";
    if (err.response) {
      statusCode += err.response.status
    } else {
      statusCode += err
    }
    res.errorMassage = " " + statusCode;
    res.error = true;
    return res;
  }
}



export async function propGenrator(Param: any) {
  let res: any = {};
  try {

    if (Param.license_verification) {
      res.license_verification = Param.license_verification
    }
    if (Param.status) {
      if(Array.isArray(Param.status)){
        res.status = Param.status 
      }else{
        res.status = Param.status
      }
    }
    if (Param.search) {
      res.search = Param.search
    }

    if (Param.limit) {
      res.limit = Param.limit
    }

    if (Param.offset) {
      res.offset = Param.offset
    }
    if (Param.ordering) {
      let according = "";
      if (Param.according === "-") {
        according = Param.according
      }
      res.ordering = according + Param.ordering
    }
    if (res !== "?") { return res; } else { return '' }

  } catch (error) {

    return '';
  }
}


export const restWithOutHook = ky.create({
  prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
});



async function refreshToken() {
  return await ky
    .post(
      `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` +
      "auth/token/refresh/",
      {
        json: { refresh: refresh_token },
      },
    )
    .json()
    .then(async (res: any) => {
      await localStorage.setItem("refresh_token", res.refresh);
      await localStorage.setItem("token", res.access);
      newToken = res.access;
      //dispatch(changeToken(response));
      refresh_token = res.refresh;
      token = res.access;
      //window.location.reload();
      return token
    })
    .catch((err: any) => {
      LogOut();
    });
}

async function hook(response: any, request: any) {
  //console.log("response.status",response.status)
  if (response.status === 401) {
    // console.log("request",request)
    // console.log("response",response)

    const newToken = await refreshToken();
    request.headers.set("Authorization", `Bearer ${newToken}`);
    window.location.reload()

    return ky(request);
  }
}

export const auth = ky.create({
  prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
});
async function jwtChecker(request?: any) {
  // console.log("token",token)
  // console.log("newToken",newToken)
  // {
  //   Accept: "application/json",
  //   "Content-Type": "application/json",
  //   Authorization : "Bearer " + token
  //   //Language : localStorage.getItem("lang"),
  // }
  const t = newToken ? newToken : token;
  request.headers.set("Accept-Language", localStorage.getItem("lang"));
  request.headers.set("Accept", "application/json")
  request.headers.set("Content-Type", "application/json")
  t && request.headers.set("Authorization", "Bearer " + t);

  // if (token && token !== "undefined") {
  //   const now: any = (new Date().getTime() / 1000);
  //   var decoded: any = jwt(newToken ? newToken : token ? token : '');
  //   var time_exp = decoded.exp;
  //   if (time_exp < now) {
  //     await refreshToken();
  //   }
  // } else {
  //   LogOut()
  // }
}

export const rest = ky.create({
  prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
  hooks: {
    beforeRequest: [
      async (request) => {
        await jwtChecker(request)
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        await hook(response, request);
      },
    ],
  },
});
export const statApi = ky.create({
  headers: {
    Authorization: `Bearer ${token}`
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        await jwtChecker(request)
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        hook(response, request);
      },
    ],
  },
});

class ApiGateway {
  header: { Accept: string; "Content-Type": string; Authorization?: string };
  rest: typeof ky;
  constructor() {
    this.header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    this.rest = ky.create({
      prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
    });
    this.createInstance();
  }
  createInstance() {
    if (token) {
      this.header["Authorization"] = `Bearer ${token}`;
    }
    this.rest = ky.create({
      prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
    });
  }
}

export default new ApiGateway();

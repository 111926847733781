import { rest, restWithOutHook } from "../apiGateway";
import ky from "ky";

export const VehiclesList = () => {
  return rest.get("company/vehicles/").json();
};
export const RecalculateRoute = (json: any)  => {
  return rest.get("vehicles/booking/"+ json.id +"/recalculate_route/").json();
};


export const VehicleEdit = (json: any) => {
  return rest.patch("company/vehicles/"+ json.id + "/" , { json }).json();
};
export const TelematicsDevices = () => {
  return rest.get("telematics/devices/").json();
};

export const userEdit = (json: any) => {
  return rest.patch("users/" + json.id + "/" , { json }).json();
};

import React from "react";
//import "react-animated-slider/build/vertical.css";
interface Props {
  MenuSideState: boolean | true;
  AddSection: () => any | undefined;
  setOverview: (action: any) => void | undefined;
  Overview: boolean | true;
  SlideShowModeCall: () => void | undefined;
  t: Record<string, any>;
}

export default function DashboardHeader({
  setOverview,
  Overview,
  SlideShowModeCall,
  AddSection,
  MenuSideState,
  t,
}: Props) {
  return (
    <div className={MenuSideState ? "DSHFA DSHFA_mobile" : "DSHFA "}>
      <div className={"dashboardSectionHeader"}>
        <div className={"dashboardHeaderAction"} onClick={() => AddSection()}>
          <img alt={""} src={"/assets/icons/plus.svg"} />
          <span>{t.add_section}</span>
        </div>
        <div
          className={"dashboardHeaderAction"}
          onClick={() => {
            setOverview(!Overview);
          }}
        >
          <img alt={""} src={"/assets/icons/overview.svg"} />
          <span>{t.overview}</span>
        </div>
        <div
          className={
            Overview
              ? "dashboardHeaderAction dashboardHeaderActionDisable"
              : "dashboardHeaderAction"
          }
          onClick={() => {
            !Overview && SlideShowModeCall();
          }}
        >
          <img alt={""} src={"/assets/icons/SlideShow.svg"} />
          <span>{t.slideshow}</span>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useLayoutEffect } from "react";


import {
  MapContainer,
  TileLayer,
  Popup,
  Marker,
  Tooltip,
  Polyline,
  FeatureGroup, LayerGroup, useMap
} from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-markercluster';

import { Icon, LatLngBounds, LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import 'react-leaflet-markercluster/dist/styles.min.css' ; // inside .js file
interface CCMapProps {
  Data: any;
  VehicleSelect: (v: any) => void | undefined;
}


export default function FleetMap({ Data,VehicleSelect }: CCMapProps) {






  function iconGenerator(vehicle: any) {
    const carIcon = new Icon({
      iconUrl: vehicle.vehicle_image ? vehicle.vehicle_image : vehicle?.vehicle_type?.vehicle_image ,
      iconSize: [60, 50],
    });
    return carIcon;
  }


  const length = Data.length;
  var corner1 = new LatLng(
    (Data[length - 1] && Data[length - 1].location_point) ? Data[length - 1].location_point.coordinates[1] : (Data[length - 1] && Data[length - 1].coordinates) ? Data[length - 1].coordinates[1] : 0,
    (Data[length - 1] && Data[length - 1].location_point) ? Data[length - 1].location_point.coordinates[0] : (Data[length - 1] && Data[length - 1].coordinates) ? Data[length - 1].coordinates[0] : 0,
  );
  var corner2 = new LatLng(
    (Data && Data[0] && Data[0].location_point) ? Data[0].location_point.coordinates[1] : (Data[0] && Data[0].coordinates) ? Data[0].coordinates[1] : 0,
    (Data && Data[0] && Data[0].location_point) ? Data[0].location_point.coordinates[0] : (Data[0] && Data[0].coordinates) ? Data[0].coordinates[0] : 0,
  );
  var bounds = new LatLngBounds(corner1, corner2);




  


  return (
    <>
     <MapContainer bounds={bounds} scrollWheelZoom={true} trackResize={true} >

        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup>

{
          Data.map((vehicle: any, key: any) => (
            <Marker
              key={key}
              eventHandlers={{
                click: (e) => {
                  VehicleSelect(vehicle);
                },
              }}
                          position={[
                vehicle && vehicle.location_point
                  ? vehicle.location_point.coordinates[1]
                  : 0,
                vehicle && vehicle.location_point
                  ? vehicle.location_point.coordinates[0]
                  : 0,
              ]}
              icon={iconGenerator(vehicle)}
            >
                      <Tooltip direction="top" offset={[0, -25]} opacity={1} permanent>{vehicle.name}</Tooltip>

            </Marker>
          ))}
          </MarkerClusterGroup>
        {/* {
          Data.map((vehicle: any, key: any) => (
            <Marker
              key={key}
              position={[
                vehicle && vehicle.location_point
                  ? vehicle.location_point.coordinates[1]
                  : 0,
                vehicle && vehicle.location_point
                  ? vehicle.location_point.coordinates[0]
                  : 0,
              ]}
              icon={iconGenerator(vehicle)}
            >
              <Popup onOpen={() => { VehicleSelect(vehicle); }}
               onClose={() => { VehicleSelect({}); }} 
                >Location</Popup>
            </Marker>
          ))} */}

        {/* <Marker
        position={[
          Data.end_location.coordinates[1],
          Data.end_location.coordinates[0],
        ]}
        icon={arrIcon}
      >
        <Popup>Arrival Location</Popup>
      </Marker> */}
      </MapContainer>
    </>
  )
}

import * as dashboardService from "../services/dashboard.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
// First, create the thunk
export const stat = createAsyncThunk(
  "dashboard/stat",
  async (url: any) => {
    return await dashboardService.stat(url);
  },
);


export const saveSetting = createAsyncThunk(
  "web/setting",
  async (sections:any) => {
    return await dashboardService.saveSetting(sections);
  },
);

export const getSetting = createAsyncThunk(
  "web/setting",
  async () => {
    return await dashboardService.getSetting();
  },
);

export const groups = createAsyncThunk("dashboard/groups", async () => {
  return await dashboardService.groups();
});

import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useEffect } from "react";
import {
  vehicles
} from "../../../redux/actions/table.action";
import { LoadingButton } from "../FromUtils";
import { useDispatch } from "../../../redux/store";
import ColorSelect from "./ColorSelect";
import { useTranslation } from "react-i18next";
interface Props {
  Type: string;
  Title: string;
  ActionResult: Record<string, any>;
  TableRowsData: Record<string, any>;
  close: () => void | undefined;
  save: (type: any) => void | undefined;
}

export default function CreateView({
  TableRowsData,
  close,
  Title,
  save,
  Type,
  ActionResult,
}: Props) {
  const [Data, setData] = React.useState<Record<string, any>>({});
  const [Error, setError] = React.useState<boolean>(false);
  const [BtnLoading, setBtnLoading] = React.useState<boolean>(false);

  const [SearchPopUp, setSearchPopUp] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const [SearchRes, setSearchRes] = React.useState<Record<string, any>>([]);
  const [SearchKey, setSearchKey] = React.useState<Record<string, any>>([]);

  const [SelectedType, setSelectedType] = React.useState<string>(Type);

  function SearchChange(e: any, col: any) {
    const value = e.target.value;
    const SearchData = { search: value, type: SelectedType };
    setSearchKey({
      ...SearchKey,
      [col.search]: value,
    });
    setSearchPopUp(true);
    if (col.search === "vehiclesBrands") {
      // dispatch(vehiclesBrands(SearchData)).then((res: any) => {
      //   if (res && res.payload) {
      //     //setSearchRes(res.payload.results);
      //     setSearchRes({
      //       ...SearchRes,
      //       [col.search]: res.payload.results,
      //     });
      //   }
      // });
    }

    if (col.search === "vehiclesSubtypes") {
      // dispatch(vehiclesSubtypes(SearchData)).then((res: any) => {
      //   if (res && res.payload) {
      //     //setSearchRes(res.payload.results);
      //     setSearchRes({
      //       ...SearchRes,
      //       [col.search]: res.payload.results,
      //     });
      //   }
      // });
    }
    if (col.search === "vehicle") {
      // dispatch(vehicles(SearchData)).then((res: any) => {
      //   if (res && res.payload) {
      //     //setSearchRes(res.payload.results);
      //     setSearchRes({
      //       ...SearchRes,
      //       [col.search]: res.payload.results,
      //     });
      //   }
      // });
    }
  }

  function setSearchObject(col: any, id: any, name: string) {
    setData({
      ...Data,
      [col.name]: id,
    });
    setSearchKey({
      ...SearchKey,
      [col.search]: name,
    });
  }

  function change(event: any) {
    const name = event.target.name;
    const value = event.target.value;
    setData({ ...Data, [name]: value });
  }

  function validation() {
    setBtnLoading(true);
    save(Data);
  }

  useEffect(() => {
    if (ActionResult) {
      setBtnLoading(false);
      if (ActionResult.payload && !ActionResult.payload.error) {
        close();
      } else {
        ActionResult.payload && setError(ActionResult.payload.errorMassage);
      }
    }
  }, [ActionResult]);

  function tagDelete(key: number) {
    const obj_groups = [...Data.obj_groups];
    obj_groups.splice(key, 1);
    setData({
      ...Data,
      obj_groups,
    });
  }

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      const value = e.target.value;
      const name = e.target.name;
      let obj_groups = Data && Data.obj_groups;
      if (!obj_groups) {
        obj_groups = [];
      }
      obj_groups.push(value);
      setData({
        ...Data,
        obj_groups,
        [name]: "",
      });
    }
  }

  function inputType(col: Record<string, any>, key: number) {
    let res = null;
    if (
      col.type !== "select" &&
      col.type !== "array" &&
      col.type !== "color" &&
      col.type !== "search"
    ) {
      res = (
        <input
          value={Data[col.name] ? Data[col.name] : ""}
          type={col.type}
          name={col.name}
          onChange={(value) => change(value)}
        />
      );
    } else {
      if (col.type === "color") {
        res = (
          <ColorSelect
            colorsSet={(value) => {
              setData({ ...Data, color: value });
            }}
            InputColor={Data.color}
          />
        );
      }
      if (col.type === "select") {
        res = (
          <select
            className={"form-control"}
            name={col.name}
            onChange={(value) => change(value)}
          >
            {col.options.map((op: any, key: any) => (
              <option key={key} value={op.name}>
                {op.label}
              </option>
            ))}
          </select>
        );
      }
      if (col.type === "search") {
        res = (
          <div>
            <input
              value={SearchKey[col.search]}
              type={col.type}
              name={col.name}
              onChange={(value) => SearchChange(value, col)}
            />
            {SearchKey && SearchRes[col.search] && (
              <div className={"searchResBox"}>
                {SearchRes[col.search] &&
                  SearchRes[col.search].map((searchRow: any, key: any) => (
                    <div
                      className={
                        Data[col.name] === searchRow.id
                          ? "searchResBoxItem searchResBoxItemActive"
                          : "searchResBoxItem"
                      }
                      key={key}
                      onClick={() => {
                        setSearchObject(col, searchRow.id, searchRow.name);
                        //setSearchPopUp(false)
                      }}
                    >
                      {searchRow.name}
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      }
      if (col.type === "array") {
        res = (
          <input
            type="text"
            value={Data["tagInput"] ? Data["tagInput"] : ""}
            name={"tagInput"}
            onChange={(value) => change(value)}
            onKeyDown={(value) => handleKeyDown(value)}
          />
        );
      }
    }

    const row = (
      <div className={"editRow"} key={key}>
        <div className={"editRowTitle"}>
          {col.label} {col.required && <span className={"required"}>*</span>}
        </div>
        {res}
        {col.type === "array" &&
          Data.obj_groups &&
          Data.obj_groups[0] &&
          Data.obj_groups.map((tag: any, key: any) => (
            <div className={"tagTable"} key={key}>
              {tag}
              <span className={"tagDelete"} onClick={() => tagDelete(key)}>
                <img src={"/assets/close.svg"} />
              </span>
            </div>
          ))}
      </div>
    );
    if (!col.hiddenCreate) {
      if (
        col.type === "search" &&
        col.name === "sub_type" &&
        SelectedType !== "TRAILER" &&
        SelectedType !== "TRUCK"
      ) {
        return <div></div>;
      }

      return row;
    } else {
      return <div></div>;
    }
  }
  const [t] = useTranslation();
  useEffect(() => {
    console.log("SearchKey", SearchKey);
  }, [SearchKey]);
  function SelectType() {
    let VehicleTypeSelect = null;
    if (
      SelectedType === "TRUCK" ||
      SelectedType === "TRAILER" ||
      SelectedType === "TRACTOR"
    ) {
      VehicleTypeSelect = (
        <div className={"editRow"}>
          <div className={"editRowTitle"}>{t("table.select_vehicle_type")}</div>

          <div className="TableSelectVehiclType">
            <div
              onClick={() => {
                setSelectedType("TRACTOR");
                setSearchRes([]);
              }}
              className={
                SelectedType === "TRACTOR"
                  ? "SingleType activeType"
                  : "SingleType"
              }
            >
              <img src="https://fs-rest-static.s3.amazonaws.com/static/vehicles_icon/44566C/Tractor_2.png" />

              {t("table.Tractor")}
            </div>{" "}
            <div
              onClick={() => {
                setSelectedType("TRUCK");
                setSearchRes([]);
              }}
              className={
                SelectedType === "TRUCK"
                  ? "SingleType activeType"
                  : "SingleType"
              }
            >
              <img src="https://fs-rest-static.s3.amazonaws.com/static/vehicles_icon/44566C/Truck_Box.png" />

              {t("table.Truck")}
            </div>
            <div
              onClick={() => {
                setSelectedType("TRAILER");
                setSearchRes([]);
              }}
              className={
                SelectedType === "TRAILER"
                  ? "SingleType activeType"
                  : "SingleType"
              }
            >
              <img src="https://fs-rest-static.s3.amazonaws.com/static/vehicles_icon/44566C/Trailer_Box_3.png" />

              {t("table.Trailer")}
            </div>
          </div>
        </div>
      );
    }

    return VehicleTypeSelect;
  }
  return (
    <div className={"editView"}>
      <OutsideClickHandler onOutsideClick={() => close()}>
        <div className={"editForm"}>
          <div className={"title"}>Create New {Title}</div>
          {Error && (
            <div className="alert alert-danger">
              <strong>{t("table.Error")}</strong> {Error}
            </div>
          )}
          {/* {SelectType()} */}
          {TableRowsData.map((col: Record<string, any>, key: number) =>
            inputType(col, key),
          )}
          <div className={"editFormSave editFormCreate"}>
            <div
              className={"editAction editActionCancel"}
              onClick={() => close()}
            >
              {t("table.Cancel")}
            </div>

            <LoadingButton
              className={"editAction editActionSave"}
              loading={BtnLoading}
              onClick={() => validation()}
              text={t("table.Add_New")}
              icon={"/assets/icons/plus-white.svg"}
            />
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { coupons, couponsStripePredefined, couponEdit, UpdateSettings } from "../../redux/actions/table.action";
import { LoadingView } from "./../../utilities/components/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DropDown } from "../../utilities/components/FromUtils";

export default function Stripe() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [Loading, setLoading] = React.useState(false);
  const [Search, setSearch] = React.useState("");
  const [StripePredefined, setStripePredefined] = React.useState([]);
  const [SelectedData, setSelectedData] = React.useState<any>({});

  let Old = [];
  React.useEffect(() => {
    dispatch(coupons()).then(async (response) => {
      setSelectedData(response?.payload);

    });

    dispatch(couponsStripePredefined()).then(async (response) => {
      setStripePredefined(response?.payload);
    });
  }, []);


  function errorsShow(errors: any) {
    let error = '';
    const a: any = Object.keys(errors)
    a.map((e: any) => {
      error += e + " = " + errors[a]
    })
    return error;
  }


  function selectedFind(pk:any){
    const result = StripePredefined.filter((s:any) => s.pk === pk);
    console.log("a",result)
    return result[0];
  }


  React.useEffect(() => {
    console.log('SelectedData', SelectedData)
  }, [SelectedData])

  function update() {
    console.log('updateupdateupdateupdate', SelectedData)
    const data = SelectedData ;
    if(data.days_valid === '-1'){
      data.days_valid = null
    }
    
    if(data.max_redemptions === '-1'){
      data.max_redemptions = null
    }
    
    if(data.followon_max_redemptions === '-1'){
      data.followon_max_redemptions = null
    }
    
    if(data.followon_days_valid === '-1'){
      data.followon_days_valid = null
    }


    dispatch(couponEdit(data)).then(async (response) => {
      console.log('a', response?.payload)
      if (!response?.payload.error) {
        toast.success('Updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error(response?.payload?.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  }


  function changeKey(e: any) {
    let value = e.target.value;
    let name = e.target.name;

    setSelectedData({
      ...SelectedData,
      [name]: value,
    });


  }
  return (
    <div className="main-container d-flex">
      <ToastContainer />
      {Loading && <LoadingView />}
      <div className="settingView">
        <div className="profileSideColTitle"> Stripe</div>

        <div className="modal-content-section d-flex flex-column StripeView">
          <div className="profileSideColTitle"> Coupon Management</div>

          <div className="title"> Explanation:</div>
          <div>This page allows to partly automate the coupons we assign to new users that we onboard. It allows us to automatically assign a coupon to a user, keep track of the number of usages and if necessary assign a second coupon.
            Usage:</div>
          <div className="title"> Usege:</div>

          <div>1) From the dropdown select the coupon that should be assigned a new user when they sign up.</div>
          <div>1a) Select the number of days this coupon is valid. Type -1 if the coupon should be valid for infitite, i.e. not to be removed automatically.</div>
          <div>1b) Select the number of times the user can redeem this coupon within the validity time. Type -1 if there are no limits how many times it is redeemed.</div>
          <div>2) Once the number of redemptions and/or the validity is reached, we can automatically assign a different coupon. Leave empty if no coupon should be added automatically.</div>
          <div>2a) Select the number of days this coupon is valid.</div>
          <div>2b) Select the number of redemptions.</div>
          <div>3) Click the update to update the server. Changes will take place immediate for all consecutive usages.</div>
          <div className="stripe-select">



            <div className="row">
              <div className="editRow drop">
                <div className="editRowTitle">New User Coupon</div>
                <DropDown
                  selectedItem={selectedFind(SelectedData?.stripe_coupon)}
                  textEntity={["name"]}
                  items={StripePredefined}
                  placeholder={""}
                  onChange={(selected: any) => {
                    setSelectedData({
                      ...SelectedData,
                      'stripe_coupon': selected.pk,
                    });
                  }}
                />
              </div>

              <div className="editRow">
                <div className="editRowTitle">Days valid: [-1 for infite]</div>
                <input
                  type="text"
                  name="days_valid"
                  onChange={(e) => { changeKey(e) }}
                  value={SelectedData.days_valid === null ?  '-1' : SelectedData.days_valid}
                />
              </div>
              <div className="editRow">
                <div className="editRowTitle">Redemptions: [-1 for infite]</div>
                <input
                  type="text"
                  name="max_redemptions"
                  onChange={(e) => { changeKey(e) }}
                  value={SelectedData.max_redemptions === null ? '-1' : SelectedData.max_redemptions}
                />
              </div>
            </div>


            <div className="row">
              <div className="editRow drop">
                <div className="editRowTitle">Follow-on Coupon</div>
                <DropDown
                  selectedItem={selectedFind(SelectedData?.followon_stripe_coupon)}
                  textEntity={["name"]}
                  items={StripePredefined}
                  placeholder={""}
                  onChange={(selected: any) => {
                    setSelectedData({
                      ...SelectedData,
                      'followon_stripe_coupon': selected.pk,
                    });
                  }}
                />
              </div>
              <div className="editRow">
                <div className="editRowTitle">Days valid: [-1 for infite]</div>
                <input
                  type="text"
                  name="followon_max_redemptions"
                  onChange={(e) => { changeKey(e) }}
                  value={SelectedData.followon_max_redemptions === null ? '-1' : SelectedData.followon_max_redemptions}
                />
              </div>
              <div className="editRow">
                <div className="editRowTitle">Redemptions: [-1 for infite]</div>
                <input
                  type="text"
                  name="followon_days_valid"
                  onChange={(e) => { changeKey(e) }}
                  value={SelectedData.followon_days_valid === null ? '-1' : SelectedData.followon_days_valid}
                />
              </div>
            </div>

          </div>
        </div>

        <button
          onClick={() => {
            update();
          }}
          className="LoadingButton updateBtn"
        >
          <img src="/assets/icons/icon-basic-refresh.svg" />
          Update
        </button>
      </div>
    </div>
  );
}

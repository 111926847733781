import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { settings, UpdateSettings } from "../../redux/actions/table.action";
import { LoadingView } from "./../../utilities/components/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FormHeader,
  InputWithIcon,
} from "../../utilities/components/FromUtils";

export default function Settings() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [Loading, setLoading] = React.useState(false);
  const [Search, setSearch] = React.useState("");
  const [SettingsData, setSettingsData] = React.useState([]);
  const [SearchData, setSearchData] = React.useState([]);

  let Old = [];
  React.useEffect(() => {
    dispatch(settings()).then(async (response) => {
      setSettingsData(response.payload);
      Old = response.payload;
    });
  }, []);
  function reset(key:number) {
    let net: any = [...SettingsData];
    net[key].value = net[key].default;
    setSettingsData(net);
  }

  function errorsShow(errors:any){
    let error = '' ;
    const a:any = Object.keys(errors)
    a.map((e:any)=>{
      error += e + " = " + errors[a]
    })
    return error ;
  }

  function update() {
    let data:any = {};
    SettingsData.map((row:any)=>{
      data[row.key] = row.value ;
    })
    dispatch(UpdateSettings(data)).then(async (response) => {
      if(!Object.keys(response?.payload.errors).length){
        toast.success('Updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }else{
        toast.error(errorsShow(response?.payload?.errors), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  }

  function search(keyword: any) {
    setSearch(keyword);
    const filtered = SettingsData.filter((entry: any) =>
      Object.values(entry).some(
        (val) => typeof val === "string" && val.includes(keyword)
      )
    );
    setSearchData(filtered);
  }

  function changeKey(e: any, key: number){
    let net: any = [...SettingsData];
    let value = e.target.value;
    let name = e.target.name;
    if(name !== "START_HOUR_FOR_FETCH_OR_RESERVE" && name !== "END_HOUR_FOR_FETCH_OR_RESERVE"){
      value = parseInt(value);
    }
    net[key].value = value;
    setSettingsData(net);
  }
  return (
    <div className="main-container d-flex">
      <ToastContainer />
      {Loading && <LoadingView />}
      <div className="settingView">
        <FormHeader text={"Settings"} />
        <div className={"tableSearch tableSearchSetting"}>
          <div className="search-input-table">
            <InputWithIcon
              value={Search}
              placeholder={t("table.Search")}
              pngSource={"/assets/icons/icon-basic-search.svg"}
              onChange={(key: string) => search(key)}
            />
          </div>
        </div>

        <table className="table tableVehicle">
          <thead>
            <tr>
              <th scope="col">KEY</th>
              <th scope="col">Default</th>
              <th scope="col">Value</th>
              <th scope="col">Reset</th>
            </tr>
          </thead>
          <tbody>
            {Search !== "" &&
              SearchData?.map((row: any, key: any) => (
                <tr key={key}>
                  <td>
                    {row.key}
                    <br />
                    <small>{row.help_text}</small>
                  </td>
                  <td>{row.default}</td>
                  <td>
                    <input
                      type={(row.key === "START_HOUR_FOR_FETCH_OR_RESERVE" || row.key === "END_HOUR_FOR_FETCH_OR_RESERVE") ? "text" : 'number'}
                      value={row.value}
                      name={row.key}
                      onChange={(e: any) => {
                        changeKey(e, key);
                      }}
                    />
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        reset(key);
                      }}
                    >
                      reset
                    </button>
                  </td>
                </tr>
              ))}

            {Search === "" &&
              SettingsData?.map((row: any, key: any) => (
                <tr key={key}>
                  <td>
                    {row.key}
                    <br />
                    <small>{row.help_text}</small>
                  </td>
                  <td>{row.default}</td>
                  <td>
                    <input
                       type={(row.key === "START_HOUR_FOR_FETCH_OR_RESERVE" || row.key === "END_HOUR_FOR_FETCH_OR_RESERVE") ? "text" : 'number'}
                       value={row.value}
                       name={row.key}
                       onChange={(e: any) => {
                         changeKey(e, key);
                       }}
                    />
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        reset(key);
                      }}
                    >
                      <img src="/assets/icons/reset.svg" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <button
          onClick={() => {
            update();
          }}
          className="LoadingButton updateBtn"
        >
          <img src="/assets/icons/icon-basic-refresh.svg" />
          Update
        </button>
      </div>
    </div>
  );
}

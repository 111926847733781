import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const lan = localStorage.getItem("lang") || "en";
i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: lan,
    fallbackLng: "en",
    ns: "english",
    defaultNS: "english", // we do not use keys in form messages.welcome
    debug: true,
    react: {
      useSuspense: false,
    },
    returnObjects: true,
    saveMissing: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  });

export default i18n;

import * as vehicles from "../services/vehicle.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandler } from "../apiGateway";


export const VehiclesList = createAsyncThunk(
  "company/vehicles/",
  async () => {
    try {
      return await vehicles.VehiclesList();
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const RecalculateRoute = createAsyncThunk(
  "company/RecalculateRoute/",
  async (json: any) => {
    try {
      return await vehicles.RecalculateRoute(json);
    } catch (err) {
      return errorHandler(err);
    }
  },
);



export const TelematicsDevices = createAsyncThunk(
  "Telematics/Devices/",
  async () => {
    try {
      return await vehicles.TelematicsDevices();
    } catch (err) {
      return errorHandler(err);
    }
  },
);



export const VehicleEdit = createAsyncThunk(
  "VehicleEdit/",
  async (json: any) => {
    try {
      return await vehicles.VehicleEdit(json);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

// export const profileEdit = createAsyncThunk(
//   "profile/edit",
//   async ({ data, token }: { data: any; token?: any }) => {
//     try {
//       return await userService.profileEdit({ data, token });
//     } catch (err) {
//       return errorHandler(err);
//     }
//   },
// );
// export const passwordChange = createAsyncThunk(
//   "password-change/",
//   async (json: any) => {
//     try {
//       return await userService.passwordChange(json);
//     } catch (err) {
//       return errorHandler(err);
//     }
//   },
// );
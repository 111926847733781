import {useState} from "react";
import { useImmer } from "use-immer";
import { Link, useNavigate } from "react-router-dom";
import {
  InputWithIcon,
  CheckboxWithTitle,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import useAuth from '../../context/hooks/useAuth';
import { signIn ,deviceAdd} from "../../redux/actions/auth.action";

import {setUser} from "../../redux/actions/user.action";
import { loginValidator } from "../../utilities/functions/authValidator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import {Shape} from "../../utilities/components/Shape"

export default function Login() {
  const dispatch = useDispatch();
  const { setAuth } = useAuth();
  const [t] = useTranslation();
  const [BtnLoading, setBtnLoading] = useState(false);
  const [state, setState] = useImmer({
    username: "",
    password: "",
    rememberMe: false,
    permission: true,
    valid: "",
  });

  const validate = (data) => {
    setState((state) => {
      state.valid = "";
    });
    if (loginValidator(data)) {
      return true;
    } else {
      setState((state) => {
        state.valid = t("auth.credentials_not_valid");
      });
      return false;
    }
  };
  

 

  const login = () => {
    const data = {
      username: state.username,
      password: state.password,
    };

    //     try {
    //       const response = await axios.post('auth/login/', data);
    //       const access_token = response?.data?.access_token;
    //       const refresh_token = response?.data?.refresh_token;
    //       const roles = ["2001"];
    //       const user = {};
    //       localStorage.setItem("token", access_token);
    //       localStorage.setItem("refresh_token", refresh_token);
    //       localStorage.removeItem("user");
    //       await setAuth({ user, roles, access_token, refresh_token });
    //       const prev_url = localStorage.getItem("prev_url");
    //       if (prev_url) {
    //         const array = prev_url.split("/");
    //         if (array[3] == "panel") {
    //           navigate("/" + array[3] + "/" + array[4]);
    //         } else {
    //           navigate("panel/tasks");
    //         }
    //       } else {
    //         navigate("panel/tasks");
    //       }
    //     } catch (err) {
    //       setState((state) => {
    //         state.valid = t("auth.credentials_not_valid");
    //       });
    //     }
    //   }
    // };

    if (validate(data)) {
      setBtnLoading(true);

      dispatch(signIn(data)).then(async (response) => {
        setBtnLoading(false);
        // if (response.payload && response.payload.user.role !== "MANAGER" && 0) {
        //   setState((state) => {
        //     state.valid =
        //       "You don’t have sufficient permissions to enter the site. Please contact your admin";
        //   });
        // } else {
        if (response.payload && response.payload.access_token) {
          const access_token = response?.payload?.access_token;
          const refresh_token = response?.payload?.refresh_token;
          const user = response?.payload?.user;
          const roles = ["2001"];
          //const user = {};

          localStorage.setItem("token", access_token);
          localStorage.setItem("refresh_token", refresh_token);
          setAuth({ user, roles, access_token, refresh_token });

          localStorage.removeItem("user");
          const prev_url = localStorage.getItem("prev_url");
          await dispatch(setUser(user));


          const res = user;
          if (!res.is_safety_driver && !res.is_dispatcher_driver && !res.is_staff) {

            setState((state) => {
              state.valid = "You can't login here , please use app";
            });

          } else if (prev_url) {
            console.log(prev_url.split("/")[3])
            if (prev_url.split("/")[3] === "panel") {
              window.location.replace(prev_url);
            } else {
              window.location.replace("/panel/tasks");
            }
          } else {
            window.location.replace("/panel/tasks");
          }
       


        } else {
          setState((state) => {
            state.valid = t("auth.credentials_not_valid");
          });
        }
        // }
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };
  return (
    <div className="main-container main-auth-container login-container d-flex">
      <div className="login-shape">
      <Shape/>
      <img src="assets/logo/Driverless Sticker w QR.png" className="logo"/>
      </div>
      <div className="login-section fadeIn">
        <div className="login-form-inputs-container d-flex justify-content-center flex-column">
          <div id="login-form" className="login-form-inputs-header">
            {/* <img
              className="login-form-inputs-header-img"
              src={"/assets/logo/logo.png"}
              alt="fetch-logo"
            /> */}
            <h3>Login</h3>
          </div>
          {/* <small className="body-1-text login-form-inputs-header-text remove-under-1440">
            {t("home.welcome-back")}
          </small> */}
          <InputWithIcon
            value={state.username}
            name={"name"}
            placeholder="Username (Email)"
            pngSource={"/assets/icons/Profile2x.png"}
            onChange={(username) =>
              setState((state) => {
                state.username = username;
              })
            }
          />
          <InputWithIcon
            value={state.password}
            placeholder={t("home.password")}
            type="password"
            onKeyDown={(event) => handleKeyDown(event)}
            pngSource={"/assets/icons/Padlock2x.png"}
            onChange={(password) =>
              setState((state) => {
                state.password = password;
              })
            }
          />
          <div className="d-flex aligm-items-center justify-content-between">
            <div>
              <CheckboxWithTitle
                value={state.rememberMe}
                text={t("home.remember_me")}
                onChange={(rememberMe) =>
                  setState((state) => {
                    state.rememberMe = rememberMe;
                  })
                }
              />
            </div>
            <Link
              to="/forgot-password"
              className="forget-password-link deep-sky-blue-text"
            >
              <span className="forgot-password-under-1440">
                {t("home.forgot-password")}
              </span>
            </Link>
          </div>
          {state.valid && (
            <div style={{ width: "200px", display: "block" }}>
              <span style={{ color: "red", fontSize: "11px" }}>
                {state.valid}
              </span>
            </div>
          )}
          <div className="login-button">
            <LoadingButton
              loading={BtnLoading}
              onClick={() => login()}
              text={"Login"}
            />
          </div>
          {/* <div className="or-text d-flex align-items-center justify-content-between remove-under-1440">
              <div className="or-line" />
              OR
              <div className="or-line" />
            </div>
            <div className="login-links d-flex align-items-center justify-content-center remove-under-1440">
              <a href="https://www.facebook.com">
                <img src={'/assets/logo/Facebookx2.png'} alt="facebook-logo" />
              </a>
              <a href="https://www.twiter.com">
                <img src={'/assets/logo/Twitterx2.png'} alt="twiter-logo" />
              </a>
              <a href="https://www.google.com" style={{ marginRight: 0 }}>
                <img src={'/assets/logo/Googlex2.png'} alt="googleplus-logo" />
              </a>
            </div> */}
          <small className="login-form-switch-part body-2-text">
            {t("home.dont-have-account")}
            <Link to="/" className="deep-sky-blue-text">
              {t("home.signUp")}
            </Link>
          </small>
        </div>
      </div>
    </div>
  );
}

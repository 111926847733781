import * as React from "react";
import { ChartLoadingSvg, MapLoadingSvg } from "./../../assets/icons";

export default function Loading({ Type }: any) {
  return (
    <>
      <div className={"widgetLoading"}>
        <ChartLoadingSvg />
      </div>
    </>
  );
}

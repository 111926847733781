import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { LoadingView } from "../../utilities/components/Loading";
import { companyVehicles, damageCreate,damageEdit } from "../../redux/actions/table.action";
import { driverEdit, drivers } from "../../redux/actions/table.action";
import { userEdit, getBillingHistory, getCards } from "../../redux/actions/user.action";
import OutsideClickHandler from "react-outside-click-handler";
import {
  DropDown,
  InputWithIcon,
  CheckboxWithTitle,
  LoadingButton, ImagePicker, ImagePickerMultiple
} from "../../utilities/components/FromUtils";
import InputWrapper from "../../utilities/components/InputWrapper/InputWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface Props {
  Damage: Record<string, any>;
  Vehicle?: Record<string, any>;
  close: () => void | undefined;
  DamageUpdate?: (d:any) => void | undefined;
}

export default function DamageDetails({
  close, Damage,DamageUpdate,Vehicle
}: Props) {

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [DamageData, setDamageData] = React.useState<any>({});
  const [Vehicles, setVehicles] = React.useState<any>({});
  const [image, setImage] = React.useState(null);

  const [Loading, setLoading] = React.useState(false);

React.useEffect(()=>{
  setDamageData(Damage)
},[])

  
  function EditDamage(Data: any) {
    setLoading(true);
    let data:any = Data
    data.id = Data.id
    data.vehicle = Data.vehicle.id
    data.images = undefined

    console.log("Data",Data)


    if (typeof data.vehicle === "object") {
      data.vehicle = data.vehicle.id
    }
    dispatch(damageEdit({data})).then((response: any) => {
      console.log("AA",data)
      setLoading(false);
      if (response.payload && !response.payload.errorMassage) {


        setDamageData(response.payload);
        DamageUpdate && DamageUpdate(response.payload)
        toast.success("Saved", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error("error : " + response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    })

  }

  function saveUser(Data: any) {
    setLoading(true);
    let data = { ...Data }


    if (typeof data.vehicle === "object") {
      data.vehicle = data.vehicle.id
    }

    if(!data.vehicle && Vehicle?.id){
      data.vehicle = Vehicle.id
    }

    dispatch(damageCreate({ data })).then((response: any) => {
      setLoading(false);
      if (response.payload && !response.payload.errorMassage) {
        setDamageData(response.payload);
        DamageUpdate && DamageUpdate(response.payload)
        toast.success("Saved", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error("error : " + response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    })

  }
  React.useEffect(() => {
    if (image !== null) {
      setDamageData({
        ...DamageData,
        uploaded_images: image,
      });
    }
  }, [image]);


  React.useEffect(() => {
    dispatch(companyVehicles({})).then((response: any) => {
      setVehicles(response?.payload?.results)
    })
  }, [])



  function change(event: any) {
    const name = event.target.name;
    let value = event.target.value;

    setDamageData({
      ...DamageData,
      [name]: value,
    });
  }



  return (

    <div className="editViewFa editViewFa ">
      <OutsideClickHandler
        onOutsideClick={() => {
          close();
        }}
      >
        <div className="editViewPopup editViewPopupDamage">

          <div
            className="editViewClose"
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/icons/closeCircle.svg" />
          </div>
          <div className="profile-fa profile-edit-fa customScrollBar">

            <div className="modal-account-account-detail d-flex">
              <div className={"col-12"}>
                <div className="profileSideColTitle">
                  {Damage.id ? <div>{"Edit Damage "} </div> : "Damage Details"}

                </div>
                <div className={"d-flex"}>
                  <div className="col-8 ">

                    <InputWrapper label={"Description" + ":"} important>
                      <input
                        name="description"
                        onChange={(value) => change(value)}
                        value={DamageData && DamageData?.description}
                        placeholder="Description"
                      />
                    </InputWrapper>

                    <InputWrapper label={"Vehicle" + ":"} important>
                      <DropDown
                        type="vehicle"
                        selectedItem={DamageData?.vehicle ? DamageData?.vehicle : Vehicle ?  Vehicle : null}
                        textEntity={["name"]}
                        items={Vehicles}
                        placeholder={'Select Vehicle'}
                        onChange={(selected: any) => {
                          setDamageData({
                            ...DamageData,
                            vehicle: selected,
                          });
                        }}
                      />
                    </InputWrapper>


                    <InputWrapper label={"Type" + ":"} important>
                      <DropDown
                        selectedItem={DamageData?.type}
                        items={["SAVERELY_DAMAGED", "DENTED", "GRAFFITI", "SCRATCHED"]}
                        textEntity={null}
                        placeholder={'Select Type'}
                        onChange={(selected: any) => {
                          setDamageData({
                            ...DamageData,
                            type: selected,
                          });
                        }}
                      />
                    </InputWrapper>


                    <InputWrapper label={"Size" + ":"} important>
                      <DropDown
                        selectedItem={DamageData?.size}
                        items={["LARGE", "SMALL"]}
                        textEntity={null}
                        placeholder={'Select Size'}
                        onChange={(selected: any) => {
                          setDamageData({
                            ...DamageData,
                            size: selected,
                          });
                        }}
                      />
                    </InputWrapper>
                    <InputWrapper label={"Side" + ":"} important>
                      <DropDown
                        selectedItem={DamageData?.side}
                        items={["CENTER", "DRIVER_SIDE", "PASSENGER_SIDE", "FRONT", "TOP", "REAR", "INTERIOR", "OTHER"]}
                        textEntity={null}
                        placeholder={'Select Side'}
                        onChange={(selected: any) => {
                          setDamageData({
                            ...DamageData,
                            side: selected,
                          });
                        }}
                      />
                    </InputWrapper>



                    <InputWrapper label={"Area" + ":"} important>
                      <DropDown
                        selectedItem={DamageData?.area}
                        items={["BUMPER", "CHARGER_FLAP", "GRILL", "HOOD", "INDICATOR", "LICENSE_PLATE", "LIGHTING_SYSTEM", "PARKING_SENSOR", "REFLECTOR", "SPOILER", "TOW_FLAP", "WINDSHIELD", "WIPER", "DOOR", "DOOR_HANDLE_OUTSIDE", "FENDER", "QUARTER_WINDOW", "RIM", "ROCKER_PANEL", "SIDE_VIEW_MIRROR", "SIDE_WINDOW", "TIRE", "EXHAUST", "REAR_WINDOW", "TRUNK_HATCH", "TRUNK_DOOR", "ANTENNA", "PANORAMIC_ROOF", "ROOF", "CEILING", "CENTER_CONSOLE", "GEAR_KNOB", "IN_CAR_DISPLAY_SCREEN", "HEADREST", "REAR_VIEW_MIRROR", "REAR_SHELF", "SAFETY_BELT", "SEAT", "STEERING_WHEEL", "AXLE", "EV_CHARGING_CABLE", "CLUTCH", "GEARBOX", "OIL_PAN", "UNDERBODY"]}
                        textEntity={null}
                        placeholder={'Select Area'}
                        onChange={(selected: any) => {
                          setDamageData({
                            ...DamageData,
                            area: selected,
                          });
                        }}
                      />
                    </InputWrapper>




                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_repaired'} onChange={(value: any) => {
                          setDamageData({
                            ...DamageData,
                            is_repaired: value.target.checked,
                          });
                        }} checked={DamageData?.is_repaired} />
                        <div className="checkboxTitle">is Repaired</div>
                      </div>
                    </div>
                    <div className="checkboxWrapper">
                      <div className="checkboxDiv">
                        <input type={'checkbox'} name={'is_valid'} onChange={(value: any) => {
                          setDamageData({
                            ...DamageData,
                            is_valid: value.target.checked,
                          });
                        }} checked={DamageData?.is_valid} />
                        <div className="checkboxTitle">is Valid</div>
                      </div>
                    </div>


                  </div>
                  <div className="col-4 ">
                    <ImagePickerMultiple
                      label="Image"
                      image={DamageData?.picture ? DamageData?.picture : undefined}
                      imageFile={(image: any) => setImage(image)}
                    />

                    {Damage.id ?  <div className="picked-images">
                      {DamageData?.images?.map((imgFile:any, key:any) => (
                        <img
                          key={key}
                          src={imgFile.image}
                        />
                      ))}

                    </div>
                    : '' }
                    {Damage.id ? <LoadingButton
                      className={"updateBtn updateUserBtn"}
                      onClick={() => {
                        EditDamage(DamageData);
                      }}
                      text={"Edit Damage"}
                      icon={"/assets/icons/icon-basic-refresh.svg"} /> : <LoadingButton
                      className={"updateBtn updateUserBtn"}
                      onClick={() => {
                        saveUser(DamageData);
                      }}
                      text={"Report Damage"}
                      icon={"/assets/icons/icon-basic-refresh.svg"} />
                    
                    
                    }
                    

                  </div>
                </div>





              </div>

            </div>
          </div>
        </div>
      </OutsideClickHandler>
      {Loading && (
        <LoadingView />
      )}
    </div>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import SideBar from "./SideBar";
import HeaderAndMain from "./HeaderAndMain";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom"
export default function MainContainer({ props }: any) {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [load, setLoad] = useState(false);
  const [sideState, setSideState] = useState(false);

  const user = useSelector((state: RootState) => state.user.profile);
  const Allroles = useSelector((state: RootState) => state.user.roles);
  function checkRole(name: any) {
    const roles: any = user.roles;
    let disable = true
    if (roles) {
      roles.map((role: any) => {
        if (Allroles[role].includes(name) || role === 'is_staff') {
          disable = false
        } else {
          disable = true
        }
      })
      return disable
    } else {
      window.location.replace("/login");
    }

  }

  const [sideMenuItems, setSideMenuItems] = useState([
    {
      title: "menu.dashboard",
      name: "Dashboard",
      value: true,
      notifCount: 0,
      disable: checkRole('dashboard'),
      page: "/panel/dashboard",
      icon: "/assets/icons/white/monitor.png",
      child: []
    },
    {
      title: "menu.tasks",
      name: "Tasks",
      page: "/panel/tasks",
      value: false,
      disable: checkRole('tasks'),
      notifCount: 0,
      icon: "/assets/icons/white/task.png",
      child: [
        {
          title: "All Task",
          name: "AllTask",
          page: "/panel/all-task",
          disable: checkRole('tasks'),
          value: false,
          notifCount: 0,
        }, {
          title: "Delivery",
          name: "Delivery",
          page: "/panel/delivery",
          disable: checkRole('tasks'),
          value: false,
          notifCount: 0,
        }, {
          title: "Return",
          name: "Return",
          page: "/panel/Return",
          disable: checkRole('tasks'),
          value: false,
          notifCount: 0,
        }, {
          title: "Valet",
          name: "Valet",
          page: "/panel/valet-parking",
          disable: checkRole('valet-parking'),
          value: false,
          notifCount: 0,
        }
      ]
    },
    {
      title: "menu.users",
      name: "Users",
      page: "/panel/Users",
      disable: checkRole('users'),
      value: false,
      notifCount: 0,
      icon: "/assets/icons/white/users.png",
      child: []
    },
    {
      title: "menu.fleet",
      name: "Fleet",
      page: "/panel/fleet",
      disable: checkRole('fleet'),
      value: false,
      notifCount: 0,
      icon: "/assets/icons/white/car.png",
      child: [{
        title: "Fleet",
        name: "Fleet",
        page: "/panel/Fleet",
        disable: checkRole('fleet'),
        value: false,
        notifCount: 0,
      }, {
        title: "Vehicles",
        name: "Vehicles",
        page: "/panel/Vehicles",
        disable: checkRole('vehicles'),
        value: false,
        notifCount: 0,
      },
      {
        title: "Telematics Devices",
        name: "TelematicsDevices",
        page: "/panel/telematics-devices",
        disable: checkRole('telematics-devices'),
        value: false,
        notifCount: 0,
      }
      ]

    },
    {
      title: "menu.bookings",
      name: "Bookings",
      page: "/panel/Bookings/?filter=COMPLETE",
      disable: checkRole('bookings'),
      value: false,
      notifCount: 0,
      icon: "/assets/icons/white/Hail.svg",
      child: []
    },
    {
      title: "Tasks Timeline",
      name: "Calendar",
      page: "/panel/calendar",
      disable: checkRole('calendar'),
      value: false,
      notifCount: 0,
      icon: "/assets/icons/white/timeline.png",
      child: []
    },
    
    {
      title: "Messaging",
      name: "Messaging",
      page: "/panel/messaging",
      disable: checkRole('calendar'),
      value: false,
      notifCount: 0,
      icon: "/assets/icons/white/mail.png",
      child: []
    },
    // {
    //   title: "Report Damages",
    //   name: "ReportDamages",
    //   page: "/panel/Report-Damages",
    //   disable: checkRole('ReportDamages'),
    //   value: false,
    //   notifCount: 0,
    //   icon: "/assets/icons/Accident.svg",
    //   child: []
    // },

    {
      title: "menu.settings",
      name: "Settings",
      page: "/panel/Settings",
      disable: checkRole('settings'),
      value: false,
      notifCount: 0,
      icon: "/assets/icons/white/setting.svg",
      child: [   {
        title: "Profile",
        name: "Profile",
        page: "/panel/Profile",
        disable: checkRole('profile'),
        value: false,
        notifCount: 0,
        icon: "/assets/icons/white/User.svg",
        
      },    
      {
        title: "Settings",
        name: "Settings",
        page: "/panel/Settings",
        disable: checkRole('settings'),
        value: false,
        notifCount: 0,
        icon: "/assets/icons/white/setting.svg",
     
      },
      {
        title: "Stripe",
        name: "Stripe",
        page: "/panel/Stripe",
        disable: checkRole('settings'),
        value: false,
        notifCount: 0,
        icon: "/assets/icons/white/setting.svg",
     
      },
    
    ]
    },
  ]);
  const [subPage, setSubPage] = useState("/");
  const [page, setPage] = useState("/");


  useEffect(() => {

  });

  return (
    <div className="main-container pale-grey-background">
      <div className="full-section">
        <div className="header-side-container d-flex ">
          <SideBar
            setSideMenuItems={(sideMenuItems) => {
              setSideMenuItems(sideMenuItems);
            }}
            sideMenuItems={sideMenuItems}
            sideState={sideState}
            setSideState={(sideState) => {
              setSideState(sideState);
            }}
          />
          <HeaderAndMain
            sideState={sideState}
            setSideState={(sideState) => setSideState(sideState)}
          >
            <Outlet />
          </HeaderAndMain>
        </div>
      </div>
    </div>
  );
}

import React, { ReactNode } from "react";

interface InputWrapperProps {
  label: string;
  important: boolean;
  children: ReactNode;
}
export default function InputWrapper({
  label,
  important,
  children,
}: InputWrapperProps) {
  return (
    <div className="input-with-label-container">
      <small>
        {label} <span>{important ? "*" : ""}</span>
      </small>
      <div className="input-with-label">
        {children}
        {/* <input
          autoComplete="new-password"
          type={type}
          placeholder={placeholder}
          value={value}
          onKeyDown={(event) => onKeyDown(event)}
          onChange={(event) => onChange(event.target.value)}
        /> */}
      </div>
    </div>
  );
}

InputWrapper.defaultProps = {
  important: false,
  label: "Label",
};

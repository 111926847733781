import { createSlice } from "@reduxjs/toolkit";
import {profile} from "../actions/user.action";
const user = localStorage.getItem("user");
const initialState: Record<string, any> = {
  profile: user && user !== "undefined" ? JSON.parse(user) : {},
  roles: {
    is_dispatcher_driver: ['tasks', 'dashboard', 'profile', 'team-calendar', 'telematics-devices', 'bookings'],
    is_safety_driver: ['tasks', 'dashboard', 'profile', 'team-calendar', 'telematics-devices', 'bookings'],
    is_staff: ['all','settings'],
  }
}
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(profile.fulfilled, (state, { payload }) => {
      let roles = []
      if (payload.is_dispatcher_driver) {
        roles.push('is_dispatcher_driver');
      }
      if (payload.is_safety_driver) {
        roles.push('is_safety_driver')
      }
      if (payload.is_staff) {
        roles.push('is_staff')
      }
      //state.roles = roles;
      let res = payload;
      res.roles = roles
      state.profile = res;
      localStorage.setItem("user", JSON.stringify(payload));
    });
  },
});

export default userSlice;

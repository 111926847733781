import { createContext, useState } from "react";
import {ContextType} from '../context/@types.context'
interface Auth {
    access_token?:string;
    refresh_token?:string;
    user?: Record<string, any>;
    roles?: Record<string, any>;
  };
const AuthContext =  createContext<any>({} as any)
  


export const AuthProvider = ({ children }:any) => {
    const [auth, setAuth] = useState({});

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { damages, damageCreate } from "../../redux/actions/table.action";
import { LoadingView } from "./../../utilities/components/Loading";
import OutsideClickHandler from "react-outside-click-handler";
import DamageDetails from "./DamageDetails"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Props {
  selectDamage: Record<string, any>;
  close: () => void | undefined;
}

export default function Damages({
  close, selectDamage
}: Props) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [Loading, setLoading] = React.useState(false);
  const [Damage, setDamage] = React.useState<any>({});
  const [Data, setData] = React.useState([]);
  const [DamageDetailsView, setDamageDetailsView] = React.useState(false);
  

  useEffect(() => {
    dispatch(damages()).then((response: any) => {
      if (!response.payload.error) {
        setData(response.payload.results)
      } else {

      }
    });
  }, []);
  useEffect(() => {
    if(Damage?.id){
      setDamageDetailsView(true)

    }
  }, [Damage]);

  return (
    <div className="editViewFa editViewFa ">
      <OutsideClickHandler
        onOutsideClick={() => {
          close();
        }}
      >
        <div className="editViewPopup editViewPopupDamage">
          <div
            className="editViewClose"
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/icons/closeCircle.svg" />
          </div>
          <div className="profile-fa profile-edit-fa customScrollBar">

            <div className="modal-account-account-detail d-flex">
              <div className={"col-12"}>
                <div className="profileSideColTitle">
                  Damages
                </div>
                <div className={"d-flex"}>
                  <div className="col-12 damegeItems">
                    {Data.map((d: any, key: any) => (
                      <div className="damegeItem" onClick={() => { setDamage(d) }}>
                        <div className="description">{d.description}</div>
                        {d.area.length ? <span className="area">{d.area}</span> : ''}
                        {d.side.length ? <span className="area">{d.side}</span> : ''}
                        {d.size.length ? <span className="area">{d.size}</span> : ''}
                        <div className="bool"><span className={"is_repaired-" + d.is_repaired}>is repaired</span><span className={"is_valid-" + d.is_valid} >is valid</span></div>

                        <div className="damegeItemImages">
                          {d.images.map((img: any, key: any) => (
                            <img src={img.image} />
                          ))}
                        </div>
                        {/* {DamageLists(v)} */}
                      </div>

                    ))}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {DamageDetailsView && <DamageDetails Damage={Damage} close={()=>{setDamageDetailsView(false)}}/>}

      </OutsideClickHandler>
      {Loading && (
        <LoadingView />
      )}
    </div>
  );
}

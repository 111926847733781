import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import Table from "../../utilities/components/Table/Table";
import { LoadingView } from "./../../utilities/components/Loading";

import { Link, useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSound from "use-sound";
import queryString from 'query-string';

import {
  valets,
  valet,
  valetCounter,
  startValetDispatching
} from "../../redux/actions/table.action";
import CCMap from "../../utilities/components/CCMap";
import moment from "moment";
export default function Dashboard() {
  const alarm = `${process.env.PUBLIC_URL}/ringtone.mp3`;
  const [play, { pause }] = useSound(alarm);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [Tasks, setTasks] = React.useState<Array<string>>([]);
  const [COMPLETE, setCOMPLETE] = React.useState(0);
  const [OPEN, setOPEN] = React.useState(0);
  const [CANCEL, setCANCEL] = React.useState(0);
  const [CANCEL_NON_OPEN, setCANCEL_NON_OPEN] = React.useState(0);
  const [DISPATCHING, setDISPATCHING] = React.useState(0);
  const [OPEN_WITH_SAFETY, setOPEN_WITH_SAFETY] = React.useState(0);

  const [Filters, setFilters] = React.useState({
    title: "FILTERS",
    icon: "/assets/icons/Todo.svg",
    items: [
      {
        title: "All",
        icon: "/assets/icons/Todo.svg",
        name: "All",
        prop: "",
      },
      {
        title: "menu.open",
        icon: "/assets/icons/Clock Remaining.svg",
        name: "Open",
        prop: "OPEN",
      },
      {
        title: "menu.OPEN_WITH_SAFETY",
        icon: "/assets/icons/Steering WheelYellow.svg",
        name: "Open with Safety",
        prop: "OPEN_WITH_SAFETY",
      },
      {
        title: "menu.dispatching",
        icon: "/assets/icons/Steering WheelGreen.svg",
        name: "Dispatching",
        prop: "DISPATCHING",
      },
      {
        title: "menu.completed",
        icon: "/assets/icons/check.png",
        name: "Completed",
        prop: "COMPLETE",
      },
      {
        title: "CANCEL NON OPEN",
        icon: "/assets/icons/Delete.svg",
        name: "Canceled",
        prop: "CANCEL_NON_OPEN",
      },
      {
        title: "menu.canceled",
        icon: "/assets/icons/Delete.svg",
        name: "Canceled",
        prop: "CANCEL",
      },
    ],
  });

  const [Task, setTask] = React.useState<Record<string, any>>({});
  const [ShowMap, setShowMap] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [IntV, setIntV] = React.useState(0);



  const url: any = useLocation();
  const navigate = useNavigate();
  const query: any = queryString.parse(url && url.search);
  const [Filter, setFilter] = React.useState(
    queryString.parse(url && url.search).filter
  );

  async function TaskUpdate(id: any) {
    dispatch(valet(id)).then((response: any) => {
      if (response.payload) {
        setTask(response.payload);
        setLoading(false)
      }
    });
  }
  async function openDrawer(state: any, task?: any) {
    setLoading(false);
    clearInterval(IntV);
    if (state) {
      !ShowMap && setShowMap(true);
      const i: any = setInterval(() => TaskUpdate(task.id), 20000);
      setIntV(i);
    } else {
      setShowMap(false);
    }
  }

  React.useEffect(() => {
    let status = searchParams.get('filter');
    setFilter(status);
    request({ status });
  }, [searchParams]);

  function request(p?: any) {
    let props: any = {};
    if (p) { props = p }
    const query: any = queryString.parse(url && url.search);
    const filter = query.filter;
    if (filter) {
      props.status = filter;
    }

    dispatch(valets(p)).then((response: any) => {
      if (response.payload && response.payload.results) {
        setTasks(response.payload);
        setLoading(false);
      }
    });
  }
  function menuCounter() {
    dispatch(valetCounter()).then((response: any) => {
      if (response && response.payload && !response.payload.error) {
        response.payload.map((obj: any, key: any) => {
          if (obj.status === "COMPLETE") {
            setCOMPLETE(obj.count)
          }
          if (obj.status === "CANCEL") {
            setCANCEL(obj.count)
          }
          if (obj.status === "CANCEL_NON_OPEN") {
            setCANCEL_NON_OPEN(obj.count)
          }
          if (obj.status === "OPEN_WITH_SAFETY") {
            setCANCEL_NON_OPEN(obj.count)
          }
          if (obj.status === "DISPATCHING") {
            setDISPATCHING(obj.count)
          }

          if (obj.status === "OPEN") {
            setOPEN(obj.count)
          }
        })
      }
    })

  }
  React.useEffect(() => {
    menuCounter();
    request();
  }, []);

  function RowMap(row: any) {

    return <CCMap Data={row} />;
  }

  function btnAction(json: any, status: any) {
    json.status = status;
    dispatch(startValetDispatching({ id: json.id, json })).then((response: any) => {
      if (!response.payload.error) {
        request();
        menuCounter();
      } else {
        toast.error(response.payload.errorMassage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  function childGenrator(item: any) {
    let status = searchParams.get('filter');

    const links = (
      <li
        onClick={() => {
          navigate("?filter=" + item.prop);
        }}
        className={status === item.prop ? "filterItem filterItemActive d-flex align-items-center" + " filterItem-" + item.prop : "filterItem d-flex align-items-center" + " filterItem-" + item.prop}
      >
        <img className={"menuChildIcon"} src={item.icon} />
        <small
          style={{
            // display: !MenuIsOpen ? "none" : "unset",
            color: !item.value ? "#8697A8" : "#44566c",
          }}
        >
          {t(item.title)}
        </small>
        {item.prop !== "" &&
          <span className={item.prop}>
            {item.prop === "OPEN" && OPEN}
            {item.prop === "CANCEL" && CANCEL}
            {item.prop === "OPEN_WITH_SAFETY" && OPEN_WITH_SAFETY}
            {item.prop === "COMPLETE" && COMPLETE}
            {item.prop === "CANCEL_NON_OPEN" && CANCEL_NON_OPEN}
            {item.prop === "DISPATCHING" && DISPATCHING}
          </span>
        }
      </li>
    );

    return (
      <>
        <div className="links">{links}</div>
      </>
    );
  }
  function selectBook(row: any) {
    setLoading(true);
    console.log("row.id", row.id)
    dispatch(valet(row.id)).then((response: any) => {
      if (response.payload) {
        console.log("setTask", response.payload)
        setTask(response.payload);
        if (response?.payload?.booking && response?.payload?.booking?.vehicle) {
          openDrawer(true, response.payload);
        } else {
          setLoading(false);
          toast.error("booking is null", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    });
  }

  return (
    <div className="main-container d-flex">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {Loading && (
        <LoadingView />
      )}
      <div className="ulChildView menuFilterView">
        <div className="filtersView">

          {Filters.items.map((item: any) => childGenrator(item))}
        </div>
      </div>
      <Table
        columns={[
          { name: 'vehicle', key: 'booking.vehicle.vehicle_image+booking.vehicle.vehicle_type.name', sort_key: 'booking__vehicle__name' },
          { name: 'license', key: 'booking.vehicle.license' },
          { name: 'name', key: 'booking.vehicle.name', sort_key: 'booking__vehicle__name' },
          { name: 'start_at', key: 'start_at', sort_key: 'start_at' },
          { name: 'book_for', key: 'booking.book_for', sort_key: 'booking__book_for' },
          { name: 'delivery_address', key: 'booking.start_address', sort_key: 'start_address' },
          { name: 'booking_status', key: 'booking.status', sort_key: 'status' },
          { name: 'customer', key: 'booking.user.picture+booking.user.first_name+booking.user.last_name+booking.user.mobile_number', sort_key: 'user' },
          { name: 'KM', key: 'booking.vehicle.distance_by_car_in_meters' },
          { name: 'HH:MM', key: 'booking.vehicle.delivery_time_in_seconds' },
          { name: 'safty', key: 'safety_driver.picture+safety_driver.first_name+safety_driver.last_name', sort_key: 'safety_driver' },
          { name: 'teleop', key: 'dispatcher_driver.picture+dispatcher_driver.first_name+dispatcher_driver.last_name', sort_key: 'dispatcher_driver' },
        ]}
        rowSelect={(row: any) => {
          selectBook(row);
        }}
        getData={(props: any) => {
          request(props);
        }}
        tableData={Tasks}
        tableType={"Valet_filter"}
        title={"Valet " + " - " + (Filter ? Filter : "All")}

      />
      {ShowMap && (
        <div className="mapPopUpView">
          <div
            className="mapPopUpViewClose"
            onClick={() => {
              openDrawer(false);
            }}
          >
            <img src="/assets/icons/closeCircle.svg" />
          </div>
          <div className="tableMapRowOpen">
            <div className="tableMapContainer">{RowMap(Task)}</div>
          </div>

          <div className="vehicleInfoContainer">
            {Task?.safety_driver && <>
              <div className="vehicleInfoTitle">Safety Driver</div>
              <div className="dispatcherInfo">
                <div><img src={Task?.safety_driver?.picture} className="dispatcherImage" /></div>
                <div>
                  {Task?.safety_driver?.first_name}  {Task?.safety_driver?.last_name} <br />
                  {Task?.safety_driver?.email} <br />
                  {Task?.safety_driver?.mobile_number} <br />
                  {Task?.safety_driver?.id} <br />
                </div>
              </div>
            </>
            } {Task?.dispatcher_driver && <>
              <div className="vehicleInfoTitle">Dispatcher</div>
              <div className="dispatcherInfo">
                <div><img src={Task?.dispatcher_driver?.picture} className="dispatcherImage" /></div>
                <div>
                  {Task?.dispatcher_driver?.first_name}  {Task?.dispatcher_driver?.last_name} <br />
                  {Task?.dispatcher_driver?.email} <br />
                  {Task?.dispatcher_driver?.mobile_number} <br />
                  {Task?.dispatcher_driver?.id} <br />
                </div>
              </div>
            </>
            }
            <br /> <br />
            <div>

              <strong>Customer  Name :</strong> - (soon) <br />
              <strong>Start Address :</strong> {Task?.start_address} <br />
              <strong>End Address :</strong> {Task?.end_address} <br />
              <strong>Car Routes :</strong> {Task?.car_routes ? 'Yes' : "No"} <br />

            </div>
            <div className="vehicleInfo">

              <div className="carImgBox">
                <div><img src={Task.booking?.vehicle.vehicle_image ? Task.booking?.vehicle.vehicle_image : Task.booking?.vehicle?.vehicle_type?.vehicle_image} className="carImage" /></div>
                <div>{Task?.booking && Task?.booking?.vehicle && Task.booking?.vehicle?.name} - {Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle?.license}</div>
                <div>is disabled : {Task.booking?.vehicle?.is_disabled ? "YES" : "NO"}</div>
                <div>is parked   :  {Task.booking?.vehicle?.is_parked ? "YES" : "NO"}</div>
                <div>Online : {Task.booking?.vehicle?.last_reported ? moment(Task.booking?.vehicle?.last_reported).format("ddd , D MMM YYYY hh:mm a") : "-"}</div>
              </div>
              <div className="carInfo">
                <div className="title"> <img src={Task?.booking && Task.booking?.vehicle && Task.booking?.vehicle.vehicle_type.vehicle_brand_image} />{" "} {Task?.booking && Task.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.NAME}
                </div>
                <div className="row"> <img src="/assets/icons/rad.png" /> {Task?.booking && Task.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.propulsion_type}
                </div>
                <div className="row"> <img src="/assets/icons/seting.png" /> {Task?.booking && Task.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.transmission_type}
                </div>
                <div className="row"> <img src="/assets/icons/seat.png" /> {Task?.booking && Task.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.number_of_seats}{" "} Seats
                </div>
                <div className="row"> <img src="/assets/icons/dif.png" /> {Task?.booking && Task.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.body_configuration_type}{" "}
                </div>
                <div className="row"> <img src="/assets/icons/batr.png" /> {Task?.booking && Task.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.max_range_meters}{" "} km
                </div>
              </div>
            </div>
            <div className="mapDispatching">
              {Task.status !== "COMPLETE" && <>
                {Task.status === "OPEN" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching startDispatchingDisable " : "startDispatching stopDispatching"}
                //onClick={() => start(Task)} 
                > START DISPATCHING (Disabled) </div>)}
                {Task.status === "OPEN_WITH_SAFETY" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching  " : "startDispatching stopDispatching"} onClick={() => btnAction(Task, "DISPATCHING")} > START DISPATCHING (SAFETY) </div>)}
                {/* {Task.status === "COMPLETE" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching " : "startDispatching stopDispatching"} onClick={() => ""} > Complete </div>)} */}
                {Task.status === "CANCEL" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching " : "startDispatching stopDispatching"} onClick={() => btnAction(Task, "DISPATCHING")} > Canceled <span> Click To Distpatching </span> </div>)}
                {Task.status === "DISPATCHING" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching " : "startDispatching stopDispatching"} onClick={() => btnAction(Task, "COMPLETE")} > End Dispatching </div>)}
                {/* <small> ( {Task.status} )</small> */}
                {Task.status !== "CANCEL" && (<div className={"startDispatching canselDispatching"} onClick={() => { btnAction(Task, "CANCEL"); }} > Cancel </div>)}
              </>}</div>
          </div>


        </div>
      )}
    </div>
  );
}

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { passwordChange } from "../../../redux/actions/user.action";
import { useDispatch } from "../../../redux/store";
import InputWrapper from "../../../utilities/components/InputWrapper/InputWrapper";
import { ToastContainer, toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";

import "react-toastify/dist/ReactToastify.css";

interface Props {
  close: () => void | undefined;
}
export default function PasswordChange({ close }: Props) {
  const dispatch = useDispatch();
  const [Error, setError] = useState("");
  const [Data, setData] = useState<Record<string, any>>({});
  const [t] = useTranslation();

  function save() {
    dispatch(passwordChange(Data)).then((response: any) => {
      if (response.payload && !response.payload.error) {
        close();

        toast.success(t("New password has been saved."), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        setError(response.payload.errorMassage);
      }
    });
  }

  function change(event: any) {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...Data,
      [name]: value,
    });
  }

  return (
    <div className={"popupBg"}>
      <div className={"popup-content popup-content-language"}>
        <OutsideClickHandler
          onOutsideClick={() => {
            close();
          }}
        >
          <div className="account-modal-close d-flex align-items-center justify-content-center">
            <button
              onClick={() => {
                close();
              }}
            >
              <img src={"/assets/icons/closeCircle.svg"} alt="close-icon" />
            </button>
          </div>
          <div className={"title"}>{t("account.password_change")}</div>
          <div className={"ErrorMassage"}>{Error}</div>

          <div className={"d-flex mt-2 passchangeinput"}>
            <InputWrapper label={t("account.newPassword") + ":"} important>
              <input
                name="new_password1"
                onChange={(value) => change(value)}
                value={Data && Data.last_name}
                placeholder={t("account.newPassword")}
              />
            </InputWrapper>
          </div>
          <div className={"d-flex mt-2 passchangeinput"}>
            <InputWrapper
              label={t("account.newPasswordRepeat") + ":"}
              important
            >
              <input
                name="new_password2"
                onChange={(value) => change(value)}
                value={Data && Data.last_name}
                placeholder={t("account.newPasswordRepeat")}
              />
            </InputWrapper>
          </div>
          <div
            className={"add"}
            onClick={() => {
              save();
            }}
          >
            <img src={"/assets/icons/plus-white.svg"} />
            {t("account.change")}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  TelematicsDevice,
  TelematicsDevicesEdit,
  TelematicsDevicesDelete,TelematicsDevicesCreate,
} from "../../redux/actions/table.action";
import Table from "../../utilities/components/Table/Table";
import { useTranslation } from "react-i18next";
// interface ChartProps {
//     // pin: () => void | undefined;
//     // remove: () => void | undefined;
//     // changeDate: (Range: object) => void | undefined;
//     // el: Record<string, any>;
// }

const DefaultParam = {
  offset: 0,
  limit: 10,
  search: false,
  ordering: false,
  according: false,
};

export default function TelematicsDevices() {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const TableRowsData = [
    {
      name: "name",
      label: t("table.name"),
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "license",
      label:"license",
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "location_point",
      label:"location point",
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "location_address",
      label:"location address",
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    
    {
      name: "price",
      label: "Price",
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
  ];

  const [Result, setResult] = React.useState<any>();
  const [DefaultParams, setDefaultParams] = React.useState<any>(DefaultParam);
  const tableData = useSelector((state: RootState) => state.table.TelematicsDevice);
  useEffect(() => {
    dispatch(TelematicsDevice(DefaultParams));
  }, []);
  useEffect(() => {
    if (Result) {
      dispatch(TelematicsDevice(DefaultParams));
    }
  }, [Result]);

 async function request(Params: any) {
    if (
      DefaultParams.offset !== Params.offset ||
      DefaultParams.limit !== Params.limit ||
      DefaultParams.search !== Params.search ||
      DefaultParams.ordering !== Params.ordering ||
      DefaultParams.according !== Params.according
    ) {
      setDefaultParams(Params);
      await dispatch(TelematicsDevice(Params));
    }
  }

  function create(data: any) {
    dispatch(TelematicsDevicesCreate(data)).then((res) => {
      if (res) {
        setResult(res);
      }
    });
  }

  function edit(data: any) {
    dispatch(TelematicsDevicesEdit(data)).then((res: any) => {
      if (res) {
        setResult(res);
      }
    });
  }

  function deleteRow(data: any) {
    dispatch(TelematicsDevicesDelete(data)).then((res) => {
      if (!res.payload.error) {
        setResult({ delete: true });
      } else {
        setResult(res);
      }
    });
  }
  function TagSave(ids: any, group_name: any) {
    // dispatch(vehiclesTag({ ids, group_name })).then((res) => {
    //   if (!res.payload.error) {
    //     setResult(true);
    //     const title = t("account.saved");
    //     toast.success(title, {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //     });
    //   } else {
    //     // setResult(res);
    //   }
    // });
  }

  return (
    <div>
      <Table
        getData={(Params: any) => {
          request(Params);
        }}
        CreateItem={(data) => create(data)}
        EditItem={(data) => edit(data)}
        ActionResult={Result}
        clearResult={() => {
          setResult(null);
        }}
        TableRowsData={TableRowsData}
        tableData={tableData}
        editable={false}
        creatable={false}
        DeleteRow={(data) => deleteRow(data)}
        TagSave={(ids, group_name) => {
          TagSave(ids, group_name);
        }}
        title={"Telematics"}
        tableType={"Telematics"}
      />
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import Header from './HeaderLayout'
function ForwardToApp() {
  useEffect(() => {
    if (isMobile) {
      window.location.replace("fetch://");
    } else {
      window.location.replace("http://fetch-mobility.com/");

    }
  }, [])
  return (<>
    <Header />
    <BrowserView>
      <div className='goToApp'>
        <a href="http://fetch-mobility.com/">Go To Site</a>
      </div>
    </BrowserView>
    <MobileView>
      <div className='goToApp'>
        <a href="fetch://">Go To App</a>
      </div>
    </MobileView>

  </>);
}
export default ForwardToApp;

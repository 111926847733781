import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import {
    ScrollingProvider,
    useScrollSection,
    Section,
} from 'react-scroll-section';

export default function Header() {
    const { t, i18n } = useTranslation();
    const [OpenMenu, setOpenMenu] = useState(false);
    const homeSection = useScrollSection('home');
    const how = useScrollSection('how');
    const contact = useScrollSection('contact');
    function scroll(id) {
        if(window.location.pathname === "" || window.location.pathname === "/"){
            try {
                if (id === "home") {
                    homeSection.onClick();
                }
                if (id === "how") {
                    how.onClick();
                }
                if (id === "contact") {
                    contact.onClick();
                }
            } catch (e) {}
        }
        setOpenMenu(false)
    }

    return (
        <>
            <menu>
                <div className={'menu-mobile'} onClick={() => { setOpenMenu(!OpenMenu) }}>
                    {OpenMenu ? <img src={'/assets/icons/MenuClosed@2x.png'} /> :
                        <img src={'/assets/icons/MenuClosed@2x.png'} />
                    }
                </div>
                <Link to="/"> <img src={'/assets/icons/logo.png'} className={'headerLogo'} /></Link>
                <div className='MenuCenter'></div>
                <ul className={OpenMenu ? "menuShow" : ""}>
                    <li onClick={() => { scroll('home') }} className={homeSection.selected ? "active" : ''} ><Link to='/' >HOME</Link></li>
                    <li onClick={() => { scroll('how') }} className={how.selected ? "active" : ''} ><Link to='/'  >HOW IT WORKS</Link></li>
                    <li onClick={() => { scroll('contact') }} className={contact.selected ? "active" : ''} ><Link to='/' >CONTACT US</Link></li>
                    <li className={"active"} onClick={() => {}} ><Link to='/Terms'  >TERMS</Link></li>

                </ul>
            </menu>
        </>
    )
}

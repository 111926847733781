import { createSlice } from "@reduxjs/toolkit";
import {
  drivers,
  companyVehicles,
  valetTasks,
  bookings,
  TelematicsDevice,pricing_plan
} from "../actions/table.action";
const initialState: Record<string, any> = {
  drivers: null,
  companyVehicles: null,
  bookings:null,
  valetTasks:null,
  canDevices: null,
  TelematicsDevice : null,
  pricing_plan:null
};
const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(drivers.fulfilled, (state, { payload }) => {
      state.drivers = payload;
    });
    builder.addCase(companyVehicles.fulfilled, (state, { payload }) => {
      state.companyVehicles = payload;
    });
    builder.addCase(bookings.fulfilled, (state, { payload }) => {
      state.bookings = payload;
    });
    builder.addCase(valetTasks.fulfilled, (state, { payload }) => {
      state.valetTasks = payload;
    });
    
    builder.addCase(TelematicsDevice.fulfilled, (state, { payload }) => {
      state.TelematicsDevice = payload;
    });
    builder.addCase(pricing_plan.fulfilled, (state, { payload }) => {
      state.pricing_plan = payload;
    });
    
    
  },
});

export default tableSlice;

import React, { Dispatch } from "react";
import { Router, Route, Routes, redirect } from "react-router-dom";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import Main from "./utilities/components/Main";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import RestPassword from "./pages/Auth/RestPassword";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import Tasks from "./pages/Tasks/Tasks";
import Return from "./pages/Return/Return";
import Dashboard from "./pages/DashboardApp/Dashboard";
import Users from "./pages/Users/Users";
import Vehicles from "./pages/Vehicels/Vehicles";
import Fleet from "./pages/Fleet/Fleet";
import Profile from "./pages/Profile/Profile";
import Home from "./pages/Website/Home";
import Confirm from "./pages/Website/Confirm";
import ForwardToApp from "./pages/Website/ForwardToApp";
import TermsConditions from "./pages/Website/TermsConditions";
import { ScrollingProvider } from "react-scroll-section";
import Bookings from "./pages/Booking/Booking";
import Setting from "./pages/Setting/Setting";
import ReportDamages from "./pages/ReportDamages/ReportDamages";
import Stripe from "./pages/Setting/Stripe";
import CalendarNew from "./pages/Calendar/CalendarNew";
import ValetTasks from "./pages/Valet/Valet";
import Messaging from "./pages/Messaging/Messaging";

import TasksAll from "./pages/Tasks-All/Tasks-All";
import TelematicsDevices from "./pages/TelematicsDevices/TelematicsDevices";
import RequireAuth from "./context/components/RequireAuth";
import { ParallaxProvider } from "react-scroll-parallax";
import Missing from "./context/components/Missing";
import './firebase-messaging-sw';

function App(token: any, user: any) {
  return (
    <Routes>
      <Route path="/verify/email" element={<VerifyEmail />} />
      <Route path="/terms" element={<TermsConditions />} />
      <Route path="/webcontanct/confirm" element={<Confirm />} />
      <Route path="/forward-to-app" element={<ForwardToApp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify/email/" element={<VerifyEmail />} />
      <Route
        path="/password-reset/confirm/:uid/:token/"
        element={<RestPassword />}
      />
      {/* <Route
        path="/"
        element={
          <ParallaxProvider>
            <ScrollingProvider>
              <Home />
            </ScrollingProvider>
          </ParallaxProvider>
        }
      /> */}
      <Route
        path="/"
        element={<Login />}
      />

      <Route
        path="*"
        element={<Missing title="You do not have access to this page" />}
      />
      <Route path="panel">
        <Route element={<Main />}>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  "is_staff",
                  "is_dispatcher_driver",
                  "is_safety_driver",
                ]}
              />
            }
          >
            <Route path="" element={<Tasks />} />
            <Route path="all-task" element={<TasksAll />} />
            <Route path="delivery" element={<Tasks />} />
            <Route path="return" element={<Return />} />
            <Route path="tasks" element={<Tasks />} />
            <Route path="tasks" element={<Tasks />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="telematics-devices" element={<TelematicsDevices />} />
            <Route path="bookings" element={<Bookings />} />
            <Route path="messaging" element={<Messaging />} />
            
            <Route path="calendar" element={<CalendarNew />} />
            
            <Route path="report-damages" element={<ReportDamages />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["is_staff"]} />}>
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Setting />} />
            <Route path="stripe" element={<Stripe />} />
            
            <Route path="fleet" element={<Fleet />} />
            <Route path="vehicles" element={<Vehicles />} />
            <Route path="valet-parking" element={<ValetTasks />} />
          </Route>
        </Route>
        <Route path="*" element={<Missing title={"Page Not Found"} />} />
      </Route>
      <Route path="*" element={<Missing title={"Page Not Found"} />} />
    </Routes>
  );
}

export default App;

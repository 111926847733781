import React, { useEffect } from "react";
import { useDispatch } from "../../redux/store";
import { VehiclesList } from "../../redux/actions/vehicle.action";
import VehicleEdit from "../Vehicels/VehicleEdit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FleetMap from "./Map/FleetMap";
import { LoadingView } from "../../utilities/components/Loading";
 import { RootState } from "../../redux/reducers";
  import { useSelector } from "react-redux";
// interface ChartProps {
//     // pin: () => void | undefined;
//     // remove: () => void | undefined;
//     // changeDate: (Range: object) => void | undefined;
//     // el: Record<string, any>;
// }



export default function Fleet() {
  const dispatch = useDispatch();
  const [VehicleActive, setVehicleActive] = React.useState<any>({});
  const [List, setList] = React.useState<any>([]);
  const [Loading, setLoading] = React.useState<any>(true);
  const user = useSelector((state: RootState) => state.user.profile);
  async function request() {

    dispatch(VehiclesList()).then((res) => {
      if (res.payload && res.payload.results) {
        setLoading(false)
        setList(res.payload.results)
      }
    });
  }


  useEffect(() => {
    request()
  }, []);


  useEffect(() => {
    console.log("VehicleActive",VehicleActive)
  }, [VehicleActive]);
  
  return (
    <div className="d-flex">
      {Loading ? <LoadingView/> :
      <>
      <ToastContainer />
      <div className="MapContainerFleet">
        {List.length && <FleetMap VehicleSelect={(v: any) => { setVehicleActive(v) }} Data={List} />}
        {VehicleActive && VehicleActive.vehicle_type && (<VehicleEdit Data={VehicleActive} Close={() => {
            setVehicleActive({})
          }}
            UpdateList={(action: any) => {
              action && request()
            }}
          />)
        }
      </div>
      </> }
    </div>
  );
}

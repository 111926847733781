import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {signIn,changeToken} from "../actions/auth.action";


// Then, handle actions in your reducers:
const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token") || "",
    refresh_token: localStorage.getItem("refresh_token") || "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      localStorage.setItem("token",payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);
    });
    builder.addCase(changeToken.fulfilled, (state, { payload }) => {
      state.token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      localStorage.setItem("token",payload.access_token);
      console.log("payload.refresh_token",payload.refresh_token)
      localStorage.setItem("refresh_token", payload.refresh_token);
    });
  },
});

export default authSlice;

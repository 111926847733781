import React, { useEffect, useRef } from "react";
import LoadingView from "./Loading";
import ErrorView from "./Error";
import LayoutHeader from "./Header/LayoutHeader";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "chart.js/auto";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Map from "./Map/Map";
import { Bar, Line, Radar, Doughnut, Pie } from "react-chartjs-2";

import { ArcElement } from "chart.js";

// import * as annotationPlugin from "chartjs-plugin-annotation";
// import WidgetError from "../Layouts/WidgetError";

//import {Context} from 'chartjs-plugin-datalabels';
//import "chartjs-plugin-datalabels";
interface ChartProps {
  widgetData: any;
  pin: () => void | undefined;
  info: () => void | undefined;
  SectionAction: () => void | undefined;
  remove: () => void | undefined;
  el: Record<string, any>;
  options: Record<string, any>;
  load: boolean | true;
  loaded?: () => void | undefined;
  DateOpen?: (v: any, id: any) => void | undefined;
  changeDate: (range: object) => void | undefined;
  t: Record<string, any>;
  Breakpoint: string;
}

export default function WidgetCreator({
  el,
  pin,
  Breakpoint,
  info,
  options,
  remove,
  load,
  loaded,
  changeDate,
  DateOpen,
  SectionAction,
  t,
  widgetData,
}: ChartProps) {
  const chartRef = useRef(null);
  const [ReRenderState, setReRenderState] = React.useState<any>(false);
  const [Loading, setLoading] = React.useState(true);
  const [Request, setRequest] = React.useState(false);
  const [Props, setProps] = React.useState<Record<string, any>>({});
  const [Error, setError] = React.useState(false);
  const [User, setUser] = React.useState<Record<string, any>>({});
  const [Group, setGroup] = React.useState("");
  //const [Data, setData] = React.useState<Record<string, any>>({});
  const [Config, setConfig] = React.useState<Record<string, any>>({});
  const [Response, setResponse] = React.useState<Record<string, any>>({});



  React.useEffect(() => {
    if (load) {
      setLoading(true);
    }
  }, [el.start_date, el.start_date]);

  function ChartGenerator(d: any) {
    const chartData = d;
    if (chartData) {
      setError(false);
      setLoading(false);

      if (chartData.user) {
        setUser(chartData.user);
      } else if (el && el.user) {
        //setUser(el.user);
      }
      if (el && el.group) {
        setGroup(el.group);
      }

      setConfig(chartData);
      setResponse(chartData);


    } else {
      console.log('ChartGenerator Error')
    }
  }

  async function request(Params: any) {
    if (Params.start_date === 'Invalid date' || !Params.start_date) {
      Params.timespan = 'EVER';
      Params.start_date = undefined
    } 
    if(Params.end_date === 'Invalid date' || !Params.end_date) {
      Params.timespan = 'EVER'
      Params.end_date = undefined
    }
    await widgetData(el.url_path, Params)
      .then((chartData: any) => {
        ChartGenerator(chartData);
      })
      .catch((e: any) => {
        setError(true);
        setLoading(false);
      });
  }
  function onPropChange(p: any, select: any) {

    setLoading(true);
    setRequest(false);

    let params = { ...Props };

    if (params) {
      params[p.name] = select;
    }
    if (!params.start_date) {
      params.start_date = el.start_date;
      params.end_date = el.end_date;
    }


    setProps(params)
    request(params);

  }
  const handleDateChange = (range: {
    start_date: string;
    end_date: string;
  }) => {

    setLoading(true);
    setRequest(false);
    changeDate(range);
    let params = { ...Props };
    params.start_date = range.start_date;
    params.end_date = range.end_date
    console.log("yoyoyoyoy", params);

    if (params) {
      if (range.start_date === 'Invalid date') {
        params.timespan = 'EVER';
        params.start_date = undefined
      } 
      if(range.end_date === 'Invalid date') {
        params.timespan = 'EVER'
        params.end_date = undefined;
      }
    }
    console.log("paramsss", params);

    setProps(params)
    request(params);
  };
  function SmallWidget(el: any) {
    const Width = window.innerWidth - 340;
    let widgetWidth = 0;
    if (el.w === 1) {
      return true
    }
    if (Breakpoint === "lg" || Breakpoint === "md") {
      widgetWidth = (Width / 3) * el.w;
    } else {
      widgetWidth = (Width / 2) * el.w;
    }
    if (widgetWidth < 475) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (Config && Loading && !Request) {
      loaded && loaded();
      setRequest(true);
      //const params = { start_date, end_date };
      let user_id = undefined;
      if (el.user_select && el.user) {
        user_id = el.user && el.user.id;
      }
      let user_group = undefined;
      if (el.group) {
        user_group = el.group;
        //setGroup(user_group)
      }

      let params:any = {
        user_id,
        user_group,
      };

      params.end_date = el.end_date
      params.start_date = el.start_date;

      if (!el.start_date) {
        params.timespan = 'EVER';
        params.start_date = undefined
        params.end_date = el.end_date
      } 
      if(!el.end_date) {
        params.timespan = 'EVER'
        params.end_date = undefined;
        params.start_date = el.start_date;
      }

      request(params);
    } else {
      setError(false); //checking error;
    }
  }, []);

  function ArrowsChart(data: any) {
    const rows = data;
    let Arrows: any = null;
    Arrows =
      rows &&
      rows.map((row: any, key: any) => {
        const number = 360 * (row.data.replace("%", "") / 100);
        const res = (
          <span
            key={key}
            className="ChartArrow"
            style={{
              transform:
                "rotate(" + number + "deg) translate(0px) rotate(0deg)",
            }}
          >
            <img src={row.icon} />
          </span>
        );
        return res;
      });
    if (Arrows) {
      return <div className="ArrowsChartFa">{Arrows}</div>;
    }
  }

  function customLegend(Response: any) {
    if (Response.info) {
      const legend = Response.info;

      if ((!SmallWidget(el)) || !Response.chart) {
        const leg: any = legend.rows;
        const average: any = legend.average;
        const description: any = Response.info?.description;
        const title: any = Response.info?.title;
        let res = null;
        let row = null;
        let HaveLegend = false;
        if (average) {
          HaveLegend = true;
          row = (
            <div className="PieAverage">
              <div className={"PieAverage-iconFa"}>
                <div className="PieAverage-icon">
                  {average.icon && <img src={average.icon} />}
                </div>

              </div>
              <div className={"PieAverageValue"}>
                {average.value}
                <span>{average.value_symbol}</span>
              </div>
            </div>
          );
        }
        if (leg && leg[0]) {
          HaveLegend = true;
          res = leg.map((row: any, key: any) => {
            return (
              <div className={"legendRow"} key={key}>
                {row.icon && (
                  <img
                    src={row.icon}
                  />
                )}
                <span>{row.name}</span>
                <strong>{row.data}</strong>
              </div>
            );
          });
        }
        if (HaveLegend) {
          return (
            <div
              className={
                el.chart && el.chart.type === "none"
                  ? "CustomLegendFa w100"
                  : "CustomLegendFa "
              }
            >
              {/* <div className="short_description">{description}</div> */}
              <div>{title ? title : ''}</div>

              <>{row}</>
              <div className="CustomLegend">{res}</div>
            </div>
          );
        }
      }

    }
  }

  function ChartRender(el: any) {
    const element = Response;

    window.dispatchEvent(new Event('resize'));

    if (!el || !element || Response.error) {
      return (
        <div className={"widgetError"}>
          <h3>Server Error {Response.errorMassage}</h3>
        </div>
      );
    }
    if (Response.empty) {
      return (
        <div className={"widgetError"}>
          <h3>{Response.text}</h3>
        </div>
      );
    }

    if (element.type === "Table") {
      return (
        <div className="ranking itemRow customScrollBar">
          {/* <Table
            getData={(Params: any) => {
              request(Params);
              console.log("request3");
            }}
            tableData={Data.data}
            tableType={Data.title}
            t={t}
          /> */}
        </div>
      );
    }
    if (element.type === "map_routing") {
      const legends: any = Response && Response.widget.absolute_view;
      return (
        <div className={"CCPointContainer"}>
          {/* <CCMap Data={Data} /> */}
          {legends && (
            <div className="MapLegend">
              {legends.map((row: any, key: any) => (
                <div className={"legendRow"} key={key}>
                  <div
                    style={{
                      backgroundColor: row.color ? row.color : "#8697a8",
                    }}
                    className={"legBox"}
                  ></div>
                  <span>{row.name}</span>
                  <strong>{row.value}</strong>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    if (element.map) {
      return (
        <div className={"TTMapContainer"}   >

          <Map Data={element.map.data} />
        </div>
      );
    }
    if (element.chart) {
      const chart = element.chart
      let className = '';
      if (element.info && !SmallWidget(el)) {
        className = "chartsContainer"
      } else {
        className = "chartsContainer chartsContainerFullWidth"
      }
      if (chart.type === "horizontal_bar") {
        return (
          <div className={className}>
            {chart && (
              <div className="HorizintlBar">
                <div className="HorizintlBarArrows">
                  {chart.arrows &&
                    chart.arrows.map(
                      (arrow: any) =>
                        arrow && (
                          <span style={{ left: arrow.left }}>
                            <img src={arrow.icon} />
                          </span>
                        )
                    )}
                </div>
                <div className="HorizintlBarBars">
                  {chart.bars &&
                    chart.bars.map(
                      (bar: any) =>
                        bar && (
                          <div
                            style={{
                              backgroundColor: bar.color,
                              width: bar.percentage,
                            }}
                          ></div>
                        )
                    )}
                </div>
                <div className="HorizintlBarNubmers">
                  {chart.numbers &&
                    chart.numbers.map((number: any, key: any) => (
                      <span key={key} style={{ left: number && number.left }}>
                        {number && number.number}
                      </span>
                    ))}
                </div>
                <div className="HorizintlBarDesc">
                  {chart.bar_description}
                </div>
              </div>
            )}
          </div>
        );
      }
      if (chart.subtype === "doughnut") {
        return (
          <div className={className}>

            <div className={"PieChartGrid"}>
              {ArrowsChart(chart.doughnut_chart?.arrows)}
              <Doughnut
                data={chart.data}
                options={chart.options}
              />
              <div className={"DoughnutPercent"}>
                <div>
                  {chart.doughnut_chart && chart.doughnut_chart.text.value}
                  {chart.doughnut_chart && chart.doughnut_chart.text.symbol}
                </div>
                <span>{chart.doughnut_chart && chart.doughnut_chart.text.label}</span>
              </div>
            </div>
          </div>
        );
      }
      if (chart.subtype === "line") {
        return (<div className={className}>

          <Line
            ref={chartRef}
            data={chart.data}
            options={chart.options}
          />
        </div>)
      }

      if (chart.subtype === "pie") {
        return (<div className={className}>

          <Pie
            ref={chartRef}
            data={chart.data}
            options={chart.options}
          />
        </div>)
      }

      if (chart.subtype === "bar") {
        return (<div className={className}>

          <Bar
            ref={chartRef}
            data={chart.data}
            options={chart.options}
          />
        </div>)
      }
      return (
        <div className={className}>

          Your Chart type is "{chart.type}" with subtype "{chart.subtype}" not detected <br />
          please use this types ['chartjs','horizontal_bar','map','info'] with this sub types ['bar','line','pie','doughnut','vehicle'] <br />
          more information in document
        </div>
      );
    }
  }

  // useEffect(() => {
  //   //console.log("useEffect User", User);
  // }, [User]);

  return (
    <div className={"itemRow"}    >
      {Loading && <LoadingView />}
      {Error && <ErrorView />}
      <LayoutHeader
        Props={Props}
        t={t}
        onPropChange={(p: any, selected) => {
          onPropChange(p, selected);
        }}
        response={Response}
        SmallWidget={SmallWidget(el)}
        onDateChange={(range) => handleDateChange(range)}
        defaultDate={{ start_date: el.start_date, end_date: el.end_date }}
        el={el}
        options={options}
        pin={() => pin()}
        info={() => info()}
        remove={() => remove()}
        DateToggle={(state: any, el: any) => {
          DateOpen && DateOpen(state, el);
        }}
        ShowDatePicker={
          Response && Response.config?.datepicker ? Response.config?.datepicker : false
        }
      />

      <div className={Response.info ? "Chart-data Chart-data-center" : "Chart-data"}>
        {customLegend(Response)}
        {!Loading && !Error && ChartRender(el)}
      </div>
    </div>
  );
}

import * as React from "react";
import { connect, useSelector } from "react-redux";
import {
  PageContainer,
  FormHeader,
  ImagePicker,
  DropDown,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import {
  profile,
  profileEdit,
} from "../../redux/actions/user.action";
import { useDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
import InputWrapper from "../../utilities/components/InputWrapper/InputWrapper";
import PasswordChange from "./ProfilePopup/PasswordChange";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";


export default function Account() {
  const dispatch = useDispatch();
  const [Data, setData] = useState<Record<string, any>>({});
  const [Error, setError] = useState("");

  const user = useSelector((state: RootState) => state.user.profile);
  const token = useSelector((state: RootState) => state.auth.token);
  const [image, setImage] = React.useState(null);
  const [BtnLoading, setBtnLoading] = React.useState(false);
  const [mobileError, setMobileError] = React.useState(false);
  const [PasswordChangeView, setPasswordChangeView] = React.useState(false);


  function mobileInputFixer(name: any, value: any) {
    if (
      name === "mobile_number" ||
      name === "mobile_cc" ||
      name === "phone_number"
    ) {
      value = "+" + value.replaceAll("+", "");
      if (value.length !== 12) {
        setMobileError(true);
      } else {
        setMobileError(false);
      }
    }
    if (value === "+") {
      setMobileError(false);

      value = " ";
    }
    return value;
  }
  function sort(array: any, KeySort: any) {
    return array.sort(function (a: any, b: any) {
      if (a[KeySort].name < b[KeySort].name) {
        return -1;
      }
      if (a[KeySort].name > b[KeySort].name) {
        return 1;
      }
      return 0;
    });
  }
  React.useEffect(() => {
    if (image !== null) {
      setData({
        ...Data,
        picture: image,
      });
    }
  }, [image]);

  function change(event: any) {
    const name = event.target.name;
    let value = event.target.value;
    // if(name === "groups"){

    // }
    value = mobileInputFixer(name, value);
    setData({
      ...Data,
      [name]: value,
    });
  }

  function read() {
    const token = `${localStorage.getItem("token")}`;
    dispatch(profile(token)).then((response: any) => {
      if (response.payload && response.payload) {
        setData(response.payload);
        if (response.payload.picture) {
          setImage(response.payload.picture);
        }
      }
    });

  }
  const [t] = useTranslation();

  function SaveRequest(yo: any) {
    setBtnLoading(true);
    const data = { ...yo };
    if (typeof data.picture !== "object") {
      data.picture = undefined; //Remove the image because it is a string
    }
    dispatch(profileEdit({ data, token })).then((response: any) => {
      setBtnLoading(false);
      dispatch(profile(token))
      if (response.payload && !response.payload.error) {
        setData(response.payload);
        if (response.payload.picture) {
          setImage(response.payload.picture);
          toast.success(t("account.saved"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setError("");
          setMobileError(false);
        }
      } else {
        setError(response.payload.errorMassage);
      }
    });
  }



  useEffect(() => {
    read();
  }, []);

  return (
    <PageContainer>
      <ToastContainer />
      <FormHeader text={t("account.profile")} />
      <div className={"ErrorMassage"}>{Error}</div>

      {PasswordChangeView && (
        <PasswordChange
          close={() => {
            setPasswordChangeView(false);
          }}
        />
      )}

      <div className="profile-fa ">
        <div className="modal-content-section d-flex flex-column">
          <div className="modal-account-account-detail d-flex">
            <div className="right-section col-4 bar-right-section">
              <ImagePicker
                label="Click to change photo"
                image={Data.picture ? Data.picture : undefined}
                imageFile={(image: any) => setImage(image)}
              />

              <div className={"profileSide"}>
                <div className={"profileSideItem"}>

                </div>
              </div>
            </div>
            <div className={"col-8 "}>
              <div className={"d-flex"}>
                <div className="col-6 ">
                  <div className="profileSideColTitle">
                    {t("account.company_details")}
                  </div>
                  <InputWrapper label={t("account.first_name") + ":"} important>
                    <input
                      name="first_name"
                      onChange={(value) => change(value)}

                      autoComplete="firstName"
                      value={Data && Data.first_name}
                      placeholder="First Name"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.last_name") + ":"} important>
                    <input
                      name="last_name"
                      onChange={(value) => change(value)}

                      autoComplete="lastName"
                      value={Data && Data.last_name}
                      placeholder="Last Name"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.email") + ":"} important>
                    <input
                      name="email"
                      onChange={(value) => change(value)}

                      autoComplete="email"
                      value={Data && Data.email}
                      placeholder="Email"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.phone") + ":"} important>
                    <input
                      name="mobile_number"
                      onChange={(value) => change(value)}
                      type="text"
                      pattern="[0-9]*"
                      className={mobileError ? "ErrorInputBorder" : ""}

                      autoComplete="phone"
                      value={
                        Data && Data.mobile_number ? Data.mobile_number : ""
                      }
                      placeholder="Mobile"
                    />
                  </InputWrapper>
                 
                </div>
                <div className="col-6 ">



                  <InputWrapper label={t("account.status") + ":"}>
                    <input
                      name="employee_status"
                      onChange={(value) => change(value)}
                      value={
                        Data && Data.employee_status ? Data.employee_status : ""
                      }

                      disabled={true}
                      placeholder="Status"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.role") + ":"}>
                    <input
                      name="role"
                      onChange={(value) => change(value)}
                      value={Data && Data.role ? Data.role : ""}
                      disabled={true}
                      autoComplete="role"
                      placeholder="Role"
                    />
                  </InputWrapper>
                  <div
                    className={"input-with-label-container profilePassChange"}
                  >
                    <small>Passwod:</small>
                    <Button
                      className="changePasswordBtn"
                      onClick={() => {
                        setPasswordChangeView(true);
                      }}
                    >
                      Change Password
                    </Button>
                  </div>


                </div>
              </div>

              <LoadingButton
              className={"updateBtn"}
              loading={BtnLoading}
              onClick={() => {
                SaveRequest(Data);
              }}
              text={t("account.Update")}
              icon={"/assets/icons/icon-basic-refresh.svg"}
            /> </div>
          </div>


        </div>
      </div>


    </PageContainer>
  );
}

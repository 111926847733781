import validator from "validator";

export function loginValidator(data) {
  if (validator.isEmpty(data.password)) {
    return false;
  }
  if (validator.isEmpty(data.username)) {
    return false;
  }
  return true;
}

export function signUpValidator(data) {
  if (validator.isEmpty(data.password1)) {
    return false;
  }
  if (validator.isEmpty(data.password2)) {
    return false;
  }
  if (data.password2 !== data.password1) {
    return false;
  }
  if (!validator.isEmail(data.email) || validator.isEmpty(data.email)) {
    return false;
  }
  return true;
}

export function resetPasswordValidator(data) {
  if (!validator.isEmail(data.email) || validator.isEmpty(data.email)) {
    return false;
  }
  return true;
}
export function EmailValidator(data) {
  if (!validator.isEmail(data.email) || validator.isEmpty(data.email)) {
    return false;
  }
  return true;
}
export function MobileValidator(data) {
  if (validator.isEmpty(data.phone_number)) {
    return false;
  }
  return true;
}

export function restPasswordValidator(data) {
  if (data.new_password1 !== data.new_password2) {
    return false;
  }
  return true;
}

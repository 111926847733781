import { combineReducers } from "redux";
import auth from "./auth.reducer";
import param from "./param.reducer";
import menu from "./menu.reducer";
import table from "./table.reducer";
import user from "./user.reducer";

const rootReducer = combineReducers({
  param,
  table : table.reducer,
  user : user.reducer,
  auth: auth.reducer,
  menu: menu.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

import * as React from "react";
import { DateRangePicker } from "react-date-range";
import { enGB, de } from "date-fns/locale";
import staticRangesGenerator from "./staticRanges";
import Moment from "moment";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from "date-fns";
export default function DatePicker({ onDateChange, defaultData, t }) {
  const staticRanges = staticRangesGenerator(de, t);

  function defultDataSelector(){
    let res = {
      key: "selection",
      
    }


    console.log("defaultData",defaultData)

    if(!defaultData.start_date || defaultData.start_date === 'Invalid date'){
      res.startDate = new Date('2022-01-01')
    }else{
      res.startDate =  new Date(defaultData.start_date) 
    }
    
    if(!defaultData.end_date || defaultData.end_date === 'Invalid date'){
      res.endDate = new Date('2022-01-01')
    }else{
      res.endDate = new Date(defaultData.end_date)
    }
    
    return [res]
  
  }

  const [DateSelected, setDateSelected] = React.useState(defultDataSelector());

  const handleDateChange = (item) => {
    setDateSelected([item.selection]);
    if (onDateChange) {
      onDateChange({
        start_date: Moment(item.selection.startDate).format("YYYY-MM-DD"),
        end_date: Moment(item.selection.endDate).format("YYYY-MM-DD"),
      });
      // onDateChange({
      //   start_date: addDays(new Date(item.selection.startDate), 1)
      //     .toISOString()
      //     .split("T")[0],
      //   end_date: addDays(new Date(item.selection.endDate), 1)
      //     .toISOString()
      //     .split("T")[0],
      // });
    }
  };

  return (
    <>
      <DateRangePicker
        toISOString={false}
        maxDate={Moment().toDate()}
        locale={localStorage.getItem("lang") === "de" ? de : enGB}
        staticRanges={staticRanges}
        onChange={(item) => handleDateChange(item)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        minimumNights={0}
        ranges={DateSelected}
        inputRanges={[]}
        direction="horizontal"
       
      />
    </>
  );
}


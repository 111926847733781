import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { companyVehicles,damages } from "../../redux/actions/table.action";
import { LoadingView } from "./../../utilities/components/Loading";
import {FormHeader,InputWithIcon} from "../../utilities/components/FromUtils";
import Damages from './Damages'
import DamageDetails from './DamageDetails'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DefaultParam = {
  offset: 0,
  limit: 10,
  search: false,
  ordering: false,
  according: false,
};

export default function ReportDamages() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [Loading, setLoading] = React.useState(false);
  const [DamagesView, setDamagesView] = React.useState(false);
  const [Vehicle, setVehicle] = React.useState<any>({});
  const [DamageDetailsView, setDamageDetailsView] = React.useState(false);
  const [Data, setData] = React.useState([]);
  const [DamagesData, setDamagesData] = React.useState([]);
  const [Damage, setDamage] = React.useState({});

  


  useEffect(() => {
    dispatch(companyVehicles(DefaultParam)).then((response: any) => {
      if (!response.payload.error) {
        setData(response.payload.results)
      }
    });
    
    dispatch(damages()).then((response: any) => {
      if (!response.payload.error) {
        setDamagesData(response.payload.results)
      }
    });
  }, []);



  useEffect(() => {
    if(Vehicle?.id){
      setDamage({vehicle:Vehicle})
      setDamageDetailsView(true)
    }
  }, [Vehicle]);


  function DamageLists(vehicle:any){
    let ds = []
    // DamagesData.map((d:any)=>{
    //   if(d.vehicle && d.vehicle === vehicle.id){
    //     ds.push(<span className={"rowSpan"}></span>)
    //   }
    // })
  }
  

  return (
    <div className="main-container d-flex">
      <ToastContainer />
      
      
      {DamagesView && <Damages selectDamage={(d:any)=>{setDamage(d)}} close={()=>{setDamagesView(false)}}/>}
      {DamageDetailsView && <DamageDetails Damage={Damage} close={()=>{setDamageDetailsView(false)}}/>}
      {Loading && <LoadingView />}
      <div className="settingView">

        <FormHeader text={"Report Damages"} />
        <div className="ReportDamagesButton">
          <button onClick={()=>{setDamagesView(true)}} >Damages List</button>
        </div>
        <div className="vehiclesItems">
          {Data.map((v: any, key: any) => (
            <div className="vehiclesItem" onClick={()=>{setVehicle(v)}}>
              <div className="img"><img src={v.vehicle_image ? v.vehicle_image : v.vehicle_type.vehicle_image} /></div>
              <div className="name">{v.name} - {v.vehicle_type.name}</div>
              <div className="license">{v.license}</div>
              {/* {DamageLists(v)} */}
            </div>

          ))}

        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import { Link,useParams ,useLocation} from 'react-router-dom'
import Header from './HeaderLayout'
function Confirm() {
  const [Res, setRes] = useState(null);
  const prop = useParams()
  const loc = useLocation();
  const queryString = require("query-string");

  async function send(key) {
        const formData = new FormData();
        formData.append('key', key);
        await fetch('https://staging-api.fetch-mobility.com/webcontact/confirm/', {
          method: 'POST',        
          body : formData
        }).then(async function (response) {
          if (response.ok) {
            setRes(true)
          }else{
            setRes(false)
          }
          console.log("response", response)
        }).catch((er) => {
          console.log("er", er)
       
        });
   
  }
  useEffect(()=>{
      const query = queryString.parse(loc && loc.search);
      //const data = { key: query.token };
      send(query.token)
  },[])
  return (<>
    <Header/>
    <div className='response'>
    {Res !== null && <div className={Res ? 'alertFetch' : 'alertFetch alert-danger'}>
      {Res ? <div><span>Thank you.</span> Your email address has been confirmed.</div> : <div><span>Cannot verify this email !</span></div>}
    </div>
    }
    </div>
  </>);
}
export default Confirm;

import {useState} from "react";
import { useImmer } from "use-immer";
import { Link, useParams,useNavigate } from "react-router-dom";
import {
  InputWithIcon,
  CheckboxWithTitle,
  LoadingButton,
} from "../../utilities/components/FromUtils";

import { ResetConfirm } from "../../redux/actions/auth.action";
import { restPasswordValidator } from "../../utilities/functions/authValidator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function RestPassword() {
  const navigate = useNavigate()

  const prop: any = useParams();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [state, setState] = useImmer({
    new_password1: "",
    new_password2: "",
    uid: "",
    token: "",
    valid: "",
  });
  const [Loading, setLoading] = useState(false);

  const validate = (data: any) => {
    setState((state) => {
      state.valid = "";
    });
    if (restPasswordValidator(data)) {
      return true;
    } else {
      setState((state) => {
        state.valid = t("auth.credentials_not_valid");
      });
      return false;
    }
  };

  const RestPassword = () => {
    const data = {
      new_password1: state.new_password1,
      new_password2: state.new_password2,
      uid: prop.uid,
      token: prop.token,
    };
    //if (validate(data)) {
    dispatch(ResetConfirm(data)).then((response: any) => {
      setLoading(false);

      if (
        response.payload &&
        response.payload.detail &&
        !response.payload.error
      ) {
        const title = t("auth.password_reseted");
        toast.success(title, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setTimeout(() => {
          window.location.replace("https://fetchcar.io/");
        }, 3000);
      } else {
        setState((state) => {

          state.valid = response.payload.errorMassage.replaceAll("2", "");
        });
      }
    });
    // }
  };
  const errorBuilder = (error:any) => {
    let newError:any = error ;
    newError = newError.split('. ')
    newError.pop();
    let erHtml: any = null;
    erHtml = newError.map((row: any, key: any) => {
        const res = (
          <li
            key={key}
            className="errorRowPass"
          >
            {row}
          </li>
        );
        return res;
      });
    return <div className="">{erHtml}</div>;
  }

  return (
    <div className="main-container  main-auth-container d-flex">
      <ToastContainer />
      
      <div data-testid={"login-form"} className="login-form-section full-section d-flex justify-content-center align-items-center fadeIn">
        <div className="login-form-inputs-container d-flex justify-content-center flex-column">
          <div className="login-form-inputs-header">
            <img
              className="login-form-inputs-header-img"
              src={"/assets/logo/logo.png"}
              alt="fetch-logo"
            />
          </div>

          <small className="signInText text-center">
            {t("home.rest-password")}
          </small>

          <InputWithIcon
            value={state.new_password1}
            data-testid="username"
            placeholder={t("auth.new_password")}
            type={"password"}
            pngSource={"/assets/icons/Padlock2x.png"}
            onChange={(username: string) =>
              setState((state) => {
                state.new_password1 = username;
              })
            }
          />
          <InputWithIcon
            value={state.new_password2}
            placeholder={t("auth.repeat_new_password")}
            pngSource={"/assets/icons/Padlock2x.png"}
            type={"password"}
            onChange={(username: string) =>
              setState((state) => {
                state.new_password2 = username;
              })
            }
          />

          {state.valid && (
            <div style={{ width: "100%", display: "block" }}>
              <div style={{ color: "red", fontSize: "21px",fontWeight:'bold' }}>Credentials Invalid</div>
              <span style={{ color: "red", fontSize: "11px" }}>
                {errorBuilder(state.valid)}
              </span>
            </div>
          )}
          <div className="login-button">
            <LoadingButton
              loading={Loading}
              onClick={() => {
                setLoading(true);

                RestPassword();
              }}
              text={t("home.rest-password")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import Paginate from "react-paginate";
import { PageContainer, FormHeader, InputWithIcon } from "../FromUtils";
import ColFilter from "./ColFilter";
import ColEdit from "./ColEdit";
import EditViewComponent from "./EditView";
import CreateViewComponent from "./CreateView";
import { LoadingTable } from "../../../utilities/components/Loading";
import parsePhoneNumber from 'libphonenumber-js'
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
interface Props {
  tableData: Record<string, any>;
  tableParent?: string | "";
  title?: string | "";
  TableRowsData?: Record<string, any>;
  columns?: Array<any>;
  colsOrder?: Array<any>;
  tableType: string | "";
  editable?: boolean | false;
  creatable?: boolean | false;
  customEdit?: boolean | false;
  ActionResult?: any;
  getData: (Params: Record<string, any>) => void | undefined;
  Edit?: (Params: Record<string, any>) => void | undefined;
  clearResult?: () => void | undefined;
  CreateItem?: (data: Record<string, any>) => void | undefined;
  EditItem?: (data: Record<string, any>) => void | undefined;
  DeleteRow?: (data: Record<string, any>) => void | undefined;
  TagSave?: (user_id: any, tag: any) => void | undefined;
  rowSelect?: (Params: Record<string, any>) => void | undefined;
}

export default function Table({
  customEdit,
  Edit,
  tableData,
  tableType,
  title,
  TableRowsData,
  CreateItem, colsOrder,
  DeleteRow,
  EditItem,
  editable,
  creatable,
  getData,
  tableParent,
  ActionResult,
  clearResult,
  TagSave, rowSelect,
  columns,
}: Props) {
  const [Offset, setOffset] = React.useState(0);
  const [propChange, setpropChange] = React.useState(0);
  const [PageCount, setPageCount] = React.useState(0);
  const [AllCount, setAllCount] = React.useState(0);
  const [Search, setSearch] = React.useState<any>(null);
  const [Ordering, setOrdering] = React.useState("");
  const [OrderingWidget, setOrderingWidget] = React.useState("");
  const [According, setAccording] = React.useState("+");
  const [WidgetAccording, setWidgetAccording] = React.useState("+");
  const [Data, setData] = React.useState<Array<Record<string, any>>>([]);
  const [Cols, setCols] = React.useState<Array<string>>([]);
  const [SelectedRows, setSelectedRows] = React.useState<Array<string>>([]);
  const [SelectedAll, setSelectedAll] = React.useState(false);
  const [EditData, setEditData] = React.useState<Record<string, any>>({});
  const [FilterCol, setFilterCol] = React.useState<Array<string>>([
    "id",
    "company",
  ]);
  const [CurrentPage, setCurrentPage] = React.useState(0);
  const [Limit, setLimit] = React.useState(20);
  const [LimitOpen, setLimitOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [ComponentLoad, setComponentLoad] = React.useState(false);
  const [EditView, setEditView] = React.useState(false);
  const [CreateView, setCreateView] = React.useState(false);
  const [MultipleRow, setMultipleRow] = React.useState(false);
  const [ColFilterView, setColFilterView] = React.useState(false);
  const [Loading, setLoading] = React.useState<any>(false);
  const [ColEditView, setColEditView] = React.useState<number>();

  const [t] = useTranslation();


  async function insertRows(response: any) {
    let data: any = [];
    setLoading(false)
    if (response) {

      if (response?.results) {
        data = response.results;
      } else {
        data = response;
      }
      if (data && data.length > 0) {
        const keys = Object.keys(data[0]);
        if (columns) {
          let dataArray: any = [];
          setCols(columns);
        } else {
          if (colsOrder) {
            setCols(colsOrder);
          } else {
            setCols(keys);
          }

        }

        setData(data);
        setPageCount(Math.ceil(response.count / Limit));
        setAllCount(response.count);


      } else {

        setData([]);
        setPageCount(0);
        setAllCount(0);
      }
    }
  }



  async function request(searching?: any) {
    const Params = {
      offset: !searching ? Offset : 0,
      limit: Limit,
      search: Search,
      ordering: Ordering,
      according: According,
    };
    return getData(Params);
  }

  async function HandlePageClick(e: any) {
    const selectedPage = e.selected;
    const offset = selectedPage * Limit;
    await setCurrentPage(selectedPage);
    await setOffset(offset);
    const Params = {
      offset: offset,
      limit: Limit,
      search: Search,
      ordering: Ordering,
      according: According,
    };
    return getData(Params);
  }

  React.useEffect(() => {
    setLoading(false)
    const filters = localStorage.getItem(tableType + "_filter");
    if (filters) {
      setFilterCol(JSON.parse(filters));
    } else {
      setFilterCol(["company", "id"]);
    }

    insertRows(tableData);
  }, [tableData]);
  React.useEffect(() => {
    localStorage.setItem(tableType + "_filter", JSON.stringify(FilterCol));
  }, [FilterCol]);


  React.useEffect(() => {
    setLoading(true)

    if (load) {
      request();
    }
    setLoad(true)

  }, [propChange]);


  React.useEffect(() => {
    if (Search !== null) {
      setOffset(0);
      setLoading(true)
      const timeout = setTimeout(() => {
        request(true);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [Search]);

  function selectAll() {
    if (SelectedAll) {
      setSelectedRows([]);
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
      const newIds = Data.map((el) => el.id);
      setSelectedRows(newIds);
    }
  }

  function selectRow(val: string) {
    if (!SelectedRows.includes(val)) {
      setSelectedRows([...SelectedRows, val]);
    } else {
      setSelectedRows(SelectedRows.filter((item) => item !== val));
    }
  }

  function search(key: string) {
    key = key.toLowerCase();
    setSearch(key);
  }

  function changeLimit(val: any) {
    setLimit(Number(val));
    setpropChange(propChange + 1)
  }

  function editedData(data: any) {
    EditItem && EditItem(data);
  }

  function deleteItem(data: any) {
    DeleteRow && DeleteRow(data);
  }

  function CreateRequest(data: any) {
    CreateItem && CreateItem(data);
  }

  function filterCol(val: string) {
    if (!FilterCol.includes(val)) {
      setFilterCol([...FilterCol, val]);
    } else {
      setFilterCol(FilterCol.filter((item) => item !== val));
    }
  }

  function OrderChecker(col: any) {
    if (tableParent === "Widget") {
      if (OrderingWidget === col) {
        return true;
      } else {
        return false;
      }
    } else {
      if (Ordering === col) {
        return true;
      } else {
        return false;
      }
    }
  }

  function sortBy(col: any) {
    if (tableParent === "Widget") {
      const sortData =
        According === "+"
          ? [...Data].sort((a: any, b: any) => (a[col] > b[col] ? 1 : -1))
          : [...Data].sort((a: any, b: any) => (a[col] < b[col] ? 1 : -1));
      setData(sortData);
      setOrderingWidget(col);
    } else {
      setOrdering(col);
      setAccording(According === "+" ? "-" : "+");
      setpropChange(propChange + 1)
    }
    setWidgetAccording(WidgetAccording === "+" ? "-" : "+");
  }


  function secondsToHms(d: any) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  function CustomColsGenerator(data: any, key: any, index: number) {
    if (key === "status") {
      return (
        <div className={"flexCol"}>
          <span className={"rowSpan  table_span_status table_span_" + data}>{data}</span>
          {/* {data === "active" && <i className={"vehicleStatus active table_span_" + data } />}
          {data === "deactivate" && (
            <i className={"vehicleStatus deactivate"} />
          )}
          {data === "pending" && <i className={"vehicleStatus pending"} />}
          {data} */}
        </div>
      );
    }

    if (key === "color") {
      return (
        <div className={"flexCol"}>
          <div className={"color-item"} style={{ background: data }}></div>
        </div>
      );
    }

    if (key === "vehicle_brand") {
      return <div className={"flexCol"}>{data ? data.name : " - "}</div>;
    }
    if (key === "vehicle") {
      return <div className={"flexCol"}>
        <img src={data.vehicle_image ? data.vehicle_image : data.vehicle_type?.vehicle_image} />
        {data ? data.name : " - "}</div>;
    }
    if (key === "book_for") {
      let classBookFor = 'tableTag';
      if (Math.round(Moment(data).diff(Moment(), "days", true)) === 0) {
        classBookFor += ' tableTagToday'
      }
      if (Moment().isBefore(Moment(data), "days")) {
        classBookFor += ' tableTagTomaroow'
      }
      classBookFor += " bookfor_" + Data[index].status;


      return (
        <div className={"flexCol"}>
          <span className={classBookFor}>
            {Moment(data).format("ddd , D MMM YYYY hh:mm a")}
          </span>
        </div>
      );
    }
    if (
      key === "drop_off_booking" ||
      key === "updated_on" ||
      key === "created_on" ||
      key === "drop_off_datetime" ||
      key === "start_at" ||
      key === "expire_at" ||
      key === "end_at" ||
      key === "requested_at" ||
      key === "last_reported"
    ) {
      return (
        <div className={"flexCol"}>
          {data ? Moment(data).format("ddd , D MMM YYYY hh:mm a") : " - "}

        </div>
      );
    }

    if (key === "obj_groups") {
      return (
        <div className={"flexCol"}>
          <div className={"row-profile-item"}>
            {data &&
              data[0] &&
              data.map((tag: any, key: number) => (
                <span key={key} className={"spanTagTable"}>
                  {tag}
                </span>
              ))}
          </div>
        </div>
      );
    }
    if (key === "picture" || key === "vehicle_image") {
      return (
        <div className={"flexCol"}>
          <div className={"row-profile-item"}>
            <img alt={""} src={data} className={key === "vehicle_image" ? 'vehilceImageIconTable' : 'userImageTabele'} />
          </div>
        </div>
      );
    }


    if (key === "vehicle_type") {
      return (
        <div className={"flexCol"}>
          {data.name} - {data.body_configuration_type}
          <div className={"row-vehicle-icon"}>
            {data ? <img src={data.vehicle_brand_image} alt={""} /> : "-"}
          </div>
        </div>
      );
    }

    if (key === "telematics_device") {
      return (<div>{data && data.name}</div>)
    }

    if (key === "dispatcher_driver" || key === "safety_driver") {
      return (data && <div>
        <img src={data?.picture} className="vehicleThumb userImageTabele" />

        {data?.first_name + " " + data?.last_name}
      </div>)
    }


    if (key === "booking") {
      return (data &&
        <div className={"flexCol"}>
          <div>
            {data?.vehicle?.vehicle_image ? <img src={data?.vehicle?.vehicle_image} className="vehicleThumb vehilceImageIconTable" /> : <img src={data?.vehicle.vehicle_type?.vehicle_image} className="vehicleThumb vehilceImageIconTable" />}
            {data?.vehicle?.name} - {data && data?.vehicle?.license}
          </div>

        </div>
      );
    }

    if (
      key === "location_point" ||
      key === "user_address" ||
      key === "user_location" ||
      key === "destination_location" ||
      key === "start_location" ||
      key === "requested_location" ||
      key === "end_location"
    ) {
      return (
        data &&
        data.coordinates && (
          <a
            target="_blank"
            href={
              "http://maps.google.com/maps?q=" +
              data.coordinates[1] +
              "%2C" +
              data.coordinates[0]
            }
          >
            [{data.coordinates[0].toFixed(2)},{data.coordinates[1].toFixed(2)}]
          </a>
        )
      );
    }


    if (key === "pricing_plan") {
      return (
        <div className={"flexCol"}>
          <div className={""}>
            {data?.currency}, {data?.name} <br />
            price : {data?.price}
          </div>
        </div>
      );
    }


    if (key === "trip") {
      return (
        <div className={"flexCol"}>
          <div className={"tableTripTd"}>
            Status :  {data?.status} <br />
            Distance meter :  {data?.distance_meter} <br />
            Duration min :  {data?.duration_min} <br />
            Vehicle is locked :  {data?.vehicle_is_locked} <br />
          </div>
        </div>
      );
    }


    if (key === "delivery") {
      return (
        <div className={"flexCol"}>
          <div className={""}>
            status :  {data?.status} <br /> start address :  {data?.start_address}
          </div>
        </div>
      );
    }
    if (key === "stripe_invoice_pdf") {
      return (
        <div className={"flexCol"}>
          <div className={""}>
            {data ? <a href={data} target={"_blank"} ><img src={'/assets/icons/PDF_file_icon.png'} alt={""} />PDF</a> : "-"}
          </div>
        </div>
      );
    }
    if (key === "icon" || key === "selfie_with_license" || key === "front_license_card" || key === "back_license_card") {
      return (
        <div className={"flexCol"}>
          <div className={"row-vehicle-icon"}>
            {data ? <img src={data} alt={""} /> : "-"}
          </div>
        </div>
      );
    }
    if (key === "id") {
      return <div className={"flexCol"}>{index + 1 + Offset}</div>;
    }
    if (key === "user") {
      return <div className={"flexCol"}>
        <div>
          {data?.picture && <img src={data?.picture} className="vehicleThumb userImageTabele" />}
          {data?.first_name} {data?.last_name} <br />
          {data?.email} <br />
          {data?.mobile_number}
        </div>

      </div>;
    }

    if (columns) {
      const cCol = key;
      const splitObject = cCol.key.split("+");
      let object: any = {};
      let res: any = [];
      //  if(testKey?.returntobase?.booking){
      //   mainKeyForBooking = 'returntobase'
      // }
      // console.log("mainKeyForBooking",mainKeyForBooking)
      // if(testKey?.delivery?.booking){
      //   mainKeyForBooking = 'delivery'
      // }
      // if(testKey?.valetparking?.booking){
      //   mainKeyForBooking = 'valetparking'
      // }
      let card = false

      splitObject.map((keyObject: any, x: any) => {
        const splitKey = keyObject.split(".");
        splitKey.map((keyname: any, i: any) => {


          if (i === 0) {
            if (typeof Data[index][keyname] === 'object') {
              object = Data[index][keyname];
              if (!object) {
                if (keyname === 'delivery') {
                  
                  if (Data[index]['returntobase']) {
                    object = Data[index]['returntobase']
                  } 
                  if (Data[index]['valetparking']) {
                    object = Data[index]['valetparking']
                  }
                }
              }
            } else {
              if (keyname === 'status' || keyname === "license_verification") {
                res.push(<span className={"rowSpan" + " " + " table_span_status table_span_" + Data[index][keyname] + ""}>{Data[index][keyname]}</span>);
              } else if (keyname === "start_at") {
                const date = Moment(Data[index][keyname]).format("ddd , D MMM YYYY hh:mm a");
                res.push(date !== "Invalid date" ? date : '-')
              } else {
                res.push(Data[index][keyname])
              }
            }
          } else if ((splitKey.length - 1) === i) {
            if (object) {
              if (keyname === "date_joined" || keyname === "book_for" || keyname === "last_booking" || keyname === "start_at") {
                const date = Moment(object[keyname]).format("ddd , D MMM YYYY hh:mm a");
                res.push(date !== "Invalid date" ? date : '-')
              } else if (keyname === "delivery_time_in_seconds") {
                res.push(object[keyname] ? secondsToHms(object[keyname]) : "-")
              } else if (keyname === "distance_by_car_in_meters") {
                res.push(object[keyname] ? (object[keyname]) / 1000 + ' KM' : "-")
              } else if (keyname === 'picture') {
                // res.push(<div className="userCardInfo"><img src={object[keyname]} /></div>);
              } else if (keyname === 'last_name' || keyname === 'mobile_number') {

              } else if (keyname === 'first_name') {
                card = true;

                res.push(
                  <div className="userCardInfo">
                    <div className="userCardImgBox"><img src={object.picture} /></div>
                    <div className="userCardDataBox">
                      <span className={"spanString"}>{object.first_name} {object.last_name}
                        {cCol.name === 'safty' || cCol.name === 'teleop' ? <div className="userCardfetch"><img src={'/assets/icons/fetch_icon.png'} /></div> : ''}</span>

                      <span className={"spanString"}>{parsePhoneNumber(object.mobile_number)?.format("INTERNATIONAL")}{object.verified_mobile_number ? <div className="userCardVerified"><img src={'/assets/icons/verified.png'} /></div> : ''} </span>
                      <span className={"spanString"}>{object.email}</span>
                    </div>
                  </div>);
              } else if (keyname === 'vehicle_image') {
                res.push(<><img className="vehilceImageIconTable" src={object[keyname] ? object[keyname] : object.vehicle_type?.vehicle_image} /><div className="imageSpace"></div></>);
              } else if (keyname === 'status' || keyname === "license_verification") {
                res.push(
                  <div className="statusParent">
                    <span className={"rowSpan" + " " + "table_span_status table_span_" + object[keyname] + ""}>{object[keyname]}</span>
                  </div>
                );
              } else if (keyname === 'notes' || keyname === "address") {
                res.push(<span className={"spanString"}>{(object[keyname])?.substring(0, 200)} {(object[keyname])?.length > 200 ? '...' : ''}</span>);
              } 
              else if (keyname === 'num_bookings') {
                const num = object[keyname];
                res.push(<span className={num > 0 ? "num_bookings num_bookings_green" : "num_bookings "}>{num}</span>);
              } 
              else if (keyname === 'num_bookings') {
                const num = object[keyname];
                res.push(<span className={num > 0 ? "num_bookings num_bookings_green" : "num_bookings "}>{num}</span>);
              }else {
            
                object[keyname] ? res.push(<span className={"rowSpan maxWidth"}>{object[keyname]} </span>) : res.push('-')
              }
            }
          } else {
            if (object && object[keyname]) {
              object = object[keyname];
            } else {
              object = null
            }
          }
        });
      });

      if (card) {
        return (<div className={cCol.name === 'safty' || cCol.name === 'teleop' ? "userTableCard userCardInfoFetch" : "userTableCard"}>{res}</div>);

      } else {
        return res;

      }

    }

    if (data === null) {
      return " - ";
    }
    if (data === true) {
      return "Yes"
    }
    if (data === false) {
      return "No"
    }
    if (typeof data !== "object") {
      return data ? data : " - ";
    } else {
      return "Object Given";
    }
  }

  function idSelector(row: Record<string, any>) {
    if (row) {
      if (row.user) {
        return row.user;
      } else {
        return row.id;
      }
    }
  }

  function editPopup(data: any, index: any) {
    if (SelectedRows.length <= 1) {
      setMultipleRow(false);
    } else {
      setMultipleRow(true);
    }
    setEditData(data);
    setColEditView(index);
  }

  function colGeneratorName(key: any) {
    const col = columns ? key.sort_key ? key.sort_key : key.name : key;
    const name = columns ? key.name.replaceAll("_", " ").replaceAll(".", " ") : col.replaceAll("_", " ").replaceAll(".", " ");
    // if(col === "vehicle.vehicle_type.model+vehicle.vehicle_type.make"){
    //   name = "Model & Make"
    // }

    // if(col === "vehicle.name"){
    //   name = "Vehicle"
    // }

    // if(col === "user.first_name+user.last_name"){
    //   name = "User"
    // }

    // if(col === "vehicle.license"){
    //   name = "Number Plate"
    // }
    const sortKeys = ["user", "name", "current_range_meters", "last_reported", "vehicle", "vehicle_type", "location_address", "license_verification", "created_on", "updated_on", "book_for",
      "end_at", "drop_off_datetime", "status", "type", "user_location", "user_address", "start_location", "start_address", "end_location", "end_address",
      "stripe_invoice_pdf", "expire_at", "pricing_plan", "stripe_invoice_total_cost", "stripe_invoice_cost_unit", "delivery", "trip", "booking", "start_at", "dispatcher_driver",
      "safety_driver", "first_name", "last_name", "email", "date_joined", "first_name", "last_booking", "num_bookings", "balance", "booking__book_for","coupon",
      "booking__vehicle__name", "total_charges"
      , "vehicle__name"];
    let sortableKey = null;
    if (sortKeys.includes(col.replaceAll("user__", "").replaceAll("booking__", ""))) {
      sortableKey = col;
    }
    if (sortableKey) {
      return (<>{name}<div
        className={"sortByItem"}
        onClick={() => {
          sortBy(col);
        }}
      >
        {OrderChecker(col) ? (
          WidgetAccording === "+" ? (
            <img
              alt={""}
              src={"/assets/icons/sortByArrow.svg"}
            />
          ) : (
            <img
              alt={""}
              src={"/assets/icons/sortByArrowUp.svg"}
            />
          )
        ) : (
          <img
            alt={""}
            src={"/assets/icons/sortByArrow.svg"}
          />
        )}
      </div></>)

    } else {
      return (name);
    }
  }


  function confirmation() {
    clearResult && clearResult();
    if (ActionResult) {
      if (ActionResult.payload && !ActionResult.payload.error) {
        const title = ActionResult.delete
          ? t("table.deleted")
          : t("account.saved");
        toast.success(title, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  }

  return (
    <>
      {EditView && (
        <EditViewComponent
          Title={title ? title : tableType}
          Type={tableType}
          ActionResult={ActionResult}
          save={(data: any) => editedData(data)}
          TableRowsData={TableRowsData ? TableRowsData : []}
          RowData={EditData}
          deleteRow={(data: any) => deleteItem(data)}
          close={() => {
            setEditView(false);

            confirmation();
          }}
        />
      )}
      {CreateView && (
        <CreateViewComponent
          Title={title ? title : tableType}
          Type={tableType}
          ActionResult={ActionResult}
          save={(data: any) => CreateRequest(data)}
          TableRowsData={TableRowsData ? TableRowsData : []}
          close={() => {
            setCreateView(false);
            confirmation();
          }}
        />
      )}
      <div
        className={"page-container d-flex flex-column table-page-container"}
      >

        <ToastContainer />
        <FormHeader text={title && title.replaceAll("_", " ").replaceAll("WITH", " ")} />
        <div className={"tableSearch"}>
          <div className="search-input-table">
            <InputWithIcon
              value={Search}
              placeholder={t("table.Search")}
              pngSource={"/assets/icons/icon-basic-search.svg"}
              onChange={(key: string) => search(key)}
            />
          </div>
          {creatable && (
            <div
              onClick={() => setCreateView(true)}
              className={"createBtn"}
            ></div>
          )}
        </div>
        <div className={tableType.includes("filter") ? "tableViewContainer customScrollBar " : "tableViewContainer customScrollBar containerTable"}>
          <div className="tableContainer ">
            {Loading && <LoadingTable />}

            {load && Data.length ? (
              <table className="table tableVehicle">
                <thead>
                  <tr>
                    {/* <th scope="col">
                      <input
                        type="checkbox"
                        onClick={() => selectAll()}
                        checked={SelectedAll}
                      />
                    </th> */}
                    {Cols.map(
                      (col, index) =>
                        !FilterCol.includes(col) && (
                          <th scope="col" key={index}>
                            <div className={"thCol"}>
                              {colGeneratorName(col)}

                            </div>
                          </th>
                        )
                    )}
                    {!rowSelect && <th scope="col" className={"thDetails "}>
                      <div
                        className={"colDetails"}
                        onClick={() => {
                          setColFilterView(!ColFilterView);
                        }}
                      >
                        <img src="/assets/icons/dotsLight.svg" />
                      </div>
                      {ColFilterView && (
                        <div className={"colEditPopup"}>
                          <ColFilter
                            close={() => setColFilterView(false)}
                            filter={(val: any) => filterCol(val)}
                            cols={Cols}
                            active={FilterCol}
                          />
                        </div>
                      )}
                    </th>}
                  </tr>
                </thead>
                <tbody>
                  {Data.map((data, index) => (
                    <tr
                      className={
                        SelectedRows.includes(idSelector(data))
                          ? "tableRow tableRowActive"
                          : "tableRow"
                      }
                      onClick={() => {
                        rowSelect && rowSelect(data);
                      }}

                      key={index}
                    >
                      {/*<th scope="row"><input type="checkbox" value={data.id}/></th>*/}
                      {/* <th scope="row">
                        <input
                          type="checkbox"
                          onChange={() => selectRow(idSelector(data))}
                          checked={SelectedRows.includes(idSelector(data))}
                        />
                      </th> */}

                      {Cols.map((key, indexKey) => (
                        <>
                          {!FilterCol.includes(key) && (
                            <td key={indexKey}>
                              {data &&
                                CustomColsGenerator(data[key], key, index)}
                            </td>
                          )}
                        </>
                      ))}
                      {!rowSelect &&
                        <td className={"thDetails"}>
                          {tableParent !== "Widget" && (
                            <div
                              className={"flexCol colDetailsFa"}
                              onClick={() => {
                                editPopup(data, index);
                              }}
                            >
                              <div className={"colDetails"}>
                                <img src="/assets/icons/dotsLight.svg" />
                              </div>
                              {ColEditView === index ? (
                                <div className={"colEditPopup"}>
                                  <ColEdit
                                    multiple={MultipleRow}
                                    close={() => {
                                      editPopup({}, -1);
                                    }}
                                    edit={() => {
                                      editPopup({}, -1);

                                      if (customEdit) {
                                        Edit && Edit(data)
                                      } else {
                                        setEditView(true)
                                      };

                                      setColEditView(-1);
                                    }}
                                    SelectedRows={SelectedRows}
                                    group={(tag: any) => {
                                      TagSave && TagSave(SelectedRows, tag);
                                    }}
                                    cols={Cols}
                                    data={Data}
                                    editable={editable ? editable : false}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (<div className={"emptyTable"}>{t("table.no_data_available")}</div>)}
          </div>
        </div>
        {tableParent !== "Widget" && (
          <div className={"tableFooter"}>
            <div className={"tableFooterDiv showRows"}>
              <span>{t("table.Show")} :</span>
              <div
                className={"customSelect"}
                onClick={() => {
                  setLimitOpen(!LimitOpen);
                }}
              >
                <div className={"customSelectValue"}>
                  {Limit}
                  <img src={"/assets/icons/SelectArrowDown.svg"} />
                </div>
                <div
                  className={
                    LimitOpen
                      ? "customSelectOptionsView customSelectOptionsViewShow"
                      : "customSelectOptionsView"
                  }
                >
                  <div
                    onClick={() => {
                      changeLimit(10);
                    }}
                  >
                    10
                  </div>
                  <div
                    onClick={() => {
                      changeLimit(20);
                    }}
                  >
                    20
                  </div>
                  <div
                    onClick={() => {
                      changeLimit(30);
                    }}
                  >
                    30
                  </div>
                  <div
                    onClick={() => {
                      changeLimit(40);
                    }}
                  >
                    40
                  </div>
                </div>
              </div>
            </div>

            <div className={"tableFooterDiv itemsCount"}>
              {t("table.Showing")} {Offset} {t("table.To")}{" "}
              {Offset + Limit > AllCount ? AllCount : Offset + Limit}{" "}
              {t("table.of")} {AllCount} {t("table.Items")}
            </div>
            <div className={"tableFooterDiv paginationDiv"}>
              <Paginate
                previousLabel={""}
                nextLabel={""}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={PageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={(v) => HandlePageClick(v)}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

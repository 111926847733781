import React, { useEffect } from "react";
import { useDispatch } from "../../redux/store";
import {
  VehiclesList, RecalculateRoute,
} from "../../redux/actions/vehicle.action";
import {
  bookings, bookingEdit, pricing_plan
} from "../../redux/actions/table.action";
import { DropDown } from "../../utilities/components/FromUtils";
import DateTimePicker from 'react-datetime-picker';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutsideClickHandler from "react-outside-click-handler";
import CCMap from "../../utilities/components/CCMap";
import Moment from "moment";
import { LoadingView } from "../../utilities/components/Loading";


interface Props {
  Close: () => void | undefined;
  UpdateTable: (action: boolean) => void | undefined;
  Data: Record<string, any>;
}



export default function BookingEdit({ Data, Close, UpdateTable }: Props) {
  const dispatch = useDispatch();
  const [SelectedData, setSelectedData] = React.useState<any>(Data);
  const [PricingPlans, setPricingPlans] = React.useState<any>([]);
  const [Vehicles, setVehicles] = React.useState<any>([]);
  const [Loading, setLoading] = React.useState<any>(false);
  const [Trip, setTrip] = React.useState<any>([]);


  function updateinputInfo(Data:any){
    dispatch(pricing_plan()).then((res) => {
      if (res.payload && res.payload.results) {
        setPricingPlans(res.payload.results);
      }
    });
    dispatch(VehiclesList()).then((res) => {
      if (res.payload && res.payload.results) {
        setVehicles(res.payload.results);
      }
    });
    dispatch(RecalculateRoute(Data)).then((res) => {
      if (res.payload && res.payload.results) {
        setTrip(res.payload.results);
      }
    });
  }

  useEffect(() => {
    updateinputInfo(Data)
  }, []);

  function changeKey(e: any) {
    setSelectedData({
      ...SelectedData,
      [e.target.name]: e.target.value,
    });
  }

  function Update() {
    setLoading(true)
    const data = { ...SelectedData };
    data.id = Data.id
    if (data?.pricing_plan?.id) {
      data.pricing_plan = data.pricing_plan.id
    }
    if (data?.vehicle?.id) {
      data.vehicle = data.vehicle.id
    }
    dispatch(bookingEdit(data)).then((response) => {
      setLoading(false)

      if (response.payload) {
        if (!response.payload.error) {
          UpdateTable(true);
          toast.success("Updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          //setSelectedData(response.payload);
          updateinputInfo(response.payload)

        } else {
          toast.error(response.payload.errorMassage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      dispatch(VehiclesList()).then((res) => {
        if (res.payload && res.payload.results) {
          setVehicles(res.payload.results);
        }
      });
    });
  }
  return (
    <>      <ToastContainer/>
   
    <div className="d-flex">
      <div className="EditContainer">
        <OutsideClickHandler onOutsideClick={() => {
          Close()
        }}>
          {Loading && <LoadingView />}
          <div className="EditCustomView customScrollBar">
            <div className="inputBox">
              <div className="title">Vehicle details</div>
              <div className="rowhalf">
                <div className="colhalf">
                  <div className="editRow">
                    <div className="editRowTitle">Vehicle</div>
                    <DropDown
                      type="vehicle"
                      selectedItem={SelectedData.vehicle ? SelectedData.vehicle : Data.vehicle}
                      textEntity={["name"]}
                      items={Vehicles}
                      placeholder={""}
                      onChange={(selected: any) => {
                        setSelectedData({
                          ...SelectedData,
                          vehicle: selected,
                        });
                      }}
                    />
                  </div>
                  <div className="editRow">
                    <div className="editRowTitle">Make & Model </div>
                    <input
                      type="text"
                      name="start_address"
                      onChange={changeKey}
                      disabled
                      value={
                        Data.vehicle.vehicle_type.model +
                        " " +
                        Data.vehicle.vehicle_type.make
                      }
                    />
                  </div>
                  <div className="editRow">
                    <div className="editRowTitle">License Plate </div>
                    <input
                      type="text"
                      name="start_address"
                      onChange={changeKey}
                      disabled
                      value={Data.vehicle.license}
                    />
                  </div>

                </div>
                <div className="colhalf">

                  <div className="carImgBox">
                    <img src={Data?.vehicle?.vehicle_image ? Data?.vehicle?.vehicle_image : Data.vehicle?.vehicle_type?.vehicle_image} className="carImage" />
                    {SelectedData?.vehicle?.id && (SelectedData?.vehicle?.id !== Data?.vehicle?.id) && (<div className="carImageAbsoutle">
                      <img src="/assets/icons/SelectArrowDown.svg" className="replaceIcon" />
                      <img src={SelectedData?.vehicle?.vehicle_image ? SelectedData?.vehicle?.vehicle_image : SelectedData.vehicle?.vehicle_type?.vehicle_image} className="carImage  " /></div>
                    )}
                  </div>

                </div>
              </div>
            </div>

            <div className="inputBox">
              <div className="title">User details</div>
              <div className="editRow">
                <div className="editRowTitle">User</div>
                <input
                  type="text"
                  name="-"
                  onChange={changeKey}
                  value={Data.user.first_name + " " + Data.user.last_name}
                  disabled
                />
              </div>
              <div className="editRow">
                <div className="editRowTitle">Email </div>
                <input
                  type="text"
                  name="license"
                  onChange={changeKey}
                  value={Data.user.email}
                  disabled
                />
              </div>
              <div className="editRow">
                <div className="editRowTitle">Mobile </div>
                <input
                  type="text"
                  name="name"
                  onChange={changeKey}
                  value={Data.user.mobile_number}
                  disabled
                />
              </div>
            </div>
            <div className="inputBox">
              <div className="title">Booking details</div>
              <div className="editRowTitle">Trip Overview</div>

              <div className="mapOverview">
                <CCMap Data={SelectedData} />
              </div>
              <div className="rowhalf">
                <div className="editRow">
                  <div className="editRowTitle">Created on</div>
                  <input
                    type="text"
                    name="start_address"
                    onChange={changeKey}
                    value={Moment(Data.created_on).format("ddd , D MMM YYYY hh:mm a")}
                  />

                </div>
                <div className="editRow">
                  <div className="editRowTitle">Status</div>
                  <DropDown
                    selectedItem={SelectedData.status ? SelectedData.status : Data.status}
                    textEntity={null}
                    preText={""}
                    items={["OPEN", "ACTIVE", "COMPLETE", "CANCEL"]}
                    placeholder={""}
                    onChange={(selected: any) => {
                      setSelectedData({
                        ...SelectedData,
                        status: selected,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="rowhalf">
                <div className="editRow">
                  <div className="editRowTitle">Booking Type</div>
                  <input
                    type="text"
                    name="start_address"
                    onChange={changeKey}
                    value={Data.type}
                  />
                </div>
                <div className="editRow">
                  <div className="editRowTitle">Pricing Plan</div>
                  <DropDown
                    selectedItem={SelectedData.pricing_plan ? SelectedData.pricing_plan : Data.pricing_plan}
                    textEntity={"name"}
                    preText={""}
                    items={PricingPlans}
                    placeholder={""}
                    onChange={(selected: any) => {
                      setSelectedData({
                        ...SelectedData,
                        pricing_plan: selected,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="rowhalf">

                <div className="editRow datePickerSectionBookfor">
                  <div className="editRowTitle">Booked For</div>
                  <input
                    type="text"
                    name="start_address"
                    onChange={changeKey}
                    value={Moment(SelectedData.book_for ? SelectedData.book_for : Data.book_for).format("ddd , D MMM YYYY hh:mm a")}
                  />

                  <div className="datePickerProfile ">
                    <DateTimePicker onChange={(date: any) => {
                      setSelectedData({
                        ...SelectedData,
                        book_for: date,
                      });
                    }} value={new Date(SelectedData.book_for ? SelectedData.book_for : Data.book_for)} />

                  </div>

                </div>


                <div className="editRow datePickerSectionBookfor">
                  <div className="editRowTitle">Return Time</div>
                  <input
                    type="text"
                    name="drop_off_datetime"
                    onChange={changeKey}
                    value={Moment(SelectedData.drop_off_datetime ? SelectedData.drop_off_datetime : Data.drop_off_datetime).format("ddd , D MMM YYYY hh:mm a")}
                  />

                  <div className="datePickerProfile ">
                    <DateTimePicker onChange={(date: any) => {
                      setSelectedData({
                        ...SelectedData,
                        drop_off_datetime: date,
                      });
                    }} value={new Date(SelectedData.drop_off_datetime ? SelectedData.drop_off_datetime : Data.drop_off_datetime)} />

                  </div>

                </div>

              </div>
              <div className="editRow">
                <div className="editRowTitle">Delivery instruction</div>
                <input
                  type="text"
                  name="delivery_instruction"
                  onChange={changeKey}
                  value={SelectedData.delivery_instruction ? SelectedData.delivery_instruction : Data.delivery_instruction}
                />
              </div>
            </div>
            {Data?.trip &&
            <div className="inputBox">
              <div className="title">Trip details</div>

              <div className="rowhalf">
                <div className="editRow">
                  <div className="editRowTitle">Start Time</div>
                  <input
                    type="text"
                    disabled
                    onChange={changeKey}
                    value={Moment(Data?.trip?.start_at).format("ddd , D MMM YYYY hh:mm a")}
                  />
                </div>
                <div className="editRow">
                  <div className="editRowTitle">Start Location</div>
                  <div className="inputLink" >
                    <a href={"http://maps.google.com/maps?q=" + (Data?.trip?.start_location && Data?.trip?.start_location?.coordinates[1]) + "%2C" + (Data?.trip?.start_location && Data?.trip?.start_location?.coordinates[0])}
                      target={"_blank"}  >
                      {Data?.trip?.start_location?.coordinates[0]?.toFixed(2) + " " + Data?.trip?.start_location?.coordinates[1]?.toFixed(2)}
                    </a>
                  </div>
                </div>
              </div>


              <div className="rowhalf">
                <div className="editRow">
                  <div className="editRowTitle">End Time</div>
                  <input
                    type="text"
                    disabled
                    onChange={changeKey}
                    value={Moment(Data?.trip?.end_at).format("ddd , D MMM YYYY hh:mm a")}
                  />
                </div>
                <div className="editRow">
                  <div className="editRowTitle">End Location</div>
                  <div className="inputLink" >
                    <a href={"http://maps.google.com/maps?q=" + (Data?.trip?.end_location && Data?.trip?.end_location?.coordinates[1]) + "%2C" + (Data?.trip?.end_location && Data?.trip?.end_location?.coordinates[0])}
                      target={"_blank"}  >
                      {Data?.trip?.end_location?.coordinates[0]?.toFixed(2) + " " + Data?.trip?.end_location?.coordinates[1]?.toFixed(2)}
                    </a>
                  </div>
                </div>

              </div>
              <div className="rowhalf rowhalf3 ">
                <div className="editRow">
                  <div className="editRowTitle">Duration (min)</div>
                  <input
                    disabled
                    type="text"
                    name="duration_min"
                    onChange={changeKey}
                    value={Data?.trip?.duration_min}
                  />
                </div>
                <div className="editRow">
                  <div className="editRowTitle">Distance (meter)</div>
                  <input
                    disabled
                    type="text"
                    name="distance_meter"
                    onChange={changeKey}
                    value={Data?.trip?.distance_meter}
                  />
                </div>

                <div className="editRow">
                  <div className="editRowTitle">Total Cost [{Data.stripe_invoice_cost_unit}]</div>
                  <input
                    type="text"
                    name="start_address"
                    onChange={changeKey}
                    value={Data.stripe_invoice_total_cost}
                  />
                </div>
              </div>
            </div>
}
            <div className="inputBox">
              <div className="title">Invoice</div>
              <button className="editUpdate editUpdateDisabled" disabled>Create</button>
              {Data.stripe_invoice_pdf ? <a href={Data.stripe_invoice_pdf} target={'_blank'} className="editUpdate " >Download</a> : <button className="editUpdate editUpdateDisabled" disabled>Download</button>}
              <button className="editUpdate editUpdateDisabled" disabled>Email</button>

            </div>
            <div className="editUpdate" onClick={() => Update()}>
              <img src="/assets/icons/icon-basic-refresh.svg" /> Update
            </div>
          </div>


        </OutsideClickHandler>
      </div >
    </div > </>
  );
}

import React, { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker,
  Circle,
  Polyline,
  FeatureGroup,
} from "react-leaflet";
import { Icon, LatLngBounds, LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
interface CCMapProps {
  Data: any;
}

export default function CCMap({ Data }: CCMapProps) {

  const arrIcon = new Icon({
    
    iconUrl: "/assets/icons/IconDestination.png",
    iconSize: [60, 60],
    className: 'pinMapIconStart'
  });


  const endIcon = new Icon({
    iconUrl: "/assets/icons/IconDestinationEnd.png",
    iconSize: [60, 60],
    className: 'pinMapIconEnd'
  });



  const lat =   '52.0397867';
  const lng = '-0.7627915';
  let endShow = true ;
  let startShow = true ;
  function pointFinder(p: any, type: any) {


    let point: any = { lat: '', lng: '' };
    if (type === "start_location") {
      if (p.start_location) {
        
        point.lat = p.start_location.coordinates[1]
        point.lng = p.start_location.coordinates[0]
      }else{
        endShow = false
      }
    } else if (type === "end_location") {
      if (p.end_location) {
        point.lat = p.end_location?.coordinates[1]
        point.lng = p.end_location?.coordinates[0]
      }else{
        endShow = false
      }
    }
    if (!point.lat && type === "start_location") {
      point.lat = lat
      point.lng = lng
    }

    return point;
  }

  function routeLine(leg: any) {
    const points: any = [];
    leg.points.map((point: any) => {
      let p = [point.latitude, point.longitude];
      points.push(p);
    });

    return (
      <Polyline positions={points} pathOptions={{ color: "#00b0ff" ,weight:6 }} />
    );
  }

  // var dis = getDistance(
  //   {latitude: Data.vehicle.location_point.coordinates[1], longitude: Data.vehicle.location_point.coordinates[0]},
  //   {latitude: vehicle.location_point.coordinates[1] , longitude: vehicle.location_point.coordinates[0]},
  // );

  let start = pointFinder(Data, "start_location")
  let end = pointFinder(Data, "end_location")
  console.log("start",start)
  console.log("end",end)





  var corner1 = new LatLng(start.lat, start.lng);
  if(end.lat){
    var corner2 = new LatLng(end.lat, end.lng);
  }else{
    var corner2 = new LatLng(start.lat, start.lng);

  }

  var bounds = new LatLngBounds(corner1, corner2);

  function iconGenerator(vehicle: any) {
    const carIcon = new Icon({
      iconUrl: vehicle.vehicle_image ? vehicle.vehicle_image : vehicle?.vehicle_type?.vehicle_image ,
      iconSize: [60, 50],
    });
    return carIcon;
  }

  return (Data ?
    <MapContainer bounds={bounds} scrollWheelZoom={true} >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {Data.car_routes && Data.car_routes.routes &&
        Data.car_routes.routes.map((route: any, key: any) => (
          <FeatureGroup key={key} >
            <Popup>Popup in FeatureGroup</Popup>
            {route &&
              route.legs &&
              route.legs.map((leg: any) => routeLine(leg))}
          </FeatureGroup>
        ))}



      {(Data?.status === "ACTIVE" || Data?.status === "OPEN") && Data?.vehicle && (
        <Marker
          position={[
            Data && Data?.vehicle?.location_point ? Data.vehicle.location_point.coordinates[1] : lat,
            Data && Data?.vehicle?.location_point ? Data.vehicle.location_point.coordinates[0] : lng,
          ]}
          icon={iconGenerator(Data.vehicle)}
        >
          <Popup>Location</Popup>
        </Marker>
      )}



{startShow &&
      <Marker
        position={[start.lat, start.lng]}
        
        icon={arrIcon}
      >
        <Popup>Start Location</Popup>
      </Marker>
}
{endShow &&

      <Marker
        position={[end.lat, end.lng]}
        icon={endIcon}
      >
        <Popup>Arrival Location</Popup>
      </Marker>
  }
    </MapContainer>

    :
    <div>Car Route is Null</div>
  );
}

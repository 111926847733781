import thunkMiddleware, { ThunkDispatch } from "redux-thunk";
// import DevTools from "../pages/ReduxDevTools/ReduxDevTools";
import { configureStore, Action } from "@reduxjs/toolkit";
import { useDispatch as useAppDispatch } from "react-redux";

import rootReducer from "./reducers";
const enhancers: any = [];
// if (process.env.REACT_APP_ENV !== "production") {
//   enhancers.push(DevTools.instrument());
// }
const store = configureStore({
  enhancers,
  middleware: [thunkMiddleware] as const,
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV !== "production",
});
export type AppDispatch = typeof store.dispatch;
export function useDispatch(): ThunkDispatch<typeof store, any, Action> {
  return useAppDispatch<ThunkDispatch<typeof store, any, Action>>(); // Export a hook that can be reused to resolve types
}
export default store;

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
}

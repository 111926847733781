import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

const RequireAuth = ({ allowedRoles }: any) => {
    const user = useSelector((state: RootState) => state.user.profile);
    const roles = user?.roles;
    const location = useLocation();
    localStorage.setItem("prev_url", window.location.href);

    return (
        roles?.find((role: any) => allowedRoles?.includes(role))
            ? <Outlet />
            : user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;
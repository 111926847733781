import * as React from "react";
import { InputWithIcon } from "./FromUtils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { logoutAction } from "../../redux/actions/auth.action";
import { profile } from "../../redux/actions/user.action";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { useEffect } from "react";
import i18n from "i18next";
import OutsideClickHandler from "react-outside-click-handler";
import Moment from "moment";

interface HeaderAndMainProps {
  sideState: any;
  setSideState: (flag: boolean) => void;
  children: React.ReactNode;
}

export default function HeaderAndMain({
  sideState,
  setSideState,
  children,
}: HeaderAndMainProps) {
  const [t] = useTranslation();
  const [dpMenu, setDpMenu] = React.useState(false);
  const [NotifDpMenu, setNotifDpMenu] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.profile);
  const Token = useSelector((state: RootState) => state.auth.token);
  const changeLanguage = (lng: any) => {
    localStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
  };

  const logOutHandler = () => {
    dispatch(logoutAction()).then(async () => {
      await localStorage.removeItem("token");
      await localStorage.removeItem("coca-manager");
      window.location.replace('/login');
    });
  };
  useEffect(() => {
    if (!user.username) {
      dispatch(profile(Token));
    }
  }, []);

  return (<>   {dpMenu && (
    <div
      className="header-drop-menu"
    >

      <OutsideClickHandler
        onOutsideClick={() => {
          setDpMenu(false);
        }}
      >
        <div className="headerUserItem">
          <div className="title">your balance</div>
          <div><img src="/assets/icons/WalletBlack.svg" /> £{user.balance}</div>
        </div>
        <div className="headerUesrRows">
          <div className="headerUserItem">
            <div className="title">num of bookings</div>
            <div>{user.num_bookings}</div>
          </div>
          <div className="headerUserItem">
            <div className="title">last booking</div>
            <div>{user.last_booking ? Moment(user.last_booking).format("D MMM hh:mm a") : '-'}</div>
          </div>

        </div>

        <button onClick={() => logOutHandler()}>
          {t("home.logout")}
        </button>
      </OutsideClickHandler>

    </div>
  )}     {NotifDpMenu && (
    <div
      className="header-drop-menu NotifDpMenu"
    >

      <OutsideClickHandler
        onOutsideClick={() => {
          setNotifDpMenu(false);
        }}
      >
        <div className="headerUserItem">
          <div className="title">you currently have no new notifications</div>
        </div>


      </OutsideClickHandler>

    </div>
  )}

    <div
      className={
        sideState
          ? "viewPanel header-content-container-desktop d-flex flex-column"
          : "viewPanel header-content-container-mobile d-flex flex-column"
      }
    >
      <header
        className={
          sideState
            ? "header-container header-container-desktop d-flex align-items-center justify-content-between"
            : "header-container  header-container-mobile d-flex align-items-center justify-content-between"
        }
      >

   
        <div className="header-expand-container d-flex align-items-center">
          <button
            onClick={() => {
              if (sideState === false) {
                setSideState(true);
              } else {
                setSideState(false);
              }
            }}
          >
            {sideState && (
              <img src={"/assets/icons/Menu@2x.png"} alt="input-icon" />
            )}
            {!sideState && (
              <img src={"/assets/icons/MenuClosed@2x.png"} alt="input-icon" />
            )}
          </button>
        </div>
        <div className="d-flex align-items-center">
          <div className="headericon" onClick={() => {
            setNotifDpMenu(true)
          }}>
            <img src="/assets/icons/notification-bell.svg" />
          </div>
          <div
            className="header-account-container d-flex align-items-center"
            onClick={() => setDpMenu(!dpMenu)}
          >
            <div className="header-account-circle header-account-profileImage d-flex align-items-center justify-content-center">
              {user && <img src={user.picture} />}
            </div>
            <small className="header-account-text">
              {(user && user.first_name) ? user.first_name + " " + user.last_name
                : user ? user.username : ' - '}
            </small>
            <button className="icon-account-container d-flex align-items-center">
              <img src={"/assets/icons/ArrowDown@2x.png"} alt="input-icon" />
            </button>
          </div>

        </div>
      </header>
      <main className="content-container">{children}</main>
    </div></>
  );
}

import React, { useEffect } from "react";
import LoadingView from "./Widgets/Loading";
import moment from "moment"
interface ItemAddProps {
  add: (element: object) => void | undefined;
  close: () => void | undefined;
  sectionId: string;
  sectionKey: string;
  WidgetsList: Record<string, any>;
  t: any;
}

export default function AddView({
  add,
  close,
  sectionId,
  sectionKey,
  WidgetsList,
  t,
}: ItemAddProps) {
  const [Menu, setMenu] = React.useState("All");
  const [Loading, setLoading] = React.useState(false);
  const [Sections, setSections] = React.useState<Array<Record<any, any>>>([]);
  const [Cats, setCats] = React.useState<Array<Record<any, any>>>([]);

  function addWidgetFunction(item: any) {
    item.i = item.i + sectionId;
    item.section_id = sectionId;
    item.section_key = sectionKey;
    item.static = item.static === "true" ? true : false;
    add(item);
  }

  useEffect(() => {
      console.log("WidgetsList",WidgetsList)
      setSections(WidgetsList?.widgets);

  }, []);


  useEffect(() => {
    Sections && Sections.length && setLoading(false)
  }, [Sections]);
  

function isNewWidget(create_date:any){
  const weekPast = moment(new Date).subtract(2, 'week').startOf('week');
  const newWidget:any = moment(create_date).isAfter(weekPast)
  return newWidget
}

  return (
    <div className={"popupItems"}>
      {Loading ? (
        <LoadingView />
      ) : (
        <div className={"itemsContent"}>
          <span className={"closePopup"} onClick={() => close()}></span>
          <div className={"menu-categories"}>
            <div className={"title"}>{t.Categories}</div>
            <ul className="side-items-container">
              <li
                onClick={() => {
                  setMenu("All");
                }}
                className="side-items-first-element d-flex align-items-center"
                style={{
                  paddingLeft: Menu !== "All" ? 15 : 20,
                  backgroundColor: Menu === "All" ? "#f8fafb" : "",
                }}
              >
                {Menu == "All" && (
                  <div className="side-item-active-left" />
                )}
                <img
                  style={{
                    width: Menu == "All" ? 25 : 35,
                    height: Menu ? 25 : 35,
                  }}
                  src={'/assets/icons/Dashboard.svg'}
                />
                <span>All</span>
              </li>
              {Sections && Sections.length &&
                Sections.map((item, index) => (

                  <li
                    key={index}
                    onClick={() => {
                      setMenu(item.name);
                    }}
                    className="side-items-first-element d-flex align-items-center"
                    style={{
                      paddingLeft: Menu !== item.name ? 15 : 20,
                      backgroundColor: Menu === item.name ? "#f8fafb" : "",
                    }}
                  >
                    {Menu == item.name && (
                      <div className="side-item-active-left" />
                    )}
                    <img

                      src={item.icon}
                    />
                    <span>{item.name}</span>
                  </li>
                ))}
            </ul>
          </div>

          <div className={"itemViews"}>
            {Sections && Sections.length &&
              Sections.map((section: any, i: any) =>
                Menu === section.name || Menu === "All" ? (
                  <section key={i} className={'sectionAddview sectionAddview-' + section.id}>
                    <div className={"viewTitle"}>{section.name}</div>
                    <div className={"items"}>

                      {section.items.map((item: any, index: any) => (
                        <div className={"itemView"} key={index}>
                          <div className={"imgBox"}>
                          {isNewWidget(item.updated_on) ? <div className="newWidget">New Widget</div> : ''}
                            <div className={"itemDescView"}>
                              <div className={"itemDescViewText"}>
                                {item.desc}
                              </div>
                              <div
                                className={"addToDashboardBtn"}
                                onClick={() => addWidgetFunction(item)}
                              >
                                <img src={"/assets/icons/AddElementPlus.svg"} />
                                {t.Add_to_Dashboard}
                              </div>
                            </div>
                            <div className={"itemIcon"}>
                              <div>
                                <img src={item.icon} />
                              </div>
                            </div>
                          </div>
                          <span className={"desc"}>{item.title}</span>
                        </div>
                      ))}
                    </div>
                  </section>
                ) : null,
              )}
          </div>
        </div>
      )}
    </div>
  );
}

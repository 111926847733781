import * as React from "react";
import CCMap from "../../utilities/components/CCMap";
import moment from "moment";
import {
  tasks,
  taskReturn, tasksCounter,
  startDispatching,startReturnDispatching
} from "../../redux/actions/table.action";
import { useDispatch } from "../../redux/store";
import { ToastContainer, toast } from "react-toastify";
import {LoadingView} from "./../../utilities/components/Loading";



interface Props {
  TaskData: Record<string, any>;
  close :  (value: any) => void | undefined;
  updateTable :  () => void | undefined;
}

export default function Table({
  TaskData,
  close,updateTable
}: Props) {

  const dispatch = useDispatch();


  const [Task, setTask] = React.useState<any>(TaskData);
  const [Loading, setLoading] = React.useState(false);

  async function TaskUpdate(id: any) {
    dispatch(taskReturn(id)).then((response: any) => {
      if (response.payload) {
        setLoading(false);
        setTask(response.payload)
      }
    });
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      TaskUpdate(Task.id)
    }, 20000);
  
    return () => clearInterval(interval);
  }, [])
  
  
  function update(data: any, status: any) {
    const json: any = {};
    json.status = status;
    dispatch(startReturnDispatching({ id: data.id, json })).then((response: any) => {
      updateTable();
      if (response.payload) {
        if (!response.payload.error) {
          close(false);
        } else {
          if(status === "COMPLETE"){
            close(false);
          }
          toast.error(response.payload.errorMassage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        
        }
      }
    });
  }
  
  function RowMap(row: any) {
    return <CCMap Data={row} />;
  }

  return ( <div className="mapPopUpView">

      {Loading && (
        <LoadingView/>
      )}
  <div
    className="mapPopUpViewClose"
    onClick={() => {
      close(false);
    }}
  >
    <img src="/assets/icons/closeCircle.svg" />
  </div>
  <div className="tableMapRowOpen">
    <div className="tableMapContainer">{RowMap(Task)}</div>
  </div>

  <div className="vehicleInfoContainer">
    {Task?.safety_driver && <>
      <div className="vehicleInfoTitle">Safety Driver</div>
      <div className="dispatcherInfo">
        <div><img src={Task?.safety_driver?.picture} className="dispatcherImage" /></div>
        <div>
        <div> {Task?.safety_driver?.first_name}  {Task?.safety_driver?.last_name} <span className="userCardVerified"><img src={'/assets/icons/verified.png'} /></span><br /></div> 
          {Task?.safety_driver?.email} <br />
          {Task?.safety_driver?.mobile_number} <br />
          {Task?.safety_driver?.id} <br />
        </div>
      </div>
    </>
    } {Task?.dispatcher_driver && <>
      <div className="vehicleInfoTitle">Dispatcher</div>
      <div className="dispatcherInfo">
        <div><img src={Task?.dispatcher_driver?.picture} className="dispatcherImage" /></div>
        <div>
        <div> {Task?.dispatcher_driver?.first_name}  {Task?.dispatcher_driver?.last_name} <span className="userCardVerified"><img src={'/assets/icons/verified.png'} /></span></div>
          {Task?.dispatcher_driver?.email} <br />
          {Task?.dispatcher_driver?.mobile_number} <br />
          {Task?.dispatcher_driver?.id} <br />
        </div>
      </div>
    </>
    }
    <br /> <br />
    <div>

      <strong>Customer  Name :</strong><img src={Task?.booking?.user?.picture} className="customerImage" /> {Task?.booking?.user?.first_name}  {Task?.booking?.user?.last_name} ({Task?.booking?.user?.mobile_number})<br />
      <strong>Start Address :</strong> {Task?.start_address} <br />
      <strong>End Address :</strong> {Task?.end_address} <br />
      <strong>Car Routes :</strong> {Task?.car_routes ? 'Yes' : "No"} <br />

    </div>
    <div className="vehicleInfo">

      <div className="carImgBox">
        <div><img src={Task?.booking?.vehicle.vehicle_image ? Task?.booking?.vehicle.vehicle_image : Task?.booking?.vehicle?.vehicle_type?.vehicle_image} className="carImage" /></div>
        <div>{Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle?.name} - {Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle?.license}</div>
        <div>is disabled : {Task?.booking?.vehicle?.is_disabled ? "YES" : "NO"}</div>
        <div>is parked   :  {Task?.booking?.vehicle?.is_parked ? "YES" : "NO"}</div>
        <div>Online : {Task?.booking?.vehicle?.last_reported ? moment(Task?.booking?.vehicle?.last_reported).format("ddd , D MMM YYYY hh:mm a") : "-"}</div>
      </div>
      <div className="carInfo">
        <div className="title"> <img src={Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.vehicle_brand_image} />{" "} {Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.NAME}
        </div>
        <div className="row"> <img src="/assets/icons/rad.png" /> {Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.propulsion_type}
        </div>
        <div className="row"> <img src="/assets/icons/seting.png" /> {Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.transmission_type}
        </div>
        <div className="row"> <img src="/assets/icons/seat.png" /> {Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.number_of_seats}{" "} Seats
        </div>
        <div className="row"> <img src="/assets/icons/dif.png" /> {Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.body_configuration_type}{" "}
        </div>
        <div className="row"> <img src="/assets/icons/batr.png" /> {Task?.booking && Task?.booking?.vehicle && Task?.booking?.vehicle.vehicle_type.max_range_meters}{" "} km
        </div>
      </div>
    </div>
    <div className="mapDispatching">
    {Task.status !== "COMPLETE" && <>
      {Task.status === "OPEN" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching startDispatchingDisable " : "startDispatching stopDispatching"}
      //onClick={() => start(Task)} 
      > START DISPATCHING (Disabled) </div>)}
      {Task.status === "OPEN_WITH_SAFETY" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching  " : "startDispatching stopDispatching"} onClick={() => update(Task, "DISPATCHING")} > START DISPATCHING (SAFETY) </div>)}
      {/* {Task.status === "COMPLETE" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching " : "startDispatching stopDispatching"} onClick={() => ""} > Complete </div>)} */}
      {Task.status === "CANCEL" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching " : "startDispatching stopDispatching"} onClick={() => update(Task, "DISPATCHING")} > Canceled <span> Click To Distpatching </span> </div>)}
      {Task.status === "DISPATCHING" && (<div className={Task.status !== "DISPATCHING" ? " startDispatching " : "startDispatching stopDispatching"} onClick={() => update(Task, "COMPLETE")} > End Dispatching </div>)}
      {/* <small> ( {Task.status} )</small> */}
      {Task.status !== "CANCEL" && (<div className={"startDispatching canselDispatching"} onClick={() => { update(Task, "CANCEL"); }} > Cancel </div>)}
    </>}</div>
  </div>


</div>);
}

import { createSlice } from "@reduxjs/toolkit";
import { setMenu, menuState } from "../actions/menu.action";
// Then, handle actions in your reducers:
const menuSlice = createSlice({
  name: "menu",
  initialState: {
    open: true,
    menuState: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setMenu.fulfilled, (state) => {
      //console.log("Menu Hover", state.open);
      state.open = !state.open;
    });
    builder.addCase(menuState.fulfilled, (state) => {
      //console.log("Menu State", state.open);
      state.menuState = !state.open;
    });
  },
});

export default menuSlice;

import { statApi, rest } from "../apiGateway";
import { errorHandler } from "../apiGateway";

export const stat = async (url: any) => {
  try {
    return await statApi.get(url).json();
  } catch (err) {
    return errorHandler(err);
  }

};

export const saveSetting = async (sections:any) => {
  try {
    return rest
      .put("widget/settings/", {
        body: JSON.stringify({ setting: sections }),
      })
      .json();
  } catch (err) {
    return errorHandler(err);
  }
};

export const getSetting = () => {
  try {
    return rest
      .get("widget/settings/")
      .json();
  } catch (err) {
    return errorHandler(err);
  }
};
export const groups = async () => {
  try {
    return await rest.get(`users/locations/`).json();
  } catch (err) {
    return errorHandler(err);
  }
};
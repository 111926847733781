import * as React from "react";
interface Props {
  title?: string;
}

export default function WidgetError({ title }: Props) {
  return (
    <div className={"widgetError"}>
      <h3>{title ? title : "Server Error"}</h3>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import DashboardHeader from "./Layout/DashboardHeader";
import SectionCreator from "./Layout/SectionCreator";
import AddWidgetView from "./Layout/AddView";

import "./App.css";

interface Props {
  assets?: Record<string, any>;
  widgetData: Record<string, any>;
  menuOpen?: boolean | true;
  reload: boolean | false;
  headers?: Record<string, any>;
  WidgetsList: Record<string, any>;
  string?: any;
  saveSetting: (setings: any) => void | undefined;
  
  getSetting: any;
}
const stringDefualt = require("./strings.json");
//const stringDefualt = {}

const simpleSection: any = {
  id: "section1",
  title: "Section 1",
  show: true,
  items: [],
};
export default function Dashboard({
  widgetData,
  menuOpen,
  WidgetsList,
  string,
  getSetting,
  saveSetting,
  assets,
  reload,
}: Props) {
  const t = string ? string : stringDefualt;
  const container = useRef();
  //const { request, exit } = useFullscreen();
  const [Sections, setSections] = useState<any>([]);
  const [HiddenSections, setHiddenSections] = useState([]);
  const [RenameSection, setRenameSection] = useState("");
  const [SectionCalendar, setSectionCalendar] = useState("");
  const [SectionTitle, setSectionTitle] = useState("");
  const [OpenSetting, setOpenSetting] = useState("");
  const [SizingReload, setSizingReload] = useState(false);
  const [SectionsAction, setSectionsAction] = useState(false);
  const [MenuSideState, setMenuSideState] = useState(menuOpen);
  const [SlideShowMode, setSlideShowMode] = useState(false);
  const [ShowSlideTimer, setShowSlideTimer] = useState(false);

  
  const [SlideTimer, setSlideTimer] = useState(30000);
  const [Scale, setScale] = useState(1);
  const [CountDown, setCountDown] = useState<any>(null);
  const [SingleOverview, setSingleOverview] = useState(null);
  const [SliderStop, setSliderStop] = useState(false);
  const [SliderIndex, setSliderIndex] = useState(0);
  const [Overview, setOverview] = useState(false);
  const [itemsReady, setItemsReady] = useState(false);
  const [Breakpoint, setBreakpoint] = useState("sm");
  const [OpenDateZindex, setOpenDateZindex] = useState({});
  const [AddView, setAddView] = useState(false);
  const [SectionIdAddedElement, setSectionIdAddedElement] = useState("");
  const [SectionKeyAddedElement, setSectionKeyAddedElement] = useState("");

  function onDragStop(layouts: any, oldItem: any, newItem: any) {
    itemUpdate(layouts, newItem);
  }
  function onResizeStop(layouts: any, oldItem: any, newItem: any) {
    itemUpdate(layouts, newItem, false, true);
  }
  function onLayoutChange(layouts: any) {
    if (layouts) {
      itemUpdate(layouts, layouts[layouts.length - 1], false, true);
    }
  }
  async function onBreakpointChange(breakpoint: any) {
    if (breakpoint !== Breakpoint) {
      setBreakpoint(breakpoint);
      const s = [...Sections];
      await setSections([]);
      await setSections(s);
    }
  }
  function handleDateChange(range: any, index: any) {
    const sections = [...Sections];
    const section = sections[index];
    console.log("handleDateChangehandleDateChangehandleDateChangehandleDateChangehandleDateChangehandleDateChangehandleDateChangehandleDateChange")

    section.items.map((item: any) => {
      item.end_date = range.end_date;
      item.start_date = range.start_date;
      if(range.start_date === 'Invalid date'){
        item.timespan = 'EVER';
        item.start_date = undefined;
      }
      if(range.end_date === 'Invalid date'){
        item.timespan = 'EVER';
        item.end_date = undefined
      }
      console.log("start_date",sections[0].items[0].start_date)
      console.log("start_date === ",range.start_date)
      console.log("end_date",sections[0].items[0].end_date)
      console.log("end_date   ===", range.start_date)

     
      item.i = makeId(5) + "-" + item.i;
    });
    setSections(sections);
    console.log("saveSetting 1")
    saveSetting(sections)
    setSizingReload(!SizingReload);
  }
  function SingleOverviewFunction(id: any) {
    if (SingleOverview !== id) {
      setSingleOverview(id);
      //request();
    } else {
      setSingleOverview(null);
      //exit();
    }
  }
  function makeId(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function openSectionSetting(id: any) {
    if (OpenSetting === id) {
      setOpenSetting("");
    } else {
      setOpenSetting(id);
    }
  }

  async function deleteSection(id: any) {
    const array = [...Sections];
    const index = await array.findIndex((section) => section.id === id);
    if (index !== -1) {

      array.splice(index, 1);
      if (array.length === 0) {
        await setDefaultSetting();
      } else {
        await setSections(array);
        await setSectionsAction(!SectionsAction);
      }
      await saveItems(array)

    }
  }
  function showAddView(id: any, key: any) {
    setAddView(true);
    setSectionIdAddedElement(id);
    setSectionKeyAddedElement(key);
  }

  function SectionMove(up: any, key: any) {
    setRenameSection("");
    if (Sections.length > 1) {
      const newIndex = up ? key - 1 : key + 1;
      if (newIndex !== -1) {
        array_move(Sections, key, newIndex);
      }
    }
  }

  function saveItems(sections: any = null) {
    //console.log("aaaa",sections)
    if (!sections) {
      sections = Sections;
    }
    //if (sections.length) {
      console.log("saveSetting 2")

      saveSetting(sections);
    //}
  }
  function setSectionsNewKey(arr: any) {
    arr.map((section: any, key: any) => {
      section.items.map((item: any) => {
        item.section_key = key;
      });
    });
    setSections(arr);
  }
  function array_move(arr: any, old_index: any, new_index: any) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        //arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setSectionsNewKey(arr);
    saveItems();
    return arr;
  }
  function widgetDateChange(range: any, section_key: any, i: any) {
    let sections = [...Sections];
    let section = sections[section_key];

    console.log("widgetDateChange", range.start_date)
    console.log("section", section)

    if (section) {
      const index = section.items.findIndex((item: any) => item.i === i);
      

      if (section && range && section.items[index]) {
        section.items[index].start_date = range.start_date;
        section.items[index].end_date = range.end_date;
        console.log("section.items[index]", range.start_date)
        console.log("section.items[index]", range.end_date)
      }
      sections[section_key] = section;
      console.log("sectionssections", sections)

      setSections(sections);
      console.log("saveSetting 3")

      saveSetting(sections)

    }
  }
  function widthValidator(item: any, width: any) {
    if (item !== undefined) {
      const ratio = item.w - item.h;
      const newHeight = width - ratio;
      if (width <= item.maxW) {
        const size = {
          width: width,
          height:
            newHeight >= item.maxH
              ? item.maxH
              : newHeight < item.minH
                ? item.minH
                : newHeight,
        };
        // console.log("size", size);
        return size;
      } else {
        return false;
      }
    }
  }

  function itemUpdate(
    layouts: any,
    DragItem: any,
    Dragged = false,
    resizing = false,
  ) {
    if (DragItem !== null) {
      let change = false
      const section_id = DragItem.i.split("=")[1];
      if (section_id !== undefined) {
        const section: any = Sections.filter(
          (section: any) => section.id === section_id,
        )[0];
        if (section) {
          const data = section.items.filter(
            (item: any) => item.i === DragItem.i,
          )[0];
          const newObject = DragItem;
          const itemIndex = section.items.findIndex(
            (item: any) => item.i === DragItem.i,
          );
          const sectionIndex = Sections.findIndex(
            (section: any) => section.id === section_id,
          );
          const widthValidation = widthValidator(data, DragItem.w);
          const sections = Sections;
          const newItem = { ...section.items[itemIndex] };
          if(newItem.x !== newObject.x){
            newItem.x = newObject.x;
            change = true
          }
          if(newItem.y !== newObject.y){
            newItem.y = newObject.y;
            change = true
          }
          
          if (widthValidation && resizing) {
           
            if (
              widthValidation.height === parseInt(widthValidation.height, 10)
            ) {
            
              if(newItem.w !== newObject.w){
                newItem.w = newObject.w;
                change = true
              }
              if(newItem.h !== newObject.h){
                newItem.h = newObject.h;
                change = true
              }
              
            }
          }
          if (Dragged) {
            newItem.i = makeId(1) + "-" + newObject.i;
            newItem.isDraggable = !newObject.isDraggable;
            newItem.isResizable = !newObject.isResizable;
            newItem.static = !newObject.static;
            change = true

          }
          sections[sectionIndex].items[itemIndex] = newItem;
          setSections(sections);
          setSizingReload(!SizingReload);
          //console.log("Change",change);
          if(change){
            saveItems(sections);
          }
        }
      }
    }
  }
  function pinItem(el: any) {
    itemUpdate(Sections, el, true);
  }

  function removeItem(el: any) {
    let section_id = el.section_id;
    if (!section_id) {
      section_id = el.i.split("=")[1];
    }
    const sections: any = [...Sections];

    const section: any = Sections.filter(
      (section: any) => section.id === section_id
    )[0];

    const sectionIndex = sections.findIndex(
      (section: any) => section.id === section_id
    );
    if (section) {
      const index = section.items.findIndex((item: any) => item.i === el.i);
      section.items.splice(index, 1);
      sections[sectionIndex].items = section.items;
      setSections(sections);
      saveItems(sections);
    }
  }
  function infoItemAction(el: any) {
    el.info = !el.info;
    itemUpdate(Sections, el);
  }
  function SectionMap(Slider = false) {
    return (
      <SectionCreator
        widgetData={widgetData}
        SectionAction={() => {
          setSectionsAction(!SectionsAction);
        }}
        Ready={itemsReady}
        Breakpoint={Breakpoint}
        t={t}

        OpenDateZindex={OpenDateZindex}
        assets={assets ? assets : {}}
        SliderIndex={SliderIndex}
        Scale={Scale}
        Sections={Sections}
        RenameSection={RenameSection}
        Slider={Slider}
        Overview={Overview}
        SingleOverview={SingleOverview}
        SectionTitle={SectionTitle}
        SlideShowMode={SlideShowMode}
        setSlideShowMode={(status:any)=>{setSlideShowMode(status)}}
        SectionCalendar={SectionCalendar}
        HiddenSections={HiddenSections}
        OpenSetting={OpenSetting}
        setSections={(sections) => {
          setSections(sections);
        }}
        loadedItem={(el) => {
          //loadedItem(el); delete
        }}
        setRenameSection={(el) => {
          setRenameSection(el);
        }}
        setHiddenSections={(el) => {
          setHiddenSections(el);
        }}
        openSectionCalendar={(el) => {
          openSectionCalendar(el);
        }}
        setSectionCalendar={(el) => {
          setSectionCalendar(el);
        }}
        handleDateChange={(range, key) => {
          handleDateChange(range, key);
        }}
        SingleOverviewFunction={(el) => {
          SingleOverviewFunction(el);
        }}
        openSectionSetting={(el) => {
          openSectionSetting(el);
        }}
        setOpenSetting={(el) => {
          setOpenSetting(el);
        }}
        deleteSection={(el) => {
          deleteSection(el);
        }}
        showAddView={(el, key) => {
          showAddView(el, key);
        }}
        SectionMove={(el, key) => {
          SectionMove(el, key);
        }}
        widgetDateChange={(range, section_key, i) => {
          widgetDateChange(range, section_key, i);
        }}

        setOpenDateZindex={(state, el) => {
          setOpenDateZindex(el);
        }}
        pinItem={(el) => {
          pinItem(el);
        }}
        removeItem={(el) => {
          removeItem(el);
        }}
        infoItemAction={(el) => {
          infoItemAction(el);
        }}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        setSectionTitle={(name) => {
          setSectionTitle(name);
        }}
      />
    );
  }
  function SlideShowMap() {
    return <div>SlideShowMap HI</div>;
  }
  function SlideShowModeCall() {
    setSlideShowMode(!SlideShowMode)
   // return true;
  }


  function openSectionCalendar(id: any) {
    if (SectionCalendar === id) {
      setSectionCalendar("");
    } else {
      setSectionCalendar(id);
    }
  }
  const scrollTop = () => {
  
    window.scrollTo({ top: 100000, behavior: "smooth" });
  };
  async function AddSection() {
    const lastKey = Sections.length;
    let id: any = "1";
    if (lastKey) {
      id = parseInt(Sections[lastKey - 1].id.match(/\d+/)[0]) + 1;
    }
    const newSection = { ...simpleSection };

    newSection.id = "Section" + id;
    newSection.title = "Section " + id;
    newSection.items = [];
    setSections((oldArray: any) => [...oldArray, newSection]);
    setSectionsAction(!SectionsAction);
    scrollTop();
  }

  async function setDefaultSetting() {
    await setSections([simpleSection]); //setAdd ;
    //await setSectionsAction(!SectionsAction);
  }
  function readItems() {
    if (getSetting !== undefined) {
      if (getSetting && getSetting.length && getSetting[0]) {
        if (getSetting[0].items) {
          let showsSection = false;
          getSetting.map((sec: any) => {
            if (sec.show) {
              showsSection = true;
            }
          });
          if (!showsSection) {
            getSetting[0].show = true;
          }
          setSections(getSetting);
          setItemsReady(true);
        } else {
          setDefaultSetting(); setItemsReady(true);

        }
      } else {
        setDefaultSetting(); setItemsReady(true);

      }
    }
  }
  async function fetchAPI() {
    await setSections([]);
    await readItems();
  }

  function onAddItem(element: any) {
    element.i = makeId(5) + "-" + element.i;
    const sections = [...Sections];
    let section = sections[element.section_key];
    if (section.id !== element.section_id) {
      const sectionWithId = sections.findIndex(
        (sec: any) => sec.id === element.section_id
      );
      section = sections[sectionWithId];
    }
    // if (section.items) {
    //   let finalY = 0;
    //   section.items.map((w: any, key: any) => {
    //     console.log("w.y ", w.y);
    //     finalY += w.y;
    //   });
    //   console.log("finalY ", finalY);
    //   element.y = finalY + 1;
    // }

    section.items = section.items.concat(element);
    sections[element.section_key] = section;
    setSections(Sections);
    setAddView(false);
  }
  function closeAddView() {
    setAddView(!AddView);
  }
  useEffect(() => {
    readItems();
    //console.log("getSetting", getSetting);
  }, [getSetting]);
  useEffect(() => {
    //console.log('save useEffect')
    //saveItems(Sections);
    //console.log("Sections", Sections);
  }, [Sections]);
  useEffect(() => {
    if(RenameSection === ""){
      saveItems(Sections);
    }
  }, [RenameSection]);

  
  return (
    <React.Fragment>
      <DashboardHeader
        t={t}
        SlideShowModeCall={() => SlideShowModeCall()}
        MenuSideState={MenuSideState ? true : false}
        AddSection={() => AddSection()}
        setOverview={(action) => setOverview(action)}
        Overview={Overview}
      />

      <div
        className={
          Overview
            ? "dashboardView dashboardViewOverViewState"
            : "dashboardView"
        }
      >
        {AddView && (
          <AddWidgetView
            t={t}
            WidgetsList={WidgetsList}
            sectionId={SectionIdAddedElement}
            sectionKey={SectionKeyAddedElement}
            add={(element) => onAddItem(element)}
            close={() => closeAddView()}
          />
        )}
        {itemsReady && SectionMap()}
      </div>
    </React.Fragment>
  );
}

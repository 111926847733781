import * as React from "react";
import Action from "./Action";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "./DatePicker";
import { useEffect } from "react";
interface LayoutHeaderProps {
  el: Record<string, any>;
  options: Record<string, any>;
  defaultDate?: Record<string, any>;
  onDateChange?: (range: { start_date: string; end_date: string }) => void;
  pin: (el: object) => void | undefined;
  info: (el: object) => void | undefined;
  remove: (el: object) => void | undefined;
  onPropChange?: (p: any,select:any) => void;
  DateToggle?: (value: any, id: any) => void | undefined;
  ShowDatePicker?: boolean | false;
  userOption?: boolean | false;
  table?: boolean | false;
  t: Record<string, any>;
  Props: Record<string, any>;
  response: Record<string, any>;
  SmallWidget: boolean;
}

export default function LayoutHeader({
  el,
  options,
  Props,
  pin,
  info,
  remove,
  onDateChange,
  defaultDate,
  ShowDatePicker,
  DateToggle,
  SmallWidget,
  onPropChange,
  response,
  t,
}: LayoutHeaderProps) {
  const [DatePopUp, setDatePopUp] = React.useState(false);
  const [ActionBtn, setActionBtn] = React.useState(false);
  const [ShowPopUp, setShowPopUp] = React.useState('');
  const [CalendarDate, setCalendarDate] = React.useState<any>(defaultDate);


  //el.permission && el.permission.admin)) ||
  function isObject(val:any) {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
}
function dropText(drop:any,key:any,parameters:any){
  if(parameters[key].name === drop.name){
    if(Props[drop.name]){
      return Props[drop.name]
    }
  }
  return false
}
  return (
    <>
      <div className={"titleItem "}>
        <span className={"title"}>
          {response.error ? "Error" : el ? el.w === 1 ? response?.config?.title?.slice(0, 19) + "..." : response?.config?.title : 'Not Set'}
        </span>
        <span className="subtitle">{response?.config?.description ? response?.config?.description : ""}</span>
        <div className={"itemHeaderOption"}>
          {el && el.parameters && Array.isArray(el.parameters) && el.parameters.map((drop: any, key: any) => (
            <div
              className={"titleItemCalendar titleItemDriver"}
              key={key}
              onClick={() => {
                setShowPopUp(drop.name);
              }}
            >
                {el.parameters.icon ? <span className={"titleItemDropDownIcon"}><img src="el.parameters.icon" /></span>  : !dropText(drop,key,el.parameters) ?  drop.values[0] : '' }

              {dropText(drop,key,el.parameters)}
              <span className={"titleItemCalendarDate"}></span>
              <span className={"titleItemCalendarArrow"}></span>
              
              {ShowPopUp === drop.name && <OutsideClickHandler onOutsideClick={() => {setShowPopUp('')}}>
              <div className="WidgetDriverList customScrollBar">
                <div className="dropTitleName">{drop.name.replaceAll("_"," ")}</div>
                {(drop && drop.values) ? drop.values.map((val: any, valKey: any) => (
                  <div
                    className={'WidgetDriverListItem'}
                    key={valKey}
                    onClick={() => {
                      onPropChange && onPropChange(drop,isObject(val) ? val[drop.traget_name] : val)
                    }}
                  >
                    <div>
                      {(isObject(val)) ? val[drop.traget_name] : val}
                      
                    </div>
                  </div>
                ))
                  : t.no_data_available}
              </div>
              </OutsideClickHandler>}
            </div>
          ))}

          {ShowDatePicker && (
            <div
              className={"titleItemCalendar"}
              onClick={() => {
                setDatePopUp(!DatePopUp);
                // setDriverPopUp(false);
                // setGroupPopUp(false);

                DateToggle && DateToggle(!DatePopUp, el);
              }}
            >
              <span className={"titleItemCalendarIcon"}></span>
              {console.log("defaultDate",defaultDate)}
              <span className={"titleItemCalendarDate"}>
                {el.w === 1 ? (
                  ""
                ) : (
                  defaultDate ? (defaultDate.start_date === 'Invalid date' || !defaultDate.start_date ) ? 'All History' : (defaultDate.end_date === 'Invalid date' || !defaultDate.end_date ) ? 'This Year' : <>
                    {defaultDate.start_date && defaultDate.start_date.replaceAll("-", ".")}
                    <strong>/</strong>
                    {defaultDate.end_date && defaultDate.end_date.replaceAll("-", ".")}
                  </>
                  : ''
                )}
              </span>
              <span className={"titleItemCalendarArrow"}></span>
            </div>
          )}

          {el.i && (
            <div
              className={"titleItemSetting"}
              onClick={() => setActionBtn(!ActionBtn)}
            ></div>
          )}
        </div>
      </div>
      <div className="CalendarParent">
        <OutsideClickHandler
          onOutsideClick={() => {
            DatePopUp && setDatePopUp(false);
            DatePopUp && DateToggle && DateToggle(!DatePopUp, el);
            DatePopUp && setActionBtn(false);
          }}
        >
          {ActionBtn && (
            <OutsideClickHandler
              onOutsideClick={() => {
                setActionBtn(false);
              }}
            >
              <Action
                SmallWidget={SmallWidget}
                t={t}
                el={el}
                options={options}
                pin={() => {
                  pin(el);
                }}
                info={() => {
                  info(el);
                }}
                remove={() => {
                  remove(el);
                }}
              />
            </OutsideClickHandler>
          )}
          {DatePopUp && (
            <div className={"datePickerChart"}>
              <DatePicker
                t={t}
                onDateChange={(range: {
                  start_date: string;
                  end_date?: string;
                }) => {
                  console.log("aaaa",range)
                  setCalendarDate(range);
                }}
                defaultData={defaultDate}
              />
              <div className="DatePickerBtn">
                <button
                  className="cancel"
                  onClick={() => {
                    setDatePopUp(false);
                  }}
                >
                  {t.cancel}
                </button>
                <button
                  className="ok"
                  onClick={() => {
                    DatePopUp && onDateChange && onDateChange(CalendarDate);
                    DatePopUp && setDatePopUp(false);
                    DatePopUp && DateToggle && DateToggle(!DatePopUp, el);
                  }}
                >
                  {t.ok}
                </button>
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>
    </>
  );
}

import * as React from "react";
import history from "../../redux/history";
import { createBrowserHistory } from "history";
import { useState, useRef, useEffect } from "react";
import { PageContainer } from "./FromUtils";
import { useDispatch } from "../../redux/store";
import { History } from "history";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import store from "../../redux/store";
import Param from "../../redux/Param";
import { setMenu, menuState } from "../../redux/actions/menu.action";
interface SideBarProps {
  setSideMenuItems: (data: any) => void | undefined;
  setSideState: (data: any) => void | undefined;
  sideMenuItems: any;
  sideState: any;
}
export default function SideBar({ sideState, sideMenuItems }: SideBarProps) {
  const [t] = useTranslation();
  // const user = useSelector((state: RootState) => state.user.profile);
  const menuOpen = useSelector((state: RootState) => state.menu.open);
  const dispatch = useDispatch();

  const [MenuIsOpen, setMenuIsOpen] = useState(sideState);
  const [SideMenuItems, setSideMenuItems] = useState(sideMenuItems);
  const [SideState, setSideState] = useState(sideMenuItems);
  const [ActiveMenu, setActiveMenu] = useState<any>({ title: "", child: {} });
  const [ActiveMenuIndex, setActiveMenuIndex] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const mounted = useRef();
  useEffect(() => {
    if (SideMenuItems !== SideMenuItems) {
      setSideMenuItems(sideMenuItems);
    }
    if (sideState !== SideState) {
      setSideState(sideState);
      setMenuIsOpen(sideState);
    }
  });

  function updateDimensions() {
    if (window.innerWidth < 800 && SideState !== false) {
      setSideState(false);
    } else if (window.innerWidth > 800 && SideState !== true) {
      setSideState(true);
    }
  }
  useEffect(() => {
    updateDimensions();
    dispatch(setMenu(sideState));
    dispatch(menuState(!sideState));
  }, [sideState]);

  function toggleMenus(index: any, itemsIndex: any) {
    let i = 0;
    let j = 0;
    const items = [...sideMenuItems];
    console.log("items[index]", items[index]);

    for (i = 0; i < items.length; i++) {
      if (i !== index) {
        items[i].value = false;
        if (items[i].child && items[i].child.length > 0) {
          // for (j = 0; j < items[i].child.length; j++) {
          //   console.log(" items[i].child", items[i].child)
          //   items[i].child[j].value = false;
          // }
        }
      } else {
        items[i].value = true;
      }
    }

    setSideMenuItems(items);
  }

  function toggleSubMenus(jIndex: any, index: any) {
    let i = 0;

    const items = SideMenuItems;
    for (i = 0; i < items[index].child.length; i++) {
      if (items[index].child[i]) {
        if (i !== jIndex) {
          items[index].child[i].value = false;
        } else {
          items[index].child[i].value = true;
        }
      }

    }
    setSideMenuItems(items);
  }

  function pushWrapper(page: any, index: any, length: any, ItemsIndex: any) {
    if (page) {
      if (length === 0) {
        if (window.innerWidth < 800 && SideState === true) {
          setSideState(false);
        }
        navigate(page);
      }
    }
    toggleMenus(index, ItemsIndex);
  }

  function pushSubWrapper(page: any, jIndex: any, index: any) {
    if (page) {
      if (window.innerWidth < 800 && SideState === true) {
        setSideState(false);
      }
      navigate(page);
    }
    toggleSubMenus(jIndex, index);
  }

  function setMenuState(action: any) {
    if (!SideState) {
      dispatch(setMenu(action));
      if (action) {
        setMenuIsOpen(true);
      } else {
        setMenuIsOpen(false);
      }
    }
  }
  // useEffect(()=>{
  //   dispatch(setMenu(SideState))
  // },[MenuIsOpen])

  function navClass() {
    let classes = MenuIsOpen ? "nav nav-container" : "nav nav-container-mobile";
    if (
      ActiveMenu.child &&
      ActiveMenu.child.length &&
      MenuIsOpen
    ) {
      classes += "";
    }
    return classes;
  }
  function childGenrator(section: any) {
    const links = section.items.map((child: any, indexJ: any) => {
      return (
        <li
          onClick={() => {
            if (!child.value) {
              pushSubWrapper(child.page, indexJ, ActiveMenuIndex);
            }
          }}
          key={indexJ}
          className="side-items-second-element d-flex align-items-center"
        >
          <img className={"menuChildIcon"} src={child.icon} />
          <small
            style={{
              display: !MenuIsOpen ? "none" : "unset",
              color: !child.value ? "#8697A8" : "#44566c",
            }}
          >
            {t(child.title)}
          </small>
        </li>
      );
    });

    return (
      <>
        <div className="childTitle">{section.title}</div>
        <div className="links">{links}</div>
      </>
    );
  }
  function liClass(item: any) {
    let classes = "side-items-first-element d-flex align-items-center ";
    if (item.page === location.pathname) {
      classes += "side-items-first-element d-flex align-items-center ActiveLi";
    }
    if (item.value) {
      classes += " liParent";
    }
    return classes;



  }
  return (
    <nav
      className={navClass()}
      onMouseLeave={() => MenuIsOpen && setMenuState(false)}
      onMouseOver={() => !MenuIsOpen && setMenuState(true)}
    >
      <div
        className={
          MenuIsOpen ? "menuParent menuDesktop" : "menuParent menuMobile"
        }
      >

        <div className={"menuContainer"}>
          <div className="side-header">
            {MenuIsOpen && (
              <img src={"/assets/icons/white/Icon Navy.svg"} alt="fetch-logo" />
            )}
            {!MenuIsOpen && (
              <img
                className="side-header-logo"
                src={"/assets/icons/white/Icon Navy.svg"}
                alt="fetch-logo"
              />
            )}
          </div>
          <div className="menuViews">
            <ul className="side-items-container">
              {SideMenuItems.map(
                (item: any, index: any) =>
                  !item.disable && (
                    <ul key={index}>
                      <li
                        onClick={() => {
                          if (item === ActiveMenu) {
                            setActiveMenu({ title: "", child: {} });
                          } else {
                            setActiveMenu(item);
                          }
                          setActiveMenuIndex(index);

                          //item.child.length > 0 && setMenuIsOpen(!MenuIsOpen)
                          //item.child.length > 0 && setActiveMenuIndex(index)
                          if (!item.value) {
                            pushWrapper(
                              item.page,
                              index,
                              item.child.length,
                              ""
                            );
                          } else {
                            pushWrapper("", "", "", "");
                          }
                        }}
                        className={liClass(item)}
                       
                      ><div className="liDiv">
                          {item.page === location.pathname && (
                            <div className="side-item-active-left" />
                          )}
                          {item.url === location.pathname.split("/")[1] && (
                            <div className="side-item-active-left" />
                          )}

                          <img
                           
                            src={item.icon}
                            className={"menuLiIcon"}
                            alt="fetch-logo"
                          />
                          <small
                            style={{ display: !MenuIsOpen ? "none" : "unset" }}
                          >
                            {t(item.title)}
                          </small>

                          {item.notifCount !== 0 && (
                            <div
                              className="badge-side-items"
                              style={{ display: !MenuIsOpen ? "none" : "unset" }}
                            >
                              {item.notifCount}
                            </div>
                          )}
                          {!item.value &&
                            item.child &&
                            item.child.length > 0 && (
                              <img
                                className="sidemenu-expand-icon"
                                style={{
                                  display: !MenuIsOpen ? "none" : "unset",
                                }}
                                src={"/assets/icons/Arrow@2x.png"}
                                alt="fetch-logo"
                              />
                            )}
                          {item.value &&
                            item.child &&
                            item.child.length > 0 && (
                              <img
                                className="sidemenu-expand-icon"
                                style={{
                                  display: !MenuIsOpen ? "none" : "unset",
                                }}
                                src={"/assets/icons/ArrowDown@2x.png"}
                                alt="fetch-logo"
                              />
                            )}
                        </div>

                        {item.value &&
                          item.child &&
                          item.child.length > 0 && MenuIsOpen && (<ul className="menuUlChild">
                            {item.child.map((childItem: any, key: any) => (
                              <li
                                onClick={() => {
                                  navigate(childItem.page);
                                }}
                                className={
                                  childItem.page === location.pathname
                                    ? "side-items-first-element d-flex align-items-center ActiveLi"
                                    : "side-items-first-element d-flex align-items-center"
                                }
                            
                              >{childItem.title} </li>
                            ))}

                          </ul>
                          )}

                      </li>
                    </ul>
                  )
              )}
            </ul>
          </div>
        </div>
        <div className="menuVersion">
          <span>{process.env.REACT_APP_ENV}</span>
          <span>{process.env.REACT_APP_VERSION}</span>

        </div>
      </div>
      {/* {ActiveMenu.child &&
          ActiveMenu.child &&
      <ul className="ulChildView">
        {ActiveMenu.child.map((section: any) => (
            <>
              <div className="menuSectionTitle">
                <img src={section.icon} />
                {ActiveMenu.child.title}
              </div>
              {childGenrator(section)}
            </>
          ))}
      </ul>
  } */}
    </nav>
  );
}
